import React, { useEffect, useState, useRef } from 'react';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import { format, parseISO } from 'date-fns';
import { useAuth0 } from '../react-auth0-spa';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import Loading from '../components/Loading';
import ModalComponent from '../components/Modal';
import OrganizationSelect from '../components/OrganizationSelect';
import { Invitation } from '../types/Auth0.interfaces';
import { Tenant, TenantGroup } from '../types/Tenant.interfaces';
import useWindowSize from '../utils/useWindowSize';
import history from '../utils/history';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme: any) => ({
  highlight: {
    backgroundColor: '#74c8b0 !important',
    '& *': {
      color: 'rgba(255, 255, 255, 1)',
    }
  },
}));

interface Props {
  activeTenant: Tenant | null;
  isClientPortal: boolean;
  isSevenstepOrganization: boolean;
  adminUserTenants: Tenant[];
  prefix: string;
}

const InvitedUsers: React.FC<Props> = ({ activeTenant, isClientPortal, isSevenstepOrganization, adminUserTenants, prefix }) => {
  const { user, organizationId, isClientUser } = useAuth0();
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageOrganizationId, setPageOrganizationId] = useState<string>('');
  const [userType, setUserType] = useState<boolean>(false);
  const [activeOrganization, setActiveOrganization] = useState<Tenant | null>(null);
  const [toolbarHeight, setToolbarHeight] = useState<number>(0);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [multipleDeleteModal, setMultipleDeleteModal] = useState<boolean>(false);
  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const [invitationsToDelete, setInvitationsToDelete] = useState<Invitation[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const { height } = useWindowSize();
  const classes = useStyles();
  const toolbarRef = useRef<any>();

  useEffect(() => {
    if (!!isClientUser || isClientPortal) {
      toast.error('You don\'t have access to this page!');
      history.push('/');
    }
  }, [isClientUser, isClientPortal]);

  useEffect(() => {
    setUserType(!!isClientPortal);
  }, [isClientPortal]);

  useEffect(() => {
    setToolbarHeight(toolbarRef.current.clientHeight)
  }, []);

  useEffect(() => {
    if (activeTenant) {
      setActiveOrganization(activeTenant);
    }
  }, [activeTenant]);
  
  useEffect(() => {
    if (activeOrganization) {
      if (isSevenstepOrganization) {
        setPageOrganizationId(activeOrganization.organization_id);
      } else {
        setPageOrganizationId(organizationId ? organizationId : '');
      }
    }
  }, [activeOrganization, organizationId, isSevenstepOrganization]);

  useEffect(() => {
    if (pageOrganizationId) {
      handleGetInvitations();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOrganizationId]);

  const handleGetInvitations = async () => {
    setIsLoading(true);
    try {
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const response: AxiosResponse<Invitation[]> = await axios.get(`${prefix}/organizations/${pageOrganizationId}/invitations?page=0&per_page=100&sort=created_at:-1`, options);
      const result = response.data.filter((invitation: Invitation) => invitation.user_metadata.organizations.length > 1 && invitation.user_metadata.is_client_user === false);
      setInvitations(result);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Something went wrong with loading ${activeOrganization?.tenant} last ${pageSize} invitations!`)
      }
      setInvitations([]);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteInvitation = async (invitation: Invitation, reload: boolean | undefined) => {
    setDeleteModal(false);
    try {
      if (reload) {
        setLoading(true);
      }
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<null> = await axios.delete(`${prefix}/organizations/${pageOrganizationId}/invitations/${invitation.id}`, options);
      if (reload) {
        toast.success('Successfully removed invitation from the list!');
        handleGetInvitations();
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with removing invitation from the list!')
      }
    } finally {
      if (reload) {
        setLoading(false);
      }
    }
  }

  const handleRemoveMultipleInvitations = async () => {
    if (invitationsToDelete) {
      if (invitationsToDelete.length > 10) {
        toast.warning('You can maximally delete 10 invitations!');
        return;
      }
      try {
        setMultipleDeleteModal(false);
        setLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await Promise.all(invitationsToDelete.map(async (invitation: Invitation) => await handleDeleteInvitation(invitation, undefined)));
        toast.success('Successfully removed invitations from the list!');
        handleGetInvitations();
      } catch (error) {
        toast.warning('Something went wrong with removing invitations from the list!')
      } finally {
        setLoading(false);
      }
    }
  }

  const columns: Column<Invitation>[] = [
    {
      title: 'Email',
      field: 'email',
      render: (rowData: Invitation) => <span>{rowData.invitee.email}</span>,
    },
    {
      title: 'Name',
      field: 'user_metadata.first_name',
      render: (rowData: Invitation) => rowData.user_metadata && rowData.user_metadata.first_name && rowData.user_metadata?.last_name ? (
        <span>{rowData.user_metadata.first_name} {rowData.user_metadata.last_name}</span>
      ) : null,
    },
    {
      title: 'Sent',
      field: 'created_at',
      render: (rowData: Invitation) => <span>{format(parseISO(rowData.created_at), 'MM/dd/yyyy hh:mm a')}</span>,
    },
    {
      title: 'Roles',
      field: 'app_metadata.group_ids',
      render: (rowData: Invitation) => (
        <span>
          {activeOrganization && activeOrganization.groups
            .filter((group: TenantGroup) => rowData.app_metadata && rowData.app_metadata.group_ids && rowData.app_metadata.group_ids.includes(group.group_id))
            .map((group: TenantGroup) => group.label).join(', ')}
        </span>
      ),
    },
    {
      title: 'Inviter',
      field: 'inviter.name',
      render: (rowData: Invitation) => <span>{rowData.inviter.name}</span>,
    },
  ]

  return (
    <Box>
      <MaterialTable
        title={
          <div className='table-header-container'>
            <Tooltip title={`Last ${pageSize} existing users added to ${activeOrganization?.tenant}`} placement='bottom'>
              <InfoOutlinedIcon fontSize='small' style={{ marginRight: '10px' }} />
            </Tooltip>
            <h6 className='table-header'>Recently Added Existing Users{isSevenstepOrganization ? ' To' : ''}</h6>
            {isSevenstepOrganization && (
              <div>
                <OrganizationSelect
                  activeOrganization={activeOrganization}
                  setActiveOrganization={setActiveOrganization}
                  showLabel={false}
                  adminUserTenants={adminUserTenants}
                  userType={userType}
                  changeParam={false}
                />
              </div>
            )}
          </div>
        }
        columns={columns}
        data={invitations}
        isLoading={isLoading}
        actions={[
          {
            icon: 'delete',
            tooltip: 'Remove invitation from the list',
            onClick: (event: React.MouseEvent, rowData: any) => {
              setDeleteModal(true);
              setInvitation(rowData);
            },
            position: 'row',
          },
          {
            icon: 'delete',
            tooltip: 'Remove selected invitations from the list',
            onClick: (event: React.MouseEvent, rowData: any) => {
              setInvitationsToDelete(rowData);
              setMultipleDeleteModal(true);
            },
            position: 'toolbarOnSelect',
          },
        ]}
        options={{
          search: false,
          sorting: false,
          headerStyle: {
            position: 'sticky',
            top: 0,
            height: 60,
          },
          maxBodyHeight: height - (103 + toolbarHeight),
          minBodyHeight: height - (103 + toolbarHeight),
          emptyRowsWhenPaging: false,
          selection: true,
          pageSize: pageSize
        }}
        onChangeRowsPerPage={(size: number) => setPageSize(size)}
        components={{
          Toolbar: (props: any) => <div ref={toolbarRef} style={{ width: '100%' }}><MTableToolbar {...props} classes={{ highlight: classes.highlight }} /></div>,
          OverlayLoading: (props: any) => <Loading transparentBackground={true} />,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No recently added existing users to display!'
          },
          toolbar: {
            nRowsSelected: `{0} invitation(s) selected`
          }
        }}
      />
      <ModalComponent
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={'Remove invitation'}
        labelText={`
          Are you really sure you want to remove invitation for 
          ${invitation && invitation.invitee.email} from the list? This cannot be undone!
        `}
        buttonText={'Yes, remove it'}
        handleAccept={handleDeleteInvitation}
        data={invitation}
      />
      <ModalComponent
        open={multipleDeleteModal}
        setOpen={setMultipleDeleteModal}
        heading={'Remove selected invitation'}
        labelText={`
          Are you really sure you want to remove selected invitations from the list? This cannot be undone!
        `}
        buttonText={'Yes, remove it'}
        handleAccept={handleRemoveMultipleInvitations}
      />
      {loading && <Loading transparentBackground={true} />}
    </Box>
  )
}

export default InvitedUsers;
