import React, { useEffect, useState } from 'react';
import ChangePasswordModal from '../components/ChangePasswordModal';
import { useAuth0 } from '../react-auth0-spa';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { TextField, Box, Avatar } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import Loading from '../components/Loading';
import { UpdateObject } from '../types/Tenant.interfaces';
import { IUser, Auth0User } from '../types/Auth0.interfaces';

interface Props {
  randomColor: string;
  prefix: string;
}

const Profile: React.FC<Props> = ({ randomColor, prefix }) => {
  const { user, getTokenSilently, userMetadata, getInitials, handleUserUpdate } = useAuth0();
  const [updateObject, setUpdateObject] = useState<UpdateObject | null>();
  const [token, setToken] = useState<string>();
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordModal, setPasswordModal] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      (async function iife() {
        if (getTokenSilently) {
          const myToken = await getTokenSilently();
          setToken(myToken);
        }
      })();
    }
  }, [user, getTokenSilently]);

  const updateUser = async () => {
    if (updateObject && user) {
      try {
        setLoading(true)
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${user.sub}`, updateObject, options);
        handleUserUpdate && await handleUserUpdate();
        toast.success('Successfully updated!');
        setUpdateObject(null);
      } catch (error) {
        if (error.response) {
          toast.warning(error.response.data.message);
        } else {
          toast.warning('Something went wrong with updating account!');
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.info('Nothing to update!');
    }
    setEditProfile(false);
  }

  const changeUserMeta = (event: React.ChangeEvent<HTMLInputElement>, prop: string) => {
    const changed: UpdateObject = { ...updateObject };
    if (!changed.user_metadata) {
      changed.user_metadata = {};
    }
    changed.user_metadata[prop] = event.target.value;
    setUpdateObject(changed);
  }

  return (
    token ? (
      <div className='profile'>
        <div className='wrapper'>
          <div className='profile-container'>
            {user ? (
              <div className='profile-image'>
                <Avatar
                  style={{
                    width: '150px',
                    height: '150px',
                    display: 'inline-flex',
                    fontSize: '72px',
                    background: user && user[userMetadata as keyof IUser].color ? user[userMetadata as keyof IUser].color : randomColor
                  }}
                >
                  {user && getInitials && getInitials(user.email, user[userMetadata as keyof IUser].first_name, user[userMetadata as keyof IUser].last_name)}
                </Avatar>
              </div>
              ) : <></>
            }
            <div className='profile-information'>
              <div className='profile-toogle'>
                <div>
                  <button 
                    className={editProfile ? 'profile-toogle-button' : 'profile-toogle-button active'}
                    onClick={() => setEditProfile(false)}
                  >
                    <InfoIcon style={{ cursor: 'pointer', marginRight: '8px' }} fontSize='small' />Info
                  </button>
                </div>
                <div>
                  <button 
                    className={!editProfile ? 'profile-toogle-button' : 'profile-toogle-button active'}
                    onClick={() => setEditProfile(true)}
                  >
                    <EditIcon style={{ cursor: 'pointer', marginRight: '8px' }} fontSize='small' />Edit
                  </button>
                </div>
              </div>
              {user ? (!editProfile ? (
                <div className='profile-details'>          
                  <p>Name: {user[userMetadata as keyof IUser].first_name} {user[userMetadata as keyof IUser].last_name}</p>
                  <p style={{ overflowWrap: 'anywhere' }}>Email: {user.email}</p>
                  <p>
                    Email verified: 
                    {' '}
                    {user.email_verified ? (
                      <VerifiedUserIcon style={{ color: '#74c8b0' }} />
                      ) : (
                      <ClearIcon color='error' />
                    )}
                  </p>
                </div>
                ) : (
                <div className='profile-details'>
                  <div>
                    <TextField
                      label='First name'
                      className='full-width'
                      defaultValue={user[userMetadata as keyof IUser].first_name}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeUserMeta(event, 'first_name')}
                    />
                  </div>
                  <div>
                    <TextField
                      label='Last name'
                      className='full-width'
                      defaultValue={user[userMetadata as keyof IUser].last_name}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeUserMeta(event, 'last_name')}
                    />
                  </div>
                  <div>
                    <Box onClick={() => setPasswordModal(true)} component='span' className='password-link'>Change password</Box>
                  </div>
                  <div>
                    <Button
                      color='primary'
                      className='green-button'
                      style={{ marginTop: '20px' }}
                      disabled={updateObject ? false : true}
                      onClick={() => updateUser()}
                    >
                      Update User
                    </Button>
                    {loading ? <Loading transparentBackground={true} /> : null}
                  </div>
                </div>
                )) : (
                  <>
                    <h2>No user selected!</h2>
                  </>
                )}
              </div>
          </div>
        </div>
        {user && (
          <ChangePasswordModal
            openModal={passwordModal}
            setOpenModal={setPasswordModal}
            heading={'Change password'}
            prefix={prefix}
            userId={user.sub}
            setLoading={setLoading}
          />
        )}
      </div>
    ) : <Loading transparentBackground={false} />
  )
};

export default Profile;
