import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  faLink,
  faPowerOff,
  faUser,
  faUsers,
  faPen,
  faUserPlus,
  faPaperPlane,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';

function initFontAwesome() {
  library.add(faLink as IconDefinition);
  library.add(faUser as IconDefinition);
  library.add(faPowerOff as IconDefinition);
  library.add(faUsers as IconDefinition);
  library.add(faPen as IconDefinition);
  library.add(faUserPlus as IconDefinition);
  library.add(faPaperPlane as IconDefinition);
  library.add(faUserFriends as IconDefinition);
}

export default initFontAwesome;
