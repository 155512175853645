import React from 'react';
import { ListItemIcon, ListItemText, Tooltip, IconButton, ListItem } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FolderStructure, Dashboard, Look } from '../types/Looker.interfaces';

interface Props {
  isMenuOpen: boolean;
  showMenuItems: boolean;
  folder: FolderStructure<Dashboard | Look>;
  isSubfolder: boolean;
  toggleFolder?: (folder: FolderStructure<Dashboard | Look>) => void;
  toggleSubfolder?: (folderId: string, parentFolderId: string) => void;
  parentFolderId?: string;
}

const FolderTitle: React.FC<Props> = ({ isMenuOpen, showMenuItems, folder, isSubfolder, toggleFolder, toggleSubfolder, parentFolderId }) => {
  const toogle = () => {
    if (toggleFolder) {
      toggleFolder(folder);
    } else if (toggleSubfolder && parentFolderId) {
      toggleSubfolder(folder.id, parentFolderId);
    }

  }
  return (
    <ListItem button onClick={() => toogle()} className='side-link' disableGutters>
      {isMenuOpen || showMenuItems ? (
        <>
          <ListItemIcon className='icon'>
            {folder.open ? (
              <FolderOpenIcon style={{ marginLeft: isSubfolder ? '16px' : '8px', minWidth: '45px' }} aria-label='folder' />
            ) : (
              <FolderIcon style={{ marginLeft: isSubfolder ? '16px' : '8px', minWidth: '45px' }} aria-label='folder' />
            )}
          </ListItemIcon>
          <ListItemText primary={isMenuOpen || showMenuItems ? folder.name : ''} />
            {folder.open ? (
              <ExpandLess style={{ fontSize: 16, marginRight: '3px' }} />
            ) : (
              <ExpandMore style={{ fontSize: 16, marginRight: '3px' }} />
            )}
        </>
      ) : (
        <>
          <Tooltip placement='right-start' enterDelay={0} key={`folder${folder.id}`} title={folder.name}>
            <ListItemText className='sidebar-item-text small'>
              <IconButton size='small'>
                {folder.open ? <FolderOpenIcon /> : <FolderIcon />}
              </IconButton>
            </ListItemText>
          </Tooltip>
        </>
      )}
    </ListItem>
  )
}

export default FolderTitle;
