import React, { useEffect, useState } from 'react';
import { Box, Typography, Modal, Tooltip, IconButton, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import ReactHtmlParser from 'react-html-parser'; 

const useStyles = makeStyles(() => ({
  modal: {
    position: 'absolute',
    background: '#fff',
    borderRadius: '10px',
    padding: '10px',
    maxWidth: 500,
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  button: {
    margin: '5px',
    border: '1px solid #dedac6',
    color: 'black',
  },
  container: {
    padding: '20px 0',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  heading: {
    fontSize: '24px',
    textAlign: 'center',
    position: 'relative',
  },
  wrapper: {
    padding: '20px'
  },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '-20%',
    width: '30px',
    height: '30px',
  }
}))

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  heading: string;
  buttonText?: string;
  handleAccept?: (value: any, reload?: boolean) => void;
  data?: any;
  labelText?: string;
  children?: React.ReactNode;
  handleCancel?: () => void;
  reload?: boolean;
}

const ModalComponent: React.FC<Props> = ({ open, setOpen, labelText, heading, buttonText, data, handleAccept, handleCancel, children, reload }) => {
  const [modifiedLabel, setModifiedLabel] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    if (labelText) {
      const email = extractEmail(labelText);
      if (email) {
        const newLabel = boldString(labelText, email);
        setModifiedLabel(newLabel);
      } else {
        setModifiedLabel(labelText);
      }
    }
  }, [labelText]);

  const handleConfirmation = () => {
    if (data) {
      handleAccept && handleAccept(data, reload && reload);
    } else {
      handleAccept && handleAccept(null, reload && reload);
    }
  }

  const handleCancellation = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      setOpen(false);
    }
  }

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.nativeEvent.key === 'Enter') {
      handleCancellation();
    }
  }

  const extractEmail = (value: string): RegExpMatchArray | null => value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);

  const boldString = (str: string, substr: string[]): string => {
    const strRegExp = new RegExp(substr.join('|'), 'g');
    return str.replace(strRegExp, (matchedValue: string) => '<b>' + matchedValue + '</b>');
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => handleEnter(event)}
    >
      <Fade in={open}>
        <Box className={classes.modal}>
          <Box className={classes.wrapper}>
            <Typography className={classes.heading}>
              {heading}
              <Box className={classes.closeBtn} onClick={() => setOpen(false)}>
                <Tooltip title={'Close'} placement='bottom'>
                  <IconButton>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Box>
            </Typography>
            <Box className={classes.container}>
              {modifiedLabel ? (
                <p style={{ textAlign: 'center' }}>{ReactHtmlParser(modifiedLabel)}</p>
              ) : (
                <>{children}</>
              )}
            </Box>
            <Box className={classes.buttonContainer}>
              {handleAccept && buttonText && (
                <Button
                  className='green-button'
                  onClick={handleConfirmation}
                >
                  {buttonText}
                </Button>
              )}
              <Button
                color='default'
                className={classes.button}
                onClick={handleCancellation}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default ModalComponent;
