import React from 'react';
import { CSVReader } from 'react-papaparse';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { InvitedUser, UploadedFile } from '../types/Tenant.interfaces';
import BackupIcon from '@material-ui/icons/Backup';

interface Props {
  setData: (data: InvitedUser[]) => void;
  reset: boolean;
  groups: number[];
}

const FileReader: React.FC<Props> = ({ setData, reset, groups }) => {
  const handleOnDrop = (data: UploadedFile[]) => {
    const fileData = data.map((item: UploadedFile) => {
      item.data.groups = groups;
      return item.data;
    })
    setData(fileData);
  };

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    toast.error('Something went wrong with uploading file.');
  };

  const handleOnRemoveFile = (data: InvitedUser[]) => {
    setData(data);
  };

  return (
    <Box>
      <CSVReader
        onDrop={handleOnDrop}
        onError={handleOnError}
        isReset={reset}
        addRemoveButton
        onRemoveFile={handleOnRemoveFile}
        progressBarColor='#74c8b0'
        config={{
          header: true,
          skipEmptyLines: 'greedy'
        }}
        style={{
          dropArea: {
            padding: '10px',
            maxHeight: '120px',
            minHeight: '70px',
          },
          fileNameInfo: {
            whiteSpace: 'nowrap',
          },
          dropFile: {
            overflowX: 'hidden',
          },
        }}
      >
        <Box component='span' style={{ display: 'flex', alignItems: 'center' }}>
          <BackupIcon style={{ marginRight: '5px' }} />
          Drop CSV file here or click to upload.
        </Box>
      </CSVReader>
    </Box>
  )
}

export default FileReader;
