import React, { useState } from 'react';
import GroupSelect from './GroupSelect';
import OrganizationSelect from './OrganizationSelect';
import { Box, TextField, Typography, Chip } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { Tenant } from '../types/Tenant.interfaces';
import { Auth0User, IUser, Invitation } from '../types/Auth0.interfaces';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import axios, { AxiosResponse } from 'axios';
import { useAuth0 } from '../react-auth0-spa';
import UserType from './UserType';

interface Props {
  isSevenstepOrganization: boolean;
  invitationOrganization: Tenant | null;
  setInvitationOrganization: (value: Tenant | null) => void;
  adminUserTenants: Tenant[];
  setLoading: (value: boolean) => void;
  cancelButton: boolean;
  userType: boolean;
  setUserType: (value: boolean) => void;
  setCloseModal?: (value: boolean) => void;
  reloadTable?: (users: Auth0User[]) => void;
  prefix: string;
}

const InvitationWidget: React.FC<Props> = ({
  isSevenstepOrganization, invitationOrganization, setInvitationOrganization, adminUserTenants, setLoading,
  setCloseModal, reloadTable, cancelButton, userType, setUserType, prefix,
}) => {
  const { user, userMetadata, clientId } = useAuth0();
  const [usersToAdd, setUsersToAdd] = useState<Auth0User[]>([]);
  const [newMembersGroups, setNewMembersGroups] = useState<number[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [users, setUsers] = useState<Auth0User[]>([]);
  const [searchEmail, setSearchEmail] = useState<string>('');

  const allowedUsersToRegister = (users: Auth0User[], tenant: Tenant): boolean => users.every((account: Auth0User) => tenant.allowedEmailDomains.includes(account.email.split('@')[1]));

  const handleAddMembers = async (tenant: Tenant) => {
    const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
    setCloseModal && setCloseModal(false);
    if (usersToAdd.length > 10) {
      toast.info('You can maximally add 10 users!');
      return;
    }
    const allowed = allowedUsersToRegister(usersToAdd, tenant);
    if (!allowed) {
      toast.error(`Users are not allowed to register to ${tenant.tenant} organization due to not allowed email domain!`);
      return;
    }
    try {
      setLoading(true);
      const data = {
        members: usersToAdd.map((account: Auth0User) => account.user_id)
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<null> = await axios.post(`${prefix}/organizations/${tenant.organization_id}/members`, data, options);
      for (let i = 0; i < usersToAdd.length; i++) {
        await updateUser(usersToAdd[i], tenant.organization_id);
      }
      toast.success(`Successfully added ${usersToAdd.length > 1 ? 'users' : `${usersToAdd[0].email}`} to ${isSevenstepOrganization ? `${tenant.tenant} ` : ''}organization!`);
      setUsersToAdd([]);
      setUsers([]);
      setSearchEmail('');
      setNewMembersGroups([]);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Something went wrong with adding ${usersToAdd.length > 1 ? 'users' : 'user'} to organization!`);
      }
    } finally {
      setLoading(false);
      reloadTable && reloadTable(usersToAdd);
    }
  }

  const updateUser = async (account: Auth0User, orgId: string) => {
    try {
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const data = {
        app_metadata: {
          group_ids: [...account.app_metadata.group_ids, ...newMembersGroups.filter((id: number) => !account.app_metadata.group_ids.includes(id))]
        },
        user_metadata: {
          organizations: [...account.user_metadata.organizations.filter((orgName: string) => orgName !== invitationOrganization?.name), invitationOrganization?.name ]
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${account.user_id}`, data, options);
      const invitationData = {
        inviter: {
          name: user && `${user[userMetadata as keyof IUser].first_name} ${user[userMetadata as keyof IUser].last_name}`
        },
        invitee: { email: account.email },
        client_id: clientId,
        user_metadata: {
          first_name: account.user_metadata.first_name,
          last_name: account.user_metadata.last_name,
          is_client_user: account.user_metadata.is_client_user,
          organizations: [...account.user_metadata.organizations.filter((org: string) => org !== invitationOrganization?.name), invitationOrganization?.name ],
        },
        app_metadata: {
          group_ids: [...account.app_metadata.group_ids, ...newMembersGroups.filter((id: number) => !account.app_metadata.group_ids.includes(id))],
        },
        ttl_sec: 2592000,
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const invitationResponse: AxiosResponse<Invitation> = await axios.post(`${prefix}/organizations/${orgId}/invitations`, invitationData, options);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Something went wrong with updating user ${account.email}!`);
      }
    }
  }

  const handleFetchUsers = async (value: string) => {
    if (value.length === 0) {
      setUsers([]);
    } else if (value.length > 2) {
      try {
        setSearching(true);
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        const response: AxiosResponse<Auth0User[]> = await axios.get(`${prefix}/users?search_engine=v3&q=email:*${value.toLowerCase()}*+AND+user_metadata.is_client_user:${userType}`, options);
        setUsers(response.data);
      } catch (error) {
        if (error.response) {
          toast.warning(error.response.data.message);
        } else {
          toast.warning('Something went wrong with searching users!');
        }
      } finally {
        setSearching(false);
      }
    } else {
      setUsers([]);
      toast.info('Search value must be at least 3 characters long!');
    }
  }

  const debounced = useDebouncedCallback(handleFetchUsers, 700);

  const handleSearchUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounced(event.target.value);
  }

  const handleRemoveUser = (user: Auth0User) => {
    const usersArray = usersToAdd.filter((account: Auth0User) => account.user_id !== user.user_id);
    setUsersToAdd([...usersArray]);
  }

  const handleAddUser = (item: Auth0User) => {
    const userAdded = usersToAdd.find((account: Auth0User) => account.user_id === item.user_id);
    if (!userAdded) {
      setUsersToAdd(prevState => ([...prevState, item]));
    } else {
      toast.info('User already added!');
    }
  }

  const handleTypeChange = (client: Tenant, setNewTenant: (value: Tenant) => void) => {
    if (client.otherGroups) {
      const tenant = {
        ...client,
        groups: client.otherGroups,
        otherGroups: client.groups,
      };
      setNewTenant(tenant);
    }
    setUsersToAdd([]);
    setUsers([]);
  }

  return (
    <Box>
      <Box>
        <Typography variant='h4'>Invite Existing Users</Typography>
      </Box>
      <Box>
        <Box>
          <UserType
            userType={userType}
            setUserType={setUserType}
            label={'users'}
            tenant={invitationOrganization}
            handleTypeChange={handleTypeChange}
            setTenant={setInvitationOrganization}
            setGroups={setNewMembersGroups}
          />
          {isSevenstepOrganization ? (
            <Box>
              <OrganizationSelect
                activeOrganization={invitationOrganization}
                setActiveOrganization={setInvitationOrganization}
                showLabel={true}
                setGroups={setNewMembersGroups}
                adminUserTenants={adminUserTenants}
                userType={userType}
                setType={setUserType}
                changeParam={false}
              />
            </Box>
          ) : null}
          <Box>
            <Autocomplete
              id='search-users'
              options={users}
              forcePopupIcon={false}
              openOnFocus={false}
              clearOnBlur={false}
              clearOnEscape={true}
              onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: string) => {
                if (reason === 'clear') {
                  setSearchEmail('');
                  setUsers([]);
                }
              }}
              loading={searching}
              inputValue={searchEmail}
              noOptionsText='No users'
              getOptionLabel={(item: Auth0User) => item.email}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  label='Search by email'
                  className='full-width'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchEmail(event.target.value);
                    handleSearchUsers(event);
                  }}
                />
              )}
              renderOption={(item: Auth0User) => <div onClick={() => handleAddUser(item)}>{item.email}</div>}
            />
          </Box>
          <Box style={{ paddingBottom: '20px' }}>
            <GroupSelect
              showLabel={true}
              label={'Roles'}
              availableTenantGroups={invitationOrganization ? invitationOrganization.groups : []}
              groups={newMembersGroups}
              setGroups={setNewMembersGroups}
              showOtherGroups={false}
            />
          </Box>
          <Box style={{ padding: '20px 0 10px'}}>
            <Button
              color='primary'
              className='green-button'
              disabled={!usersToAdd.length}
              onClick={() => invitationOrganization && handleAddMembers(invitationOrganization)}
            >
              Send Invitations
            </Button>
            {cancelButton ? (
              <Button
                color='default'
                className='invitation-button'
                onClick={() => setCloseModal && setCloseModal(false)}
              >
                Cancel
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box className='chip-container'>
        {usersToAdd.length ? usersToAdd.map((user: Auth0User) => (
          <Chip
            variant='outlined'
            label={user.email}
            style={{ cursor: 'pointer', margin: '5px' }}
            color='default'
            clickable={false}
            onDelete={() => handleRemoveUser(user)}
          />
        )) : null}
      </Box>
    </Box>
  )
}

export default InvitationWidget;
