import React from 'react';

const GraphLoading = () => {
  return (
    <div className='graph-loader'>
      <div className='loading'>      
        <div className='loading-combined loading-1'></div>
        <div className='loading-combined loading-2'></div>
        <div className='loading-combined loading-3'></div>
        <div className='loading-combined loading-4'></div>
      </div>
    </div>
  )
}

export default GraphLoading;
