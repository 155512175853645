import React, { useState } from 'react';
import ModalComponent from '../components/Modal';
import { useAuth0 } from '../react-auth0-spa';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Box, TextField, Tooltip, IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Auth0User } from '../types/Auth0.interfaces';

interface Props {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  setLoading: (value: boolean) => void;
  userId: string;
  prefix: string;
  heading: string;
}

const ChangePasswordModal: React.FC<Props> = ({ openModal, setOpenModal, setLoading, userId, prefix, heading }) => {
  const { user } = useAuth0();
  const [passwordValue, setPasswordValue] = useState<string>();
  const [passwordConfirmValue, setPasswordConfirmValue] = useState<string>();
  const [arePasswordsSame, setArePasswordsSame] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const handleChangePassword = async () => {
    if (user) {
      if (passwordValue === passwordConfirmValue) {
        setArePasswordsSame(true);
        setOpenModal(false);
        try {
          setLoading(true);
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${userId}`, { password: passwordValue }, options);
          toast.success('Successfully changed password!');
          setPasswordValue('');
          setPasswordConfirmValue('');
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with changing user password!');
          }
        } finally {
          setLoading(false);
        }
      } else {
        setArePasswordsSame(false);
      }
    }
  }

  return (
    <ModalComponent
      open={openModal}
      setOpen={setOpenModal}
      heading={heading}
      buttonText={'Change password'}
      handleAccept={handleChangePassword}
    >
      <>
        <Box style={{ textAlign: 'center' }}>
          <TextField
            label='Password'
            type={showPassword ? 'text' : 'password'}
            className='full-width'
            value={passwordValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordValue(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={!showPassword ? 'Show password' : 'Hide password'} placement='right-start' enterDelay={0}>
                    <IconButton
                      size='small'
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box style={{ textAlign: 'center' }}>
          <TextField
            label='Confirm password'
            type={showConfirmPassword ? 'text' : 'password'}
            className='full-width'
            value={passwordConfirmValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordConfirmValue(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={!showConfirmPassword ? 'Show password' : 'Hide password'} placement='right-start' enterDelay={0}>
                    <IconButton
                      size='small'
                      aria-label='toggle confirm password visibility'
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {!showConfirmPassword ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Box>
        {!arePasswordsSame && (
          <Box style={{ textAlign: 'center' }}>
            <Box className='modal-error'>Passwords must match!</Box>
          </Box>
        )}
      </>
    </ModalComponent>
  )
}

export default ChangePasswordModal;
