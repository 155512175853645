import React, { useState, useEffect, useRef } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

// views
import User from './views/User';
import Profile from './views/Profile';
import UserManagement from './views/UserManagement';
import Home from './views/Home';
import Dashboard from './views/Dashboard';
import Explore from './views/Explore';
import Look from './views/Look';
import UsersInvitation from './views/UsersInvitation';
import PendingInvitations from './views/PendingInvitations';
import MoreInfo from './views/MoreInfo';
import RecentlyAddedUsers from './views/RecentlyAddedUsers';
import Notifications from './views/Notifications';
import IERedirect from './components/IERedirect';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import Loading from './components/Loading';
import NavBar from './components/NavBar';
import MobileMenu from './components/MobileMenu';
import MobileRightSideMenu from './components/MobileRightSideMenu';
import ErrorFallback from './components/ErrorFallback';
import { useAuth0 } from './react-auth0-spa';
import history from './utils/history';
import axios, { AxiosResponse } from 'axios';
import { LookerEmbedDashboard, LookerEmbedSDK } from './utils/embed_sdk';
import useWindowSize from './utils/useWindowSize';
import { Transition } from 'react-transition-group';
import { Dashboard as DashboardType, Explore as ExploreType, FolderStructure, Look as LookType, LookerRole, Token, LookerEmbedUser, BasicFolder, LookMLModels, FavoriteContent } from './types/Looker.interfaces';
import { Tenant, TenantGroup } from './types/Tenant.interfaces';
import { Auth0User, IUser, Role, Organization } from './types/Auth0.interfaces';
import { Incident, Subscriber } from './types/Statuspage.interfaces';
import { toast, ToastContainer } from 'react-toastify';
import { isAfter, format, parseISO, differenceInMinutes } from 'date-fns';
import * as Sentry from '@sentry/react';

// styles
import './App.css';

// fontawesome
import initFontAwesome from './utils/initFontAwesome';
import { TransitionStatus } from 'react-transition-group/Transition';
initFontAwesome();

const App: React.FC = () => {
  const { loading, user, isAuthenticated, userMetadata, appMetadata, loginWithRedirect, isAdmin, organizationId, userOrganizations,
    handleOrganizationChanged, parseParams, setOrganizationId, availableUserTenants, allAvailableTenants, adminRoleName, isClientUser, isFirstLogin } = useAuth0();
  const [token, setToken] = useState<string | null>();
  const [embedToken, setEmbedToken] = useState<string | null>(null);
  const [menuStructure, setMenuStructure] = useState<FolderStructure<DashboardType | LookType>[]>([]);
  const [menuLoading, setMenuLoading] = useState<boolean>(true);
  const AUTH_DASH_ID = 50;
  const lookerHost = process.env.REACT_APP_LOOKER_EMBED_HOST as string;
  const { width } = useWindowSize();
  const openMenuSize = 240;
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [closedWidth, setClosedWidth] = useState<number>(width < 768 ? 0 : 50);
  const [menuWidth, setMenuWidth] = useState<number>(width < 768 ? closedWidth : openMenuSize);
  const [rightMenuWidth, setRightMenuWidth] = useState<number>(width < 768 ? closedWidth : openMenuSize);
  const [showMenuItems, setShowMenuItems] = useState<boolean>(true);
  const [BASE_URL] = useState<string>(`/.netlify/functions/proxy`);
  const [explores, setExplores] = useState<FolderStructure<ExploreType>[]>([]);
  const [isExploreAvailable, setIsExploreAvailable] = useState<boolean | null>(null);
  const [colors] = useState<string[]>(['#F79784', '#3399DA', '#9B5AB6', '#2B81BA', '#74c8b0']);
  const [randomColor, setRandomColor] = useState<string>('');
  const [lookerTokenExpirationTime, setLookerTokenExpirationTime] = useState<Date | null>(null);
  const [searchBarFocus, setSearchBarFocus] = useState<boolean>(false);
  const [activeTenant, setActiveTenant] = useState<Tenant | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isIE, setIsIE] = useState<boolean | null>(null);
  const [isSevenstepOrganization, setIsSevestepOrganization] = useState<boolean>(false);
  const [adminUserTenants, setAdminUserTenants] = useState<Tenant[]>([]);
  const [activeOrganization, setActiveOrganization] = useState<Tenant | null>(null);
  const [userManagementType, setUserManagementType] = useState<boolean>(false);
  const [clientTenant, setClientTenant] = useState<Tenant | null>(null);
  const [isClientPortal, setIsClientPortal] = useState<boolean>(false);
  const [moreInfoDashboardId, setMoreInfoDashboardId] = useState<number | null>(null);
  const [notifications, setNotifications] = useState<{ active: Incident[], scheduled: Incident[], unresolved: Incident[] } | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [shouldChangeClient, setShouldChangeClient] = useState<boolean>(false);
  const [tempTenant, setTempTenant] = useState<Tenant | null>(null);
  const [whatsNewDashboardId, setWhatsNewDashboardId] = useState<number | null>(null);
  const prefix = '/.netlify/functions/auth0Proxy/https://' + process.env.REACT_APP_AUTH0_API_IDENTIFIER + '/api/v2';
  const statuspageUrl = '/.netlify/functions/statusPage/https://' + process.env.REACT_APP_STATUSPAGE_API_IDENTIFIER + '/v1';
  const moreInfoDashboards = JSON.parse(process.env.REACT_APP_MORE_INFO_DASHBOARDS as string);
  const [statuspageId] = useState<string>(process.env.REACT_APP_STATUSPAGE_PAGE_ID as string);
  const whatsNewDashboards = JSON.parse(process.env.REACT_APP_WHATS_NEW_DASHBOARDS as string);
  const dashboardIndexs = JSON.parse(process.env.REACT_APP_DASHBOARD_INDEX as string);
  const [orgName, setOrgName] = useState<string>('');
  const [dashboardIndexId, setDashboardIndexId] = useState<number | null>(null);
  const rightToggleRef = useRef<any>();
  const leftToggleRef = useRef<any>();
  LookerEmbedSDK.init(lookerHost, `/.netlify/functions/auth_utils`);

  const duration = 500;

  const contentStyle = {
    transition: `width ${duration}ms`,
  };

  const contentTransitionStyles: { [id: string]: React.CSSProperties } = {
    entering: { width: window.innerWidth - menuWidth },
    entered: { width: window.innerWidth - menuWidth },
    exiting: { width: window.innerWidth - menuWidth },
    exited: { width: window.innerWidth - menuWidth },
  };

  const toggleMenu = () => {
    if (menuWidth === openMenuSize) {
      setMenuWidth(closedWidth);
    } else {
      setMenuWidth(openMenuSize);
    }
    if (showMenuItems) {
      setTimeout(() => {
        setShowMenuItems(!showMenuItems);
      }, duration);
    } else {
      setShowMenuItems(!showMenuItems);
    }
  };

  const toggleRightSideMenu = () => {
    setMenuWidth(0);
    if (rightMenuWidth === openMenuSize) {
      setRightMenuWidth(closedWidth);
    } else {
      setRightMenuWidth(openMenuSize);
    }
    if (showMenuItems) {
      setTimeout(() => {
        setShowMenuItems(!showMenuItems);
      }, duration);
    } else {
      setShowMenuItems(!showMenuItems);
    }
  }

  const handleCloseRightMenu = (event: React.MouseEvent<any>) => {
    if (event.target !== rightToggleRef.current && width < 768) {
      setRightMenuWidth(0);
      if (showMenuItems) {
        setTimeout(() => {
          setShowMenuItems(!showMenuItems);
        }, duration);
      } else {
        setShowMenuItems(!showMenuItems);
      }
    }
  }

  const handleCloseLeftMenu = (event: React.MouseEvent<any>) => {
    if (event.target !== leftToggleRef.current && width < 768) {
      setMenuWidth(0);
      if (showMenuItems) {
        setTimeout(() => {
          setShowMenuItems(!showMenuItems);
        }, duration);
      } else {
        setShowMenuItems(!showMenuItems);
      }
    }
  }

  const toggleItem = (item: FolderStructure<DashboardType | LookType>) => {
    item.open = !item.open;
    setMenuStructure([...menuStructure]);
  };

  const toggleExplore = (item: FolderStructure<ExploreType>) => {
    item.open = !item.open;
    setExplores([...explores]);
  };

  const openFolder = (item: DashboardType | LookType) => {
    if (!item.in_subfolder) {
      const folder = menuStructure.find((menu: FolderStructure<DashboardType | LookType>) => menu.name.toLowerCase() === item.folder.name.toLowerCase());
      if (folder) {
        folder.open = true;
        setMenuStructure([...menuStructure]);
      }
    } else {
      const parentFolder = menuStructure.find((menu: FolderStructure<DashboardType | LookType>) => menu.id === item.folder.parent_id);
      if (parentFolder) {
        parentFolder.open = true;
        const subfolder = parentFolder.subfolders.find((subItem: FolderStructure<DashboardType | LookType>) => subItem.id === item.folder.id);
        if (subfolder) {
          subfolder.open = true;
          setMenuStructure([...menuStructure]);
        }
      }
    }
  }

  const openExploreFolder = (folderName: string) => {
    const folder = explores.find((explore: FolderStructure<ExploreType>) => explore.name.toLowerCase() === folderName.toLowerCase());
    if (folder) {
      folder.open = true;
      setExplores([...explores]);
    }
  }

  const toggleSubfolder = (folderId: string, parentFolderId: string) => {
    const parentFolder = menuStructure.find((menu: FolderStructure<DashboardType | LookType>) => menu.id === parentFolderId);
    if (parentFolder && parentFolder.subfolders) {
      const subfolder = parentFolder.subfolders.find((subfolder: FolderStructure<DashboardType | LookType>) => subfolder.id === folderId);
      if (subfolder) {
        subfolder.open = !subfolder.open;
      }
    }
    setMenuStructure([...menuStructure]);
  }

  const redirectToDefaultDashboard = (tenant: Tenant) => {
    const maxWeight = Math.max(...tenant.groups.map((group: TenantGroup) => group.weight || 0));
    const maxWeightGroup = tenant.groups
      .filter((group: TenantGroup) => !group.label.toLowerCase().includes('explorer'))
      .find((group: TenantGroup) => group.weight === maxWeight);
    if (maxWeightGroup && maxWeightGroup.default_dashboard) {
      history.push(`/dashboard/${maxWeightGroup.default_dashboard}${!isClientUser ? `?organization=${tenant.organization_id}` : ''}`);
    } else {
      history.push('/');
    }
  }

  const tenantChange = (newTenant: Tenant) => {
    if (tenant?.organization_id !== newTenant.organization_id) {
      const isValid = checkTokenValidity('');
      if (!isValid) {
        setTempTenant(newTenant);
        return;
      }
      setEmbedToken(null);
      setLookerTokenExpirationTime(null);
      setOrganizationId && setOrganizationId(newTenant.organization_id);
      redirectToDefaultDashboard(newTenant);
      setTenant(newTenant);
      setMenuStructure([]);
      setExplores([]);
      setUserManagementType(false);
      setAdminUserTenants([]);
      setOrgName('');
      localStorage.organizationId = newTenant.organization_id;
      if (handleOrganizationChanged) {
        handleOrganizationChanged(newTenant.organization_id);
      }
    }
  }

  const addDashboardToFavorites = async (dashboard: DashboardType | LookType, inSubfolder: boolean) => {
    const isValid = checkTokenValidity(`You can now add ${dashboard.title} to Favorites!`);
    if (!isValid) {
      return;
    }
    try {
      const options = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json', Authorization: embedToken } };
      const body = {
        content_metadata_id: +dashboard.content_metadata_id
      };
      const response: AxiosResponse<FavoriteContent> = await axios.post(`${BASE_URL}/content_favorite`, JSON.stringify(body), options);
      const data = response.data;
      if (!inSubfolder) {
        const parentFolder = menuStructure.find((menu: FolderStructure<DashboardType | LookType>) => +menu.id === +dashboard.folder.id);
        const board = parentFolder?.dashboards.find((item: DashboardType | LookType) => +item.id === +dashboard.id);
        if (board) {
          board.content_favorite_id = data.id;
        } else {
          toast.warning('Couldn\'t find dashboard!');
        }
      } else {
        const parentFolder = menuStructure.find((menu: FolderStructure<DashboardType | LookType>) => menu.subfolders && menu.subfolders.find((folder: FolderStructure<DashboardType | LookType>) => folder.id === dashboard.folder.id))
        parentFolder?.subfolders?.forEach((folder: FolderStructure<DashboardType | LookType>) => {
          const board = folder.dashboards.find((item: DashboardType | LookType) => item.id === dashboard.id);
          if (board) {
            board.content_favorite_id = data.id;
          } else {
            toast.warning('Couldn\'t find dashboard!');
          }
        })
      }
      const favoriteFolder = menuStructure.find((menu: FolderStructure<DashboardType | LookType>) => menu.name.toLowerCase() === 'favorites');
      const menu = menuStructure.filter((item: FolderStructure<DashboardType | LookType>) => item.name.toLowerCase() !== 'favorites');
      if (favoriteFolder) {
        dashboard.content_favorite_id = data.id;
        dashboard.in_subfolder = inSubfolder;
        favoriteFolder.dashboards.push(dashboard);
        setMenuStructure([favoriteFolder, ...menu]);
        toast.success(`Successfully added ${dashboard.title} to Favorites folder!`)
      } else {
        toast.warning('Couldn\'t find Favorites folder!');
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Couldn't add ${dashboard.title} from Favorites folder!`);
      }
    }
  };

  const deleteDashboardFromFavorites = async (dashboard: DashboardType | LookType) => {
    const isValid = checkTokenValidity(`You can now remove ${dashboard.title} from Favorites!`);
    if (!isValid) {
      return;
    }
    try {
      const options = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json', Authorization: embedToken } };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<null> = await axios.delete(`${BASE_URL}/content_favorite/${dashboard.content_favorite_id}`, options);
      if (!dashboard.in_subfolder) {
        const parentFolder = menuStructure.find((menu: FolderStructure<DashboardType | LookType>) => +menu.id === +dashboard.folder.id);
        const board = parentFolder?.dashboards.find((item: DashboardType | LookType) => +item.id === +dashboard.id);
        if (board) {
          board.content_favorite_id = null;
        } else {
          toast.warning('Couldn\'t find dashboard!');
        }
      } else {
        const parentFolder = menuStructure.find((menu: FolderStructure<DashboardType | LookType>) => menu.subfolders && menu.subfolders.find((folder: FolderStructure<DashboardType | LookType>) => folder.id === dashboard.folder.id))
        parentFolder?.subfolders?.forEach((folder: FolderStructure<DashboardType | LookType>) => {
          const board = folder.dashboards.find((item: DashboardType | LookType) => item.id === dashboard.id);
          if (board) {
            board.content_favorite_id = null;
          } else {
            toast.warning('Couldn\'t find dashboard!');
          }
        })
      }
      const favoriteFolder = menuStructure.find((menu: FolderStructure<DashboardType | LookType>) => menu.name.toLowerCase() === 'favorites');
      const menu = menuStructure.filter((item: FolderStructure<DashboardType | LookType>) => item.name.toLowerCase() !== 'favorites');
      if (favoriteFolder) {
        const dashboards = favoriteFolder.dashboards.filter((item: DashboardType | LookType) => item.title.toLowerCase() !== dashboard.title.toLowerCase());
        favoriteFolder.dashboards = dashboards;
        setMenuStructure([favoriteFolder, ...menu]);
        toast.success(`Successfully removed ${dashboard.title} from Favorites folder!`)
      } else {
        toast.warning('Couldn\'t find Favorites folder!');
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Couldn't remove ${dashboard.title} from Favorites folder! `);
      }
    }
  };

  const checkTokenValidity = (message: string): boolean => {
    if (lookerTokenExpirationTime) {
      const isValid = isTokenExpired(lookerTokenExpirationTime);
      if (!isValid) {
        setLookerTokenExpirationTime(null);
        handleInvalidToken(message);
        return false;
      } else {
        return true;
      }
    } else {
      if (tempTenant) {
        return true;
      }
    }
    return false;
  }

  const isTokenExpired = (expirationDate: Date): boolean => isAfter(expirationDate, new Date(Date.now()));

  const handleInvalidToken = async (message: string) => {
    toast.info(`Your session has expired. Automatically refreshing session! ${message}`);
    setMenuLoading(true);
    try {
      const options = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/x-www-form-urlencoded' } };
      const response: AxiosResponse<Token> = await axios.post(`${BASE_URL}/login`, null, options);
      setToken(`token ${response.data.access_token}`);
    } catch (error) {
      toast.warning('Couldn\'t connect to Looker!');
    }
  }

  const changeToClientPortal = (client: Tenant) => {
    setEmbedToken(null);
    setLookerTokenExpirationTime(null);
    setMenuStructure([]);
    setExplores([]);
    setTenant(client);
    setClientTenant(tenant);
    redirectToDefaultDashboard(client);
    setIsClientPortal(!isClientPortal);
    setUserManagementType(!isClientPortal);
  }

  const clientPortalAccess = (groups: number[], tenant: Tenant): Tenant | null => {
    const clientTenantGroups: TenantGroup[] = [];
    const clientExploreGroup: TenantGroup[] = [];
    groups.forEach((groupID: number) => {
      const otherGroup = tenant.otherGroups.find((item: TenantGroup) => item.group_id === groupID && !item.label.toLowerCase().includes('explorer'));
      if (otherGroup) {
        clientTenantGroups.push(otherGroup);
      }
      const explorerGroup = tenant.otherGroups.find((item: TenantGroup) => item.group_id === groupID && item.label.toLowerCase().includes('explorer'));
      if (explorerGroup) {
        clientExploreGroup.push(explorerGroup);
      }
    });
    if (clientTenantGroups.length) {
      const client = {
        tenant: tenant.tenant,
        groups: [...clientTenantGroups, ...clientExploreGroup],
        organization_id: tenant.organization_id,
        name: tenant.name,
        logo_url: tenant.logo_url,
        otherGroups: tenant.otherGroups,
        allowedEmailDomains: tenant.allowedEmailDomains,
      }
      return client;
    } else {
      return null;
    }
  }

  const updateActiveUserRoles = () => {
    setTenant(null);
    setEmbedToken(null);
    setLookerTokenExpirationTime(null);
    setMenuStructure([]);
    setExplores([]);
    setMenuLoading(true);
  }

  const updateUserPortalAccess = (groups: number[]) => {
    if (tenant) {
      const client = clientPortalAccess(groups, tenant);
      setClientTenant(client);
    }
  }

  const handleUpdateUser = async (value: boolean) => {
    if (user) {
      try {
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        const body = {
          user_metadata: {
            subscribed: value,
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.patch(`${prefix}/users/${user.sub}`, body, options);
      } catch (error) {
        if (error.response) {
          toast.warning(error.response.data.error);
        } else {
          toast.warning('Something went wrong with updating profile!');
        }
      }
    }
  }

  const formatUnit = (value: number, unit: string): string => value === 1 ? unit : `${unit}s`;

  const formatDate = (time: string): string => {
    const minutesDifference = Math.abs(differenceInMinutes(new Date(), new Date(time)));
    if (minutesDifference < 60) {
      return `${minutesDifference} ${formatUnit(minutesDifference, 'minute')}`;
    } else {
      const hours = Math.floor(minutesDifference / 60);
      if (hours > 24) {
        const days = Math.floor(hours / 24);
        return `${days} ${formatUnit(days, 'day')} ${hours % 24 > 0 ? `${hours % 24} ${formatUnit(hours % 24, 'hour')}` : ''} ${minutesDifference % 60 > 0 ? `${minutesDifference % 60} ${formatUnit(minutesDifference % 60, 'minute')}` : ''}`;
      } else {
        return `${hours} ${formatUnit(hours % 24, 'hour')} ${minutesDifference % 60 > 0 ? `${minutesDifference % 60} ${formatUnit(minutesDifference % 60, 'minute')}` : ''}`;
      }
    }
  }

  useEffect(() => {
    if (moreInfoDashboards && tenant) {
      const dashboardId = moreInfoDashboards[tenant.name];
      setMoreInfoDashboardId(dashboardId ? dashboardId : null);
    }
  }, [moreInfoDashboards, tenant]);

  useEffect(() => {
    if (dashboardIndexs && tenant) {
      const dashboardId = dashboardIndexs[tenant.name];
      setDashboardIndexId(dashboardId ? dashboardId : null);
    }
  }, [dashboardIndexs, tenant]);

  useEffect(() => {
    if (whatsNewDashboards && tenant) {
      const dashboardId = whatsNewDashboards[tenant.name];
      if (dashboardId) {
        if (typeof dashboardId === 'number') {
          setWhatsNewDashboardId(dashboardId);
        } else if (typeof dashboardId === 'object') {
          setWhatsNewDashboardId(isClientUser ? dashboardId.client : dashboardId.internal);
        }
      } else {
        setWhatsNewDashboardId(null);
      }
    }
  }, [whatsNewDashboards, tenant, isClientUser]);

  useEffect(() => {
    if (tenant && user && !isClientPortal) {
      const client = clientPortalAccess(user[appMetadata as keyof IUser].group_ids, tenant);
      setClientTenant(client);
    }
  }, [tenant, user, appMetadata, isClientPortal]);

  useEffect(() => {
    setIsClientPortal(!!isClientUser);
  }, [isClientUser]);

  useEffect(() => {
    if (isAuthenticated && statuspageId) {
      (async function iife() {
        try {
          const options = { headers: { 'content-type': 'application/json' } };
          const incidentsEndpoints = ['active_maintenance', 'scheduled', 'unresolved'];
          const response: Incident[][] = await Promise.all(incidentsEndpoints.map(async (endpoint: string) => {
            const incidentResponse: AxiosResponse<Incident[]> = await axios.get(`${statuspageUrl}/pages/${statuspageId}/incidents/${endpoint}`, options);
            return incidentResponse.data;
          }));
          if (response[0].length || response[1].length || response[2].length) {
            let activeMaintenances = [...response[0], ...response[1], ...response[2]]
              .filter((item: Incident, index: number, array: Incident[]) => array.length > 1 ? array.map((incident: Incident) => incident.id).indexOf(item.id) === index : item);
            activeMaintenances.forEach((incident: Incident) => {
              toast.info(
                <>
                  <h5>{incident.name}</h5>
                  {incident.scheduled_for ? (
                    <p style={{ padding: '0 5px', fontSize: '14px' }}>Scheduled for: {format(parseISO(incident.scheduled_for), 'MM/dd/yyyy hh:mm a')}</p>
                  ) : incident.updated_at ? (
                    <p style={{ padding: '0 5px', fontSize: '14px' }}>Last updated: {formatDate(incident.updated_at)} ago</p>
                  ) : null}
                </>
              )
            })
            setNotifications({
              active: response[0],
              scheduled: response[1],
              unresolved: response[2],
            })
          } else {
            setNotifications(null);
          }
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with loading upcoming incidents!');
          }
          setNotifications(null);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuspageId, statuspageUrl, isAuthenticated]);

  useEffect(() => {
    if (isAdmin === true && isSevenstepOrganization === true && user && allAvailableTenants) {
      (async function iife() {
        try {
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const adminOrganizations: Tenant[] = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const response: Tenant[] = await Promise.all(allAvailableTenants.map(async (tenant: Tenant) => {
            const adminResponse: AxiosResponse<Role[]> = await axios.get(`${prefix}/organizations/${tenant.organization_id}/members/${user.sub}/roles`, options);
            const isAdmin = adminResponse.data.find((role: Role) => role.name === adminRoleName);
            if (isAdmin) {
              adminOrganizations.push(tenant);
            }
            return tenant;
          }));
          setAdminUserTenants(adminOrganizations);
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with loading admin organizations!');
          }
          setAdminUserTenants([]);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, allAvailableTenants, isAdmin, isSevenstepOrganization]);

  useEffect(() => {
    const isInternetExplorer = /*@cc_on!@*/false || !!(document as any).documentMode;
    setIsIE(isInternetExplorer);
  }, []);

  useEffect(() => {
    if (allAvailableTenants && allAvailableTenants.length && tenant) {
      let activeTenant = allAvailableTenants.find((client: Tenant) => client.organization_id === tenant.organization_id);
      if (isClientPortal && activeTenant && !isClientUser) {
        activeTenant = {
          ...activeTenant,
          groups: activeTenant.otherGroups,
          otherGroups: activeTenant.groups,
        }
      }
      setActiveTenant(activeTenant ? activeTenant : null);
      setIsSevestepOrganization(!!(activeTenant && activeTenant.name === 'sevenstep'));
    }
  }, [allAvailableTenants, tenant, isClientPortal, isClientUser]);

  useEffect(() => {
    setClosedWidth(width < 768 ? 0 : 50);
  }, [width]);

  useEffect(() => {
    if (user && !user[userMetadata as keyof IUser].color) {
      (async function iife() {
        try {
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const color = colors[Math.floor(Math.random() * 5)];
          const body = { user_metadata: { color: color } };
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${user.sub}`, JSON.stringify(body), options);
          setRandomColor(color);
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with updating user color!');
          }
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user && user[isFirstLogin as keyof IUser]) {
      (async function iife () {
        try {
          const options = { headers: { 'content-type': 'application/json' } };
          const response: AxiosResponse<Subscriber[]> = await axios.get(`${statuspageUrl}/pages/${statuspageId}/subscribers?q=${user.email}&state=all`, options);
          const subscribedUser = response.data.find((account: Subscriber) => account.email === user.email);
          if (!subscribedUser) {
            const body = {
              subscriber: {
                email: user.email,
              }
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response: AxiosResponse<Subscriber> = await axios.post(`${statuspageUrl}/pages/${statuspageId}/subscribers`, body, options);
            await handleUpdateUser(true);
          }
        } catch (error) {
          if (error.response.data.message) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with checking subscription to notifications!');
          }
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, statuspageId, statuspageUrl]);

  useEffect(() => {
    if (!isClientUser && user && userOrganizations && userOrganizations.length) {
      const partOfSevenstepOrganization = userOrganizations.find((org: Organization) => org.name === 'sevenstep');
      if (!partOfSevenstepOrganization) {
        (async function iife () {
          try {
            const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
            const sevenstepResponse: AxiosResponse<Organization> = await axios.get(`${prefix}/organizations/name/sevenstep`, options);
            if (sevenstepResponse.data.id) {
              const body = {
                members: [ user.sub ],
              }
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const response: AxiosResponse<null> = await axios.post(`${prefix}/organizations/${sevenstepResponse.data.id}/members`, body, options);
              const updateBody = {
                app_metadata: {
                  group_ids: [ ...user[appMetadata as keyof IUser].group_ids.filter((id: number) => id !== 62), 62 ],
                },
                user_metadata: {
                  organizations: [ ...user[userMetadata as keyof IUser].organizations.filter((name: string) => name !== 'sevenstep'), 'sevenstep' ],
                }
              }
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const updateUserResponse: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${user.sub}`, updateBody, options);
            }
          } catch (error) {
            if (error.response) {
              toast.warning(error.response.data.message);
            } else {
              toast.warning('Something went wrong with adding user to Sevenstep organization!');
            }
          }
        })();
      }
    }
  }, [user, userOrganizations, isClientUser, prefix, appMetadata, userMetadata]);

  useEffect(() => {
    if (isAuthenticated) {
      (async function iife() {
        try {
          const options = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/x-www-form-urlencoded' } };
          const response: AxiosResponse<Token> = await axios.post(`${BASE_URL}/login`, null, options);
          setToken(`token ${response.data.access_token}`);
        } catch (error) {
          toast.warning('Couldn\'t connect to Looker!');
        }
      })();
    }
  }, [BASE_URL, isAuthenticated]);

  useEffect(() => {
    if (availableUserTenants && availableUserTenants.length) {
      const found = availableUserTenants.find((item: Tenant) => item.organization_id === organizationId);
      const selectedTenant = found ? found : availableUserTenants[0];
      const sameGroups = selectedTenant.groups.every((group: TenantGroup) => tenant?.groups.map((group: TenantGroup) => group.group_id).includes(group.group_id))
      if (!tenant || tenant.tenant !== selectedTenant.tenant || !sameGroups) {
        setTenant(selectedTenant);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableUserTenants, organizationId]);

  useEffect(() => {
    if (shouldChangeClient && tempTenant) {
      setTempTenant(null);
      setShouldChangeClient(false);
      tenantChange(tempTenant);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldChangeClient, tempTenant]);

  useEffect(() => {
    if (embedToken && tenant) {
      if (tenant.groups.length) {
        (async function iife() {
          try {
            setMenuLoading(true);
            // Higher value for ID means higher priority
            const dashboardPriority: { [key: string]: number } = {
              'Executive Overview': 100,
              'Leadership Overview': 100,
            };
            const folderPriority: { [key: string]: number } = { 
              'Executives': 100,
              'Leadership': 100,
              'TA Management': 90,
              'Management': 90,
              'Recruiters': 80,
              'Recruiting': 80,
            };
            const options = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json', Authorization: embedToken } };
            const response: AxiosResponse<BasicFolder[]> = await axios.get(`${BASE_URL}/folders`, options);
            const results: AxiosResponse<FolderStructure<DashboardType | LookType>>[] = await Promise.all(response.data.map(async (item: BasicFolder) => await axios.get(`${BASE_URL}/folders/${item.id}`, options)));
            const favorites: FolderStructure<DashboardType | LookType> = {
              can: { index: true, show: true },
              child_count: null,
              content_metadata_id: null,
              created_at: null,
              creator_id: null,
              dashboards: [],
              external_id: '',
              id: 'favorites',
              is_embed: false,
              is_embed_shared_root: false,
              is_embed_users_root: false,
              is_personal: false,
              is_personal_descendant: false,
              is_shared_root: false,
              is_users_root: false,
              looks: [],
              name: 'Favorites',
              parent_id: null,
              open: false,
              subfolders: [],
            };
            const folders: FolderStructure<DashboardType | LookType>[] = results
              .map((folderResponse: AxiosResponse<FolderStructure<DashboardType | LookType>>, i: number) => {
                const itemPriority = Object.keys(folderPriority).find((folder: string) => folder.toLowerCase() === folderResponse.data.name.toLowerCase());
                folderResponse.data.weight = itemPriority ? folderPriority[folderResponse.data.name] : undefined;
                folderResponse.data.open = false;
                folderResponse.data.subfolders = [];
                if (folderResponse.data.looks.length > 0) {
                  folderResponse.data.looks.forEach((look: LookType) => {
                    if (tenant && tenant.tenant.toLowerCase().includes(look.model.id)) {
                      look.is_look = true;
                      folderResponse.data.dashboards.push(look);
                    }
                  })
                };
                folderResponse.data.dashboards = folderResponse.data.dashboards
                  .filter((item: DashboardType | LookType) => item.id !== `${AUTH_DASH_ID}`)
                  .map((x: DashboardType | LookType, i: number) => {
                    if (x.folder.name.toLowerCase() === 'executives' || x.folder.name.toLowerCase() === 'leadership') {
                      const priority = Object.keys(dashboardPriority).find((dashboard: string) => dashboard.toLowerCase() === x.title.toLowerCase());
                      x.weight = priority ? dashboardPriority[x.title] : undefined;
                    } else {
                      x.weight = x.title.toLowerCase().includes('overview') ? 100 : undefined;
                    }
                    if (x.content_favorite_id !== null) {
                      favorites.dashboards.push(x);
                    }
                    return x;
                  })
                  .sort((a: DashboardType | LookType, b: DashboardType | LookType): number => {
                    if (b.weight && a.weight) {
                      return +b.weight - +a.weight > 0 ? 1 : -1;
                    } else if (b.weight && !a.weight) {
                      return 1;
                    } else if (a.weight && !b.weight) {
                      return -1;
                    } else {
                      return a.title.localeCompare(b.title);
                    }
                  });
                return folderResponse.data;
              })
              .sort((a: FolderStructure<DashboardType | LookType>, b: FolderStructure<DashboardType | LookType>) => {
                if (b.weight && a.weight) {
                  return +b.weight - +a.weight > 0 ? 1 : -1;
                } else if (b.weight && !a.weight) {
                  return 1;
                } else if (a.weight && !b.weight) {
                  return -1;
                } else {
                  return a.name.localeCompare(b.name);
                }
              });
            const parentFolders = ['executives', 'recruiters', 'ta management', 'leadership', 'management', 'recruiting'];
            const duplicateFolders: string[] = [];
            folders.forEach((folder: FolderStructure<DashboardType | LookType>) => {
              const parentFolder = folders.find((parent: FolderStructure<DashboardType | LookType>) => parent.id === folder.parent_id);
              if (parentFolder && parentFolders.includes(parentFolder.name.toLowerCase())) {
                parentFolder.subfolders.push(folder);
                folder.dashboards.forEach((dashboard: DashboardType | LookType) => {
                  dashboard.in_subfolder = true;
                })
                duplicateFolders.push(folder.id);
              }
            });
            folders.forEach((folder: FolderStructure<DashboardType | LookType>) => {
              if (folder.subfolders && folder.subfolders.length) {
                folder.subfolders.sort((a: FolderStructure<DashboardType | LookType>, b: FolderStructure<DashboardType | LookType>) => a.name > b.name ? 1 : -1)
              }
            })
            const menu = [favorites, ...folders.filter((item: FolderStructure<DashboardType | LookType>) => item.dashboards.length && !duplicateFolders.includes(item.id) && item.name.toLowerCase() !== 'more info')];
            setMenuStructure(menu);
          } catch (error) {
            toast.warning(`Something went wrong with loading folders!`);
          } finally {
            setMenuLoading(false);
          }
        })();
      } else {
        toast.info('You don\'t have any role assigned!');
        setMenuLoading(false);
        setMenuStructure([]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embedToken, tenant]);

  useEffect(() => {
    if (user && tenant && token) {
      if (!tempTenant) {
        setMenuLoading(true);
        const lookerUser = {
          ...user[appMetadata as keyof IUser],
          external_user_id: user.sub,
          ...user[userMetadata as keyof IUser],
          group_ids: tenant.groups.map((x: TenantGroup) => x.group_id),
        };
        LookerEmbedSDK.createDashboardWithId(AUTH_DASH_ID)
          .withClassName('pixel-frame')
          .withLegacy()
          .build()
          .connect(lookerUser)
          .then((res: { connection: Promise<LookerEmbedDashboard>, url: string | null | undefined }) => {
            res.connection.then(() => {
              const iframe = document.querySelector('.pixel-frame');
              if (iframe && iframe.parentNode !== null) {
                iframe.parentNode.removeChild(iframe);
              }
              (async function iife() {
                const options = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json', Authorization: token } };
                try {
                  const response: AxiosResponse<LookerEmbedUser> = await axios.get(`${BASE_URL}/users/credential/embed/${lookerUser.external_user_id}`, options);
                  if (response.data && response.data.role_ids && response.data.role_ids.length > 0) {
                    const rolesResponse: AxiosResponse<LookerRole[]> = await axios.get(`${BASE_URL}/roles?ids=${response.data.role_ids.join(',')}`, options);
                    if (rolesResponse.status === 200) {
                      const exploreRole = rolesResponse.data.find((role: LookerRole) => role.name.toLowerCase().includes('explorer'));
                      if (exploreRole) {
                        setIsExploreAvailable(true);
                      } else {
                        setIsExploreAvailable(false);
                      }
                    } else {
                      toast.warning('Something went wrong with checking explore availability!');
                      setIsExploreAvailable(false);
                    }
                  } else {
                    setIsExploreAvailable(false);
                  }
                  if (response.data && response.data.id) {
                    const loginResponse: AxiosResponse<Token> = await axios.post(`${BASE_URL}/login/${response.data.id}`, null, options);
                    if (loginResponse && loginResponse.status === 200 && loginResponse.data) {
                      setEmbedToken(`token ${loginResponse.data.access_token}`);
                      setLookerTokenExpirationTime(new Date(Date.now() + loginResponse.data.expires_in * 1000));
                      const iframe = document.querySelector('.pixel-frame');
                      if (iframe && iframe.parentNode !== null) {
                        iframe.parentNode.removeChild(iframe);
                      }
                    }
                  } else {
                    toast.warning('User not found on Looker.');
                  }
                } catch (error) {
                  toast.warning('User not found on Looker.');
                }
              })();
            });
          });
      } else {
        setShouldChangeClient(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user, BASE_URL, tenant, appMetadata, userMetadata]);

  useEffect(() => {
    if (embedToken && isExploreAvailable && tenant && tenant.name !== 'rpo') {
      (async function iife() {
        const exploresPriority: { [key: string]: number } = {
          'Current Requisition Activity': 100,
          'Current Candidate Activity': 90,
          'Historical Requisition Activity': 80,
          'Historical Candidate Activity': 70,
          'Closed Requisitions': 60,
          'New Hire NPS Scores': 50,
        }
        try {
          const options = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json', Authorization: embedToken } };
          const response: AxiosResponse<LookMLModels[]> = await axios.get(`${BASE_URL}/lookml_models`, options);
          const explores: FolderStructure<ExploreType> = {
            can: { index: true, show: true },
            child_count: null,
            content_metadata_id: null,
            created_at: null,
            creator_id: null,
            dashboards: [],
            external_id: '',
            id: 'explores',
            is_embed: false,
            is_embed_shared_root: false,
            is_embed_users_root: false,
            is_personal: false,
            is_personal_descendant: false,
            is_shared_root: false,
            is_users_root: false,
            looks: [],
            name: 'Ad Hoc Analysis',
            parent_id: null,
            open: false,
            subfolders: [],
          };
          response.data.forEach((data: LookMLModels) => {
            data.explores.filter((explore: ExploreType) => !explore.hidden).forEach((item: ExploreType, i: number) => {
              item.group_name = `${data.name}`;
              const itemPriority = Object.keys(exploresPriority).find((explore) => explore.toLowerCase() === item.label.toLowerCase());
              item.weight = itemPriority ? exploresPriority[item.label] : -i - 1;
              item.folder = {
                name: 'Ad Hoc Analysis',
              }
              explores.dashboards.push(item);
            })
          })
          explores.dashboards.sort((a: ExploreType, b: ExploreType) => {
            if (b.weight && a.weight) {
              return +b.weight - +a.weight > 0 ? 1 : -1;
            } else {
              return -1;
            }
          })
          setExplores([explores]);
        } catch (error) {
          toast.warning(`Something went wrong with loading explores!`);
        }
      })();
    }
  }, [embedToken, BASE_URL, isExploreAvailable, tenant]);

  if (loading) {
    return <Loading transparentBackground={false} />;
  }

  if (isIE) {
    history.push('/');
    return (
      <>
        <ToastContainer />
        <IERedirect />
      </>
    );
  }

  if (!isAuthenticated && loginWithRedirect) {
    if (parseParams) {
      const searchParams = parseParams(history.location.search);
      if (searchParams.organization && searchParams.invitation) {
        loginWithRedirect({ organization: searchParams.organization, invitation: searchParams.invitation });
      } else if (searchParams.organization) {
        loginWithRedirect({ organization: searchParams.organization });
      } else {
        loginWithRedirect({});
      }
    } else {
      loginWithRedirect({});
    }
  }

  return (
    <Router history={history}>
      <Sentry.ErrorBoundary fallback={() => <ErrorFallback />}>
        <div id='app' className='h-100'>
          <NavBar
            toggleMenu={toggleMenu}
            toggleRightSideMenu={toggleRightSideMenu}
            randomColor={randomColor}
            tenantChange={tenantChange}
            redirectToDefaultDashboard={redirectToDefaultDashboard}
            selectedTenant={tenant}
            clientTenant={clientTenant}
            changeToClientPortal={changeToClientPortal}
            isClientPortal={isClientPortal}
            moreInfoDashboardId={moreInfoDashboardId}
            notifications={notifications}
            orgName={orgName}
            whatsNewDashboardId={whatsNewDashboardId}
            rightToggleRef={rightToggleRef}
            leftToggleRef={leftToggleRef}
            dashboardIndexId={dashboardIndexId}
          />
          <div
            className='grid-container'
            style={{ gridTemplateColumns: isAuthenticated ? `${menuWidth}px 1fr` : `0px 1fr` }}
          >
            {isAuthenticated && (
              <MobileMenu
                menuWidth={menuWidth}
                menuLoading={menuLoading}
                menuStructure={menuStructure}
                explores={explores}
                toggleMenu={toggleMenu}
                toggleExplore={toggleExplore}
                toggleItem={toggleItem}
                showMenuItems={showMenuItems}
                openFolder={openFolder}
                addDashboardToFavorites={addDashboardToFavorites}
                deleteDashboardFromFavorites={deleteDashboardFromFavorites}
                setSearchBarFocus={setSearchBarFocus}
                searchBarFocus={searchBarFocus}
                activeTenant={activeTenant}
                toggleSubfolder={toggleSubfolder}
                openExploreFolder={openExploreFolder}
                handleCloseMenu={handleCloseLeftMenu}
              />
            )}
            {isAuthenticated && width < 768 && (
              <MobileRightSideMenu
                menuWidth={rightMenuWidth}
                toggleMenu={toggleRightSideMenu}
                tenantChange={tenantChange}
                activeTenant={activeTenant}
                clientTenant={clientTenant}
                changeToClientPortal={changeToClientPortal}
                isClientPortal={isClientPortal}
                moreInfoDashboardId={moreInfoDashboardId}
                notifications={notifications}
                orgName={orgName}
                whatsNewDashboardId={whatsNewDashboardId}
                handleCloseMenu={handleCloseRightMenu}
                openMenuSize={openMenuSize}
                dashboardIndexId={dashboardIndexId}
              />
            )}
            <Transition timeout={duration}>
              {(state: TransitionStatus) => (
                <div
                  className='content-style'
                  style={{
                    overflow: width <= 768 ? 'scroll' : 'auto',
                    height: 'calc(100vh - 50px)',
                    ...contentStyle,
                    ...contentTransitionStyles[state],
                  }}
                >
                  <Switch>
                    <Route path='/' exact render={(props: any) => (
                      <Home
                        {...props}
                        tenant={tenant}
                        redirectToDefaultDashboard={redirectToDefaultDashboard}
                        isSevenstepOrganization={isSevenstepOrganization}
                        tenantChange={tenantChange}
                      />
                    )} />
                    <PrivateRoute path='/profile' exact component={Profile} render={(props: any) => (
                      <Profile {...props} randomColor={randomColor} prefix={prefix} />
                    )} />
                    <AdminRoute path='/users/:id' exact component={User} render={(props: any) => (
                      <User
                        {...props}
                        BASE_URL={BASE_URL}
                        token={token}
                        isSevenstepOrganization={isSevenstepOrganization}
                        activeOrganization={activeOrganization}
                        adminUserTenants={adminUserTenants}
                        userType={userManagementType}
                        isClientPortal={isClientPortal}
                        clientPortalAccess={clientPortalAccess}
                        updateUserPortalAccess={updateUserPortalAccess}
                        updateActiveUserRoles={updateActiveUserRoles}
                        prefix={prefix}
                        statuspageId={statuspageId}
                        statuspageUrl={statuspageUrl}
                        activeTenant={activeTenant}
                        setActiveOrganization={setActiveOrganization}
                      />
                    )} />
                    <AdminRoute path='/users' exact component={UserManagement} render={(props: any) => (
                      <UserManagement
                        {...props}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        BASE_URL={BASE_URL}
                        token={token}
                        isSevenstepOrganization={isSevenstepOrganization}
                        adminUserTenants={adminUserTenants}
                        activeOrganization={activeOrganization}
                        setActiveOrganization={setActiveOrganization}
                        userManagementType={userManagementType}
                        setUserManagementType={setUserManagementType}
                        isClientPortal={isClientPortal}
                        clientPortalAccess={clientPortalAccess}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        prefix={prefix}
                        activeTenant={activeTenant}
                        setOrgName={setOrgName}
                        orgName={orgName}
                        statuspageId={statuspageId}
                        statuspageUrl={statuspageUrl}
                      />
                    )} />
                    <AdminRoute path='/invite-users' exact component={UsersInvitation} render={(props: any) => (
                      <UsersInvitation
                        {...props}
                        colors={colors}
                        activeTenant={activeTenant}
                        isSevenstepOrganization={isSevenstepOrganization}
                        adminUserTenants={adminUserTenants}
                        isClientPortal={isClientPortal}
                        prefix={prefix}
                      />
                    )} />
                    <AdminRoute path='/pending-invitations' exact component={PendingInvitations} render={(props: any) => (
                      <PendingInvitations
                        {...props}
                        activeTenant={activeTenant}
                        isSevenstepOrganization={isSevenstepOrganization}
                        adminUserTenants={adminUserTenants}
                        isClientPortal={isClientPortal}
                        prefix={prefix}
                      />
                    )} />
                    <AdminRoute path='/existing-invited-users' exact component={RecentlyAddedUsers} render={(props: any) =>(
                      <RecentlyAddedUsers
                        {...props}
                        activeTenant={activeTenant}
                        isSevenstepOrganization={isSevenstepOrganization}
                        adminUserTenants={adminUserTenants}
                        isClientPortal={isClientPortal}
                        prefix={prefix}
                      />
                    )} />
                    <PrivateRoute path='/dashboard/:id' exact component={Dashboard} render={(props: any) => (
                      <Dashboard
                        {...props}
                        tenant={tenant}
                        embedToken={embedToken}
                        BASE_URL={BASE_URL}
                        expirationTime={lookerTokenExpirationTime}
                        lookerHost={lookerHost}
                        checkTokenValidity={checkTokenValidity}
                      />
                    )} />
                    <PrivateRoute path='/explore/:name/:id' exact component={Explore} render={(props: any) => (
                      <Explore {...props} tenant={tenant} isExploreAvailable={isExploreAvailable} lookerHost={lookerHost} />
                    )} />
                    <PrivateRoute path='/look/:id' exact component={Look} render={(props: any) => (
                      <Look {...props} tenant={tenant} lookerHost={lookerHost} />
                    )} />
                    <PrivateRoute path='/more-information' exact component={MoreInfo} render={(props: any) => (
                      <MoreInfo {...props} tenant={tenant} lookerHost={lookerHost} moreInfoDashboardId={moreInfoDashboardId} />
                    )} />
                    <PrivateRoute path='/whats-new' exact component={MoreInfo} render={(props: any) => (
                      <MoreInfo {...props} tenant={tenant} lookerHost={lookerHost} moreInfoDashboardId={whatsNewDashboardId} />
                    )} />
                    <PrivateRoute path='/dashboard-index' exact component={MoreInfo} render={(props: any) => (
                      <MoreInfo {...props} tenant={tenant} lookerHost={lookerHost} moreInfoDashboardId={dashboardIndexId} />
                    )} />
                    <PrivateRoute path='/notifications' exact component={Notifications} render={(props: any) => (
                      <Notifications {...props} statuspageUrl={statuspageUrl} statuspageId={statuspageId} prefix={prefix} />
                    )} />
                    <Redirect to='/' />
                  </Switch>
                </div>
              )}
            </Transition>
          </div>
        </div>
      </Sentry.ErrorBoundary>
    </Router>
  );
};

export default Sentry.withProfiler(App);
