import React, { useEffect, useState } from 'react';
import { useAuth0 } from '../react-auth0-spa';
import { Link, useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import GroupSelect from '../components/GroupSelect';
import Loading from '../components/Loading';
import ModalComponent from '../components/Modal';
import OrganizationSelect from '../components/OrganizationSelect';
import ChangePasswordModal from '../components/ChangePasswordModal';
import { toast } from 'react-toastify';
import { TextField, Box, Typography, Avatar, Tooltip } from '@material-ui/core';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { Auth0User, IUser, Organization, Role, MfaEnrollment } from '../types/Auth0.interfaces';
import { ParamTypes, Tenant, TenantGroup, UpdateObject } from '../types/Tenant.interfaces';
import { LookerEmbedUser } from '../types/Looker.interfaces';
import { Subscriber } from '../types/Statuspage.interfaces';
import history from '../utils/history';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BlockIcon from '@material-ui/icons/Block';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@material-ui/icons/Add';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ReplayIcon from '@material-ui/icons/Replay';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { format, parseISO } from 'date-fns';
import useWindowSize from '../utils/useWindowSize';

interface Props {
  BASE_URL: string;
  token: string;
  isSevenstepOrganization: boolean;
  activeOrganization: Tenant | null;
  adminUserTenants: Tenant[];
  userType: boolean;
  isClientPortal: boolean;
  clientPortalAccess: (groups: number[], tenant: Tenant) => Tenant | null;
  updateUserPortalAccess: (groups: number[]) => void;
  updateActiveUserRoles: () => void;
  prefix: string;
  statuspageId: string;
  statuspageUrl: string;
  activeTenant: Tenant | null;
  setActiveOrganization: (value: Tenant | null) => void;
}

const User: React.FC<Props> = ({
  BASE_URL, token, isSevenstepOrganization, activeOrganization, adminUserTenants, statuspageId, activeTenant, setActiveOrganization,
  userType, isClientPortal, clientPortalAccess, updateUserPortalAccess, updateActiveUserRoles, prefix, statuspageUrl,
}) => {
  const { user, getInitials, isClientUser, handleUserUpdate, adminRoleName, userOrganizations } = useAuth0();
  const [auth0User, setAuth0User] = useState<Auth0User | null>();
  const { id } = useParams<ParamTypes>();
  const [updateObject, setUpdateObject] = useState<UpdateObject | null>();
  const [requestCompleted, setRequestCompleted] = useState<boolean>(true);
  const [loadingOrganizations, setLoadingOrganizations] = useState<boolean>(false);
  const [loadingAdminRole, setLoadingAdminRole] = useState<boolean>(false);
  const [userGroups, setUserGroups] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const [roleModal, setRoleModal] = useState<boolean>(false);
  const [blockModal, setBlockModal] = useState<boolean>(false);
  const [verifyUserModal, setVerifyUserModal] = useState<boolean>(false);
  const [removeClientPortalModal, setRemoveClientPortalModal] = useState<boolean>(false);
  const [addClientPortalModal, setAddClientPortalModal] = useState<boolean>(false);
  const [addToModal, setAddToModal] = useState<boolean>(false);
  const [resetMfaModal, setResetMfaModal] = useState<boolean>(false);
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [isUserBlocked, setIsUserBlocked] = useState<boolean>(false);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);
  const [accountOrganizations, setAccountOrganizations] = useState<Organization[]>([]);
  const [organization, setOrganization] = useState<Tenant | null>(null);
  const [userToAddOrganization, setUserToAddOrganization] = useState<Tenant | null>(null);
  const [userToAddGroups, setUserToAddGroups] = useState<number[]>([]);
  const [clientPortal, setClientPortal] = useState<boolean>(false);
  const [userTenants, setUserTenants] = useState<Tenant[]>([]);
  const [differenceTenants, setDifferenceTenants] = useState<Tenant[]>([]);
  const [clientPortalTenant, setClientPortalTenant] = useState<Tenant | null>(null);
  const [canHaveClientPortalAccess, setCanHaveClientPortalAccess] = useState<boolean>(false);
  const [mfaEnrollments, setMfaEnrollments] = useState<MfaEnrollment[]>([]);
  const [loadingMfa, setLoadingMfa] = useState<boolean>(false);
  const [subscriber, setSubscriber] = useState<Subscriber | null>(null);
  const [subscribeModal, setSubscribeModal] = useState<boolean>(false);
  const [removeOrganizationModal, setRemoveOrganizationModal] = useState<boolean>(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (isClientPortal || userType) {
      setClientPortal(true);
    } else {
      setClientPortal(false);
    }
  }, [isClientPortal, userType])

  useEffect(() => {
    if (activeOrganization) {
      setOrganization(activeOrganization);
    }
  }, [activeOrganization]);

  useEffect(() => {
    if (activeTenant && !activeOrganization) {
      setActiveOrganization(activeTenant);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization, activeTenant]);

  useEffect(() => {
    if (auth0User && !isClientUser && activeOrganization && !isClientPortal && activeOrganization.otherGroups.length) {
      const client = clientPortalAccess(auth0User.app_metadata.group_ids, activeOrganization);
      setClientPortalTenant(client);
      if (client === null) {
        const allAccessRole = activeOrganization.groups.filter((group: TenantGroup) => !group.label.toLowerCase().includes('explorer') && auth0User.app_metadata.group_ids.includes(group.group_id));
        if (allAccessRole.length > 0) {
          const allAccess = allAccessRole.find((role: TenantGroup) => role.label.toLowerCase() === (`${activeOrganization.name} - SSR - All Access`).toLowerCase());
          if (allAccess) {
            setCanHaveClientPortalAccess(true);
          } else {
            setCanHaveClientPortalAccess(false);
          }
        }
      }
    }
  }, [auth0User, isClientUser, activeOrganization, clientPortalAccess, isClientPortal]);

  useEffect(() => {
    if (auth0User) {
      (async function iife() {
        try {
          const options = { headers: { 'content-type': 'application/json' } };
          const response: AxiosResponse<Subscriber[]> = await axios.get(`${statuspageUrl}/pages/${statuspageId}/subscribers?q=${auth0User.email}&state=active`, options);
          const subscribedUser = response.data.find((account: Subscriber) => account.email === auth0User.email);
          setSubscriber(subscribedUser ? subscribedUser : null);
        } catch (error) {
          if (error.response.data.error) {
            toast.warning(error.response.data.error);
          } else {
            toast.warning('Something went wrong with checking subscription to notifications!');
          }
          setSubscriber(null);
        }
      })();
    }
  }, [statuspageUrl, statuspageId, auth0User]);

  useEffect(() => {
    if (user && id) {
      (async function iife() {
        try {
          setLoadingMfa(true);
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const response: AxiosResponse<MfaEnrollment[]> = await axios.get(`${prefix}/users/${id}/authenticators`, options);
          setMfaEnrollments(response.data.filter((enrollemnt: MfaEnrollment) => enrollemnt.confirmed && enrollemnt.last_auth_at));
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with loading user MFA enrollments!');
          }
          setMfaEnrollments([]);
        } finally {
          setLoadingMfa(false);
        }
      })();
    }
  }, [user, id, prefix]);

  useEffect(() => {
    if (user && id && activeOrganization) {
      (async function iife() {
        try {
          setLoadingAdminRole(true);
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const response: AxiosResponse<Role[]> = await axios.get(`${prefix}/organizations/${activeOrganization.organization_id}/members/${id}/roles`, options);
          const isAdmin = response.data.find((item: Role) => item.name.toLowerCase() === adminRoleName);
          setIsUserAdmin(!!isAdmin);
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with checking is user admin!');
          }
        } finally {
          setLoadingAdminRole(false);
        }
      })();
    }
  }, [user, id, activeOrganization, prefix, adminRoleName]);

  useEffect(() => {
    if (user && id && !isClientUser && !isClientPortal && activeOrganization) {
      (async function iife() {
        try {
          setLoadingOrganizations(true);
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const response: AxiosResponse<Organization[]> = await axios.get(`${prefix}/users/${id}/organizations`, options);
          const isMember = response.data.find((org: Organization) => org.id === activeOrganization.organization_id);
          if (isMember) {
            setAccountOrganizations(response.data);
          } else {
            setAccountOrganizations([]);
            setAuth0User(null);
            setUserGroups([]);
            toast.warning('User is not a member of organization!');
          }
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with loading user organizations!')
          }
          setAccountOrganizations([]);
        } finally {
          setLoadingOrganizations(false);
        }
      })();
    }
  }, [user, id, isClientUser, prefix, isClientPortal, activeOrganization]);

  useEffect(() => {
    if (isSevenstepOrganization && !isClientUser && adminUserTenants && accountOrganizations.length) {
      const matchingTenants: Tenant[] = [];
      const tenantDifference: Tenant[] = [];
      adminUserTenants.forEach((client: Tenant) => {
        const found = accountOrganizations.find((org: Organization) => org.id === client.organization_id);
        if (found) {
          matchingTenants.push(client);
        } else {
          tenantDifference.push(client);
        }
      });
      setDifferenceTenants(tenantDifference);
      setUserTenants(matchingTenants);
    }
  }, [accountOrganizations, adminUserTenants, isClientUser, isSevenstepOrganization]);

  useEffect(() => {
    if (differenceTenants.length) {
      const client = differenceTenants[0];
      setUserToAddOrganization(client);
    }
  }, [differenceTenants]);

  useEffect(() => {
    if (user && id && activeOrganization) {
      (async function iife() {
        try {
          setRequestCompleted(false);
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const response: AxiosResponse<Auth0User> = await axios.get(`${prefix}/users/${id}`, options);
          setAuth0User(response.data);
          setUserGroups(response.data.app_metadata.group_ids);
          setIsUserBlocked(!!response.data.blocked);
          setIsUserVerified(response.data.email_verified);
        } catch (error) {
          setAuth0User(null);
          setUserGroups([]);
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with loading user!')
          }
        } finally {
          setRequestCompleted(true);
        }
      })();
    }
  }, [id, user, prefix, activeOrganization]);

  const updateUser = async () => {
    if (updateObject) {
      try {
        setLoading(true);
        const options = { headers: { user: JSON.stringify(user), 'content-type': 'application/json' } };
        const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${id}`, updateObject, options);
        if (user && user.sub === id) {
          updateActiveUserRoles();
          handleUserUpdate && await handleUserUpdate();
        } else {
          setAuth0User(response.data);
        }
        toast.success('Successfully updated!');
        setUpdateObject(null);
      } catch (error) {
        if (error.response) {
          toast.warning(error.response.data.message);
        } else {
          toast.warning('Something went wrong with updating user!');
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.info('Nothing to update!');
    }
  }

  const changeUserMeta = (event: React.ChangeEvent<HTMLInputElement>, prop: string) => {
    const changed: UpdateObject = { ...updateObject };
    if (!changed.user_metadata) {
      changed.user_metadata = {};
    }
    changed.user_metadata[prop] = event.target.value;
    setUpdateObject(changed);
  }

  const updateGroups = (value: number[]) => {
    const changed: UpdateObject = { ...updateObject};
    if (!changed.app_metadata) {
      changed.app_metadata = {};
    }
    changed.app_metadata.group_ids = value;
    setUserGroups(value);
    setUpdateObject(changed);
  }

  const handleRemoveGroups = async (data: Auth0User) => {
    try {
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const body = {
        app_metadata: {
          group_ids: data.app_metadata.group_ids.filter((groupId: number) => !activeOrganization?.groups.map((group: TenantGroup) => group.group_id).includes(groupId))
        },
        user_metadata: {
          organizations: data.user_metadata.organizations.filter((orgName: string) => orgName !== activeOrganization?.name)
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${data.user_id}`, body, options);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with removing user roles!');
      }
    }
  }

  const removeMember = async (data: Auth0User) => {
    setRemoveOrganizationModal(false);
    try {
      if (!isClientUser && accountOrganizations.length === 2 && accountOrganizations.find((org: Organization) => org.name === 'sevenstep')) {
        await deleteSevenstepUser(data);
      } else {
        setLoading(true);
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        const body = {
          members: [ data.user_id ]
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response: AxiosResponse<null> = await axios.delete(`${prefix}/organizations/${activeOrganization?.organization_id}/members`, {
          ...options,
          data: body,
        });
        await handleRemoveGroups(data);
        toast.success(`Successfully removed ${data.email} from ${activeOrganization?.tenant} organization!`);
        history.goBack();
      }
    } catch (error) {
      toast.warning(`Couldn't remove ${data.email} from ${activeOrganization?.tenant} organization!`);
    } finally {
      setLoading(false);
    }
  }

  const deleteUser = async (data: Auth0User) => {
    try {
      const lookerOptions = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json', Authorization: token } };
      const lookerResponse: AxiosResponse<LookerEmbedUser> = await axios.get(`${BASE_URL}/users/credential/embed/${data.user_id}`, lookerOptions);
      if (lookerResponse.data.id) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const deleteUserResponse: AxiosResponse<null> = await axios.delete(`${BASE_URL}/users/${lookerResponse.data.id}`, lookerOptions);
        } catch (error) {
          toast.warning('Something went wrong with deleting user in Looker!');
        }
      }
    } catch (error) { }
  }

  const handleAssignRole = async () => {
    setRoleModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const response: AxiosResponse<Role[]> = await axios.get(`${prefix}/roles?name_filter=${adminRoleName}`, options);
      if (response.data[0]) {
        const assignRole: AxiosResponse<null> = await axios.post(`${prefix}/organizations/${activeOrganization?.organization_id}/members/${id}/roles`, { roles: [response.data[0].id] }, options);
        if (assignRole.status === 204) {
          toast.success(`Successfully assigned admin role!`);
          setIsUserAdmin(true);
        } else {
          toast.warning(`Couldn't assign admin role!`);
        }
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with assigning admin role!');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleRemoveAdminRole = async () => {
    setRoleModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const response: AxiosResponse<Role[]> = await axios.get(`${prefix}/roles?name_filter=${adminRoleName}`, options);
      if (response.data[0]) {
        const assignRole: AxiosResponse<null> = await axios.delete(`${prefix}/organizations/${activeOrganization?.organization_id}/members/${id}/roles`, {
          ...options,
          data: { roles: [response.data[0].id] }
        });
        if (isUserAdmin && clientPortalTenant && auth0User) {
          await handleRemoveClientPortalAccess(auth0User, undefined);
        }
        if (assignRole.status === 204) {
          toast.success(`Successfully removed admin role!`);
          setIsUserAdmin(false);
        } else {
          toast.warning(`Couldn't remove admin role!`);
        }
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with removing admin role!');
      }
    } finally {
      setLoading(false);
    }
  }

  const deleteSevenstepUser = async (account: Auth0User) => {
    setDeleteModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<null> = await axios.delete(`${prefix}/users/${account.user_id}`, options);
      await deleteUser(account);
      if (subscriber) {
        const body = {
          subscribers: [ subscriber.id ]
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const unsubscribeResponse: AxiosResponse<Subscriber> = await axios.post(`${statuspageUrl}/pages/${statuspageId}/subscribers/unsubscribe`, body, { headers: { 'content-type': 'application/json' } });
      }
      toast.success(`Successfully deleted ${account.email} from the portal!`);
      history.goBack();
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with deleting user!');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleBlockingUser = async (account: Auth0User) => {
    setBlockModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${account.user_id}`, { blocked: true }, options);
      toast.success(`Successfully blocked ${account.email}!`);
      setIsUserBlocked(true);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with blocking user!');
      }
    } finally {
      setLoading(false);
    }
  }
  
  const handleUnblockingUser = async (account: Auth0User) => {
    setBlockModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${account.user_id}`, { blocked: false }, options);
      toast.success(`Successfully unblocked ${account.email}!`);
      setIsUserBlocked(false);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with unblocking user!');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleVerifyUser = async (account: Auth0User) => {
    setVerifyUserModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${account.user_id}`, { email_verified: true }, options);
      toast.success(`Successfully verified ${account.email}!`);
      setIsUserVerified(true);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with verifying user!');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleRemoveClientPortalAccess = async (account: Auth0User, reload: boolean | undefined) => {
    setRemoveClientPortalModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const updateData = {
        app_metadata: {
          group_ids: account.app_metadata.group_ids
            .filter((id: number) => !clientPortalTenant?.groups
              .filter((group: TenantGroup) => !group.label.toLowerCase().includes('explorer'))
              .map((group: TenantGroup) => group.group_id)
              .includes(id))
        }
      }
      const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${account.user_id}`, updateData, options);
      setAuth0User(response.data);
      setClientPortalTenant(null);
      setUserGroups(response.data.app_metadata.group_ids);
      setCanHaveClientPortalAccess(true);
      updateGroups([...response.data.app_metadata.group_ids]);
      if (user && user.sub === id) {
        updateUserPortalAccess(response.data.app_metadata.group_ids);
      }
      if (reload) {
        toast.success(`Successfully removed client portal access from ${account.email}!`);
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Something went wrong with removing client portal access from ${account.email}!`);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleAddClientPortalAccess = async (account: Auth0User) => {
    setAddClientPortalModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      if (activeOrganization) {
        const maxWeight = Math.max(...activeOrganization.otherGroups.map((group: TenantGroup) => group.weight || 0));
        const maxWeightGroup = activeOrganization.otherGroups.find((group: TenantGroup) => group.weight === maxWeight);
        if (maxWeightGroup) {
          const updateData = { 
            app_metadata: {
              group_ids: [...account.app_metadata.group_ids, maxWeightGroup.group_id]
            }
          }
          const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${account.user_id}`, updateData, options);
          setAuth0User(response.data);
          const client = clientPortalAccess(response.data.app_metadata.group_ids, activeOrganization);
          setClientPortalTenant(client);
          setUserGroups(response.data.app_metadata.group_ids);
          setCanHaveClientPortalAccess(false);
          updateGroups([...response.data.app_metadata.group_ids]);
          if (user && user.sub === id) {
            updateUserPortalAccess(response.data.app_metadata.group_ids);
          }
          toast.success(`Successfully added client portal access to ${account.email}!`)
        }
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Something went wrong with adding client portal access to ${account.email}!`);
      }
    } finally {
      setLoading(false);
    }
  }

  const checkIsActiveUser = (user: IUser, id: string): boolean => user.sub === id;

  const isAllowedDomain = (user: Auth0User, tenant: Tenant) => !!tenant.allowedEmailDomains.find((domain: string) => domain === user.email.split('@')[1]);

  const handleAddUserToOrganization = async () => {
    setAddToModal(false);
    if (auth0User && userToAddOrganization) {
      try {
        const isAllowed = isAllowedDomain(auth0User, userToAddOrganization);
        if (!isAllowed) {
          toast.error(`Can't add user to ${userToAddOrganization.tenant} organization!`);
          return;
        }
        setLoading(true);
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        const data = {
          members: [ auth0User.user_id ]
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response: AxiosResponse<null> = await axios.post(`${prefix}/organizations/${userToAddOrganization.organization_id}/members`, data, options);
        await updateAddedUser(auth0User, userToAddOrganization);
        setUserToAddGroups([]);
        toast.success(`Successfully added ${auth0User.email} to ${userToAddOrganization.tenant}!`);
      } catch (error) {
        if (error.response) {
          toast.warning(error.response.data.message);
        } else {
          toast.warning(`Something went wrong with adding ${auth0User.email} to ${userToAddOrganization.tenant}!`);
        }
      } finally {
        setLoading(false);
      }
    }
  }
  
  const updateAddedUser = async (account: Auth0User, client: Tenant) => {
    try {
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const data = {
        app_metadata: {
          group_ids: [...account.app_metadata.group_ids, ...userToAddGroups.filter((id: number) => !account.app_metadata.group_ids.includes(id))]
        },
        user_metadata: {
          organizations: [...account.user_metadata.organizations.filter((orgName: string) => orgName !== client.name), client.name ]
        }
      }
      const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${account.user_id}`, data, options);
      setAuth0User(response.data);
      setUserGroups(response.data.app_metadata.group_ids);
      const org = userOrganizations?.find((tenant: Organization) => tenant.id === client.organization_id);
      if (org) {
        setAccountOrganizations(prevState => ([...prevState, org]))
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Something went wrong with updating user ${account.email}!`);
      }
    }
  }

  const handleResetMfa = async () => {
    const enrollments = mfaEnrollments.filter((enrollment: MfaEnrollment) => enrollment.type !== 'email');
    if (enrollments.length > 0) {
      try {
        setResetMfaModal(false);
        setLoading(true);
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response: AxiosResponse<string>[] = await Promise.all(
          (enrollments.map(async (enrollment: MfaEnrollment) => await axios.delete(`${prefix}/guardian/enrollments/${enrollment.id}`, options)))
        );
        toast.success('Successfully reseted user MFA enrollment!');
        setMfaEnrollments(mfaEnrollments.filter((enrollment: MfaEnrollment) => enrollment.type === 'email'));
      } catch (error) {
        if (error.response) {
          toast.warning(error.response.data.message);
        } else {
          toast.warning('Something went wrong with reseting user MFA enrollment!');
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.warning('Can\'t reset user email MFA enrollment');
    }
  }

  const handleSubscribeUser = async () => {
    try {
      setLoading(true);
      setSubscribeModal(false);
      const options = { headers: { 'content-type': 'application/json' } };
      const body = {
        subscriber: {
          email: auth0User?.email,
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Subscriber> = await axios.post(`${statuspageUrl}/pages/${statuspageId}/subscribers`, body, options);
      await handleUpdateUser(true);
      setSubscriber(response.data);
      toast.success('Successfully subscribed to notifications!');
    } catch (error) {
      if (error.response.data.error) {
        toast.warning(error.response.data.error[0]);
      } else {
        toast.warning('Something went wrong with subscribing to notifications!');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleUnsubscribeUser = async () => {
    try {
      setLoading(true);
      setSubscribeModal(false);
      const options = { headers: { 'content-type': 'application/json' } };
      const body = {
        subscribers: [ subscriber?.id ]
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Subscriber> = await axios.post(`${statuspageUrl}/pages/${statuspageId}/subscribers/unsubscribe`, body, options);
      await handleUpdateUser(false);
      setSubscriber(null);
      toast.success('Successfully unsubscribed from notifications!');
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.error[0]);
      } else {
        toast.warning(`Something went wrong with unsubscribing user from notifications!`);
      }
    } finally {
      setLoading(false);
    }
  }

  const handleUpdateUser = async (value: boolean) => {
    if (user) {
      try {
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        const body = {
          user_metadata: {
            subscribed: value,
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${auth0User?.user_id}`, body, options);
      } catch (error) {
        if (error.response) {
          toast.warning(error.response.data.message);
        } else {
          toast.warning('Something went wrong with updating profile!');
        }
      }
    }
  }

  const getIcon = (type: string): React.ReactNode => {
    if (type === 'sms' || type === 'totp') {
      return <PhoneIphoneIcon style={{ fontSize: '3rem' }} />;
    } else if (type === 'email') {
      return <MailOutlineIcon style={{ fontSize: '3rem' }} />;
    } else if (type === 'webauthn-platform') {
      return <FingerprintIcon style={{ fontSize: '3rem' }} />;
    } else {
      return <MailOutlineIcon style={{ fontSize: '3rem' }} />;
    }
  }

  const getEnrollmentType = (type: string): string => {
    if (type === 'sms') {
      return 'Phone';
    } else if (type === 'email') {
      return 'Email';
    } else if (type === 'webauthn-platform') {
      return 'Device biometrics';
    } else if (type === 'totp') {
      return 'One-time code';
    } else {
      return 'Email';
    }
  }

  return (
    requestCompleted && !loadingOrganizations && !loadingAdminRole && !loadingMfa ? (
      <Box className='profile'>
        <Box className='wrapper' style={{ padding: '24px' }}>
          <Box style={{ paddingBottom: '20px'}}>
            <Link to='#' onClick={() => history.goBack()} style={{ fontSize: '14px', color: 'black' }}>
              <ArrowBackIcon style={{ fontSize: '14px' }} /> Back to Users
            </Link>
          </Box>
          <Box className='header-contaniner'>
            <Box style={{ display: 'flex', flexGrow: 1 }}>
              <Box>
                <Avatar
                  style={{
                    width: '50px',
                    height: '50px',
                    display: 'inline-flex',
                    fontSize: '24px',
                    background: auth0User && auth0User.user_metadata.color ? auth0User.user_metadata.color : ''
                  }}
                >
                  {auth0User && getInitials && getInitials(auth0User.email, auth0User.user_metadata.first_name, auth0User.user_metadata.last_name)}
                </Avatar>
              </Box>
              <Box style={{ paddingLeft: '20px' }}>
                {auth0User ? (
                  <>
                    <Typography variant='h5' className='header-style'>
                      {auth0User.email}
                      {' '}
                      {isUserVerified ? (
                        <span style={{ fontSize: '1rem' }}>(verified)</span>
                      ) : (
                        <span style={{ fontSize: '1rem' }}>(pending)</span>
                      )}
                      {' '}
                      {isUserBlocked ? (
                        <Tooltip title='Blocked user' placement='bottom-start'>
                          <BlockIcon fontSize='small' style={{ color: '#f44336' }} />
                        </Tooltip>
                      ) : null}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant='h5' className='header-style'>User not found!</Typography>
                  </>
                )}
              </Box>
            </Box>
            <Box>
              {auth0User && user && (
                <UncontrolledButtonDropdown nav>
                  <DropdownToggle caret color='primary' style={{ marginTop: width <= 768 ? '5px' : '0' }}>Actions</DropdownToggle>
                  <DropdownMenu right={width >= 768 ? true : false} left={width < 768 ? true : false}>
                    {!checkIsActiveUser(user, id) && !isClientUser && isSevenstepOrganization && differenceTenants.length && !auth0User.user_metadata.is_client_user ? (
                      <DropdownItem onClick={() => setAddToModal(true)} className='user-menu-dropdown'>
                        <AddIcon fontSize='small' style={{ marginRight: '10px' }} />
                        Add user
                      </DropdownItem>
                    ) : null}
                    {clientPortalTenant && !auth0User.user_metadata.is_client_user && (
                      <DropdownItem onClick={() => setRemoveClientPortalModal(true)} className='user-menu-dropdown'>
                        <ClearIcon fontSize='small' style={{ marginRight: '10px' }} />
                        Remove access to client portal
                      </DropdownItem>
                    )}
                    {canHaveClientPortalAccess && !auth0User.user_metadata.is_client_user && (
                      <DropdownItem onClick={() => setAddClientPortalModal(true)} className='user-menu-dropdown'>
                        <DashboardIcon fontSize='small' style={{ marginRight: '10px' }} />
                        Add access to client portal
                      </DropdownItem>
                    )}
                    <DropdownItem onClick={() => setPasswordModal(true)} className='user-menu-dropdown'>
                      <VpnKeyIcon fontSize='small' style={{ marginRight: '10px' }} />
                      Change password
                    </DropdownItem>
                    {!isUserVerified && auth0User.last_login ? (
                      <DropdownItem onClick={() => setVerifyUserModal(true)} className='user-menu-dropdown'>
                        <VerifiedUserIcon fontSize='small' style={{ marginRight: '10px' }} />
                        Verify user
                      </DropdownItem>
                    ) : null}
                    <DropdownItem onClick={() => setSubscribeModal(true)} className='user-menu-dropdown'>
                      {subscriber ? (
                        <>
                          <NotificationsOffIcon fontSize='small' style={{ marginRight: '10px' }} />
                          Unsubscribe
                        </>
                      ) : (
                        <>
                          <NotificationsActiveIcon fontSize='small' style={{ marginRight: '10px' }} />
                          Subscribe
                        </>
                      )}
                    </DropdownItem>
                    {!checkIsActiveUser(user, id) && (
                      <>
                        <DropdownItem onClick={() => setRoleModal(true)} className='user-menu-dropdown'>
                          <AssignmentIcon fontSize='small' style={{ marginRight: '10px' }} />
                          {!isUserAdmin ? 'Assign admin role' : 'Remove admin role'}
                        </DropdownItem>
                        <DropdownItem divider />
                        {mfaEnrollments.length > 0 ? (
                          <DropdownItem onClick={() => setResetMfaModal(true)} className='user-menu-dropdown'>
                            <ReplayIcon fontSize='small' style={{ marginRight: '10px' }} />
                            Reset MFA
                          </DropdownItem>
                        ) : null}
                        <DropdownItem onClick={() => setBlockModal(true)} className='user-menu-dropdown'>
                          {isUserBlocked ? (
                            <><LockOpenIcon fontSize='small' style={{ marginRight: '10px' }} />Unblock user</>
                          ) : (
                            <><BlockIcon fontSize='small' style={{ marginRight: '10px' }} />Block user</>
                          )}
                        </DropdownItem>
                        {!isClientUser ? (
                          <DropdownItem onClick={() => setRemoveOrganizationModal(true)} className='user-menu-dropdown'>
                            <RemoveCircleOutlineIcon fontSize='small' style={{ marginRight: '10px' }} />
                            Remove from organization
                          </DropdownItem>
                        ) : null}
                      </>
                    )}
                    {!checkIsActiveUser(user, id) && !isUserAdmin && (
                      <DropdownItem onClick={() => setDeleteModal(true)} className='user-menu-dropdown' style={{ color: '#f44336' }}>
                        <DeleteIcon fontSize='small' style={{ marginRight: '10px' }} />
                        Delete user
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}
            </Box>
          </Box>
          {auth0User && (
            <Box className='user-container'>
              <Box className='details-container'>
                <Box component='p' style={{ marginBottom: '5px' }}>
                  <Box component='span' style={{ fontWeight: 'bold' }}>Name: </Box>
                  {auth0User.user_metadata.first_name} {auth0User.user_metadata.last_name}
                </Box>
                <Box component='p' style={{ marginBottom: '5px' }}>
                  <Box component='span' style={{ fontWeight: 'bold' }}>Email: </Box>
                  {auth0User.email}
                </Box>
                <Box component='p' style={{ marginBottom: '5px' }}>
                  <Box component='span' style={{ fontWeight: 'bold' }}>Admin: </Box>
                  {isUserAdmin ? (
                    <CheckCircleIcon fontSize='small' color='primary' style={{ color: '#74c8b0' }} />
                  ) : (
                    <ClearIcon fontSize='small' color='error' />
                  )}
                </Box>
                <Box component='p' style={{ marginBottom: '5px' }}>
                  <Box component='span' style={{ fontWeight: 'bold' }}>Signed up: </Box> 
                  {format(parseISO(auth0User.created_at), 'PPP, pp')}
                </Box>
                <Box component='p' style={{ marginBottom: '5px' }}>
                  <Box component='span' style={{ fontWeight: 'bold' }}>Latest login: </Box>
                  {auth0User.user_metadata.latest_login ? (
                    format(parseISO(auth0User.user_metadata.latest_login), 'PPP, pp')
                    ) : auth0User.last_login ? format(parseISO(auth0User.last_login), 'PPP, pp') : 'Never'}
                </Box>
                <Box component='p' style={{ marginBottom: '5px' }}>
                  <Box component='span' style={{ fontWeight: 'bold' }}>Notifications: </Box>
                  {subscriber ? (
                    <CheckCircleIcon fontSize='small' color='primary' style={{ color: '#74c8b0' }} />
                  ) : (
                    <ClearIcon fontSize='small' color='error' />
                  )}
                </Box>
                {!isClientUser && accountOrganizations.length ? (
                  <Box component='p' style={{ marginBottom: '5px' }}>
                    <Box component='span' style={{ fontWeight: 'bold' }}>Organizations: </Box>
                    {accountOrganizations.map((organization: Organization) => organization.display_name).join(', ')}
                  </Box>
                ) : null}
                {!isClientUser && clientPortalTenant && !auth0User.user_metadata.is_client_user ? (
                  <Box component='p' style={{ marginBottom: '5px'}}>
                    <Box component='span' style={{ fontWeight: 'bold' }}>User has access to {activeOrganization?.tenant} client portal!</Box>
                  </Box>
                ) : null}
                {mfaEnrollments.length ? (
                  <Box>
                    <Box component='p' style={{ marginBottom: '5px'}}>
                      <Box component='span' style={{ fontWeight: 'bold' }}>Multi-factor authentication enrollments:</Box>
                    </Box>
                    {mfaEnrollments.map((enrollment: MfaEnrollment, index: number) => (
                      <Box style={{ display: 'flex', padding: '10px 0', borderBottom: index !== mfaEnrollments.length - 1 ? '1px solid #ccc' : '' }}>
                        <Box style={{ flex: '20%', paddingRight: '5px' }}>
                          {getIcon(enrollment.type)}
                        </Box>
                        <Box style={{ flex: '80%' }}>
                          {enrollment.name ? <Box>{enrollment.name}</Box> : null}
                          <Box>Enrolled using {getEnrollmentType(enrollment.type)}</Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : null}
              </Box>
              <Box className='edit-container'>
                {!isClientUser && isSevenstepOrganization && userTenants.length > 1 && (
                  <Box className='form-control-container' textAlign={width <= 768 ? 'left' : 'right'}>
                    <OrganizationSelect
                      activeOrganization={organization}
                      setActiveOrganization={setOrganization}
                      showLabel={true}
                      adminUserTenants={userTenants}
                      userType={userType}
                      changeParam={false}
                    />
                  </Box>
                )}
                <Box className='form-control-container' textAlign={width <= 768 ? 'left' : 'right'}>
                  <TextField
                    label='First name'
                    className='full-width'
                    defaultValue={auth0User['user_metadata']?.first_name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeUserMeta(event, 'first_name')}
                  />
                </Box>
                <Box className='form-control-container' textAlign={width <= 768 ? 'left' : 'right'}>
                  <TextField
                    label='Last name'
                    className='full-width'
                    defaultValue={auth0User['user_metadata']?.last_name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeUserMeta(event, 'last_name')}
                  />
                </Box>
                <Box className='form-control-container' textAlign={width <= 768 ? 'left' : 'right'}>
                  <GroupSelect
                    groups={userGroups}
                    setGroups={updateGroups}
                    availableTenantGroups={organization ? auth0User.user_metadata.is_client_user === clientPortal ? organization.groups : organization.otherGroups : []}
                    showLabel={true}
                    label='Roles'
                    showOtherGroups={true}
                  />
                </Box>
                <Box className='form-control-container mobile' textAlign={width <= 768 ? 'left' : 'right'}>
                  <Button
                    color='primary'
                    className='green-button'
                    style={{ marginTop: '20px' }}
                    disabled={updateObject ? false : true}
                    onClick={() => updateUser()}
                  >
                    Update User
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {loading && <Loading transparentBackground={true} />}
          <ModalComponent 
            open={deleteModal}
            setOpen={setDeleteModal}
            heading={'Delete user'}
            buttonText={'Yes, delete it'}
            labelText={`
              Are you really sure you want to delete ${auth0User?.email}${isSevenstepOrganization ? ` from ${activeOrganization?.name === 'sevenstep' ? 'all organizations' : `${activeOrganization?.tenant} organization`}` : ''}? 
              This cannot be undone!
            `}
            handleAccept={deleteSevenstepUser}
            data={auth0User}
          />
          <ModalComponent
            open={removeOrganizationModal}
            setOpen={setRemoveOrganizationModal}
            heading='Remove user'
            buttonText='Yes, remove it'
            labelText={`Are you really sure you want to remove ${auth0User?.email} from ${activeOrganization?.tenant}? This cannot be undone!`}
            handleAccept={removeMember}
            data={auth0User}
          />
          <ChangePasswordModal
            openModal={passwordModal}
            setOpenModal={setPasswordModal}
            prefix={prefix}
            userId={id}
            setLoading={setLoading}
            heading={'Change user password'}
          />
          <ModalComponent 
            open={roleModal}
            setOpen={setRoleModal}
            heading={isUserAdmin ? 'Remove admin role' : 'Assign admin role'}
            labelText={`
              Are you really sure you want to ${isUserAdmin ? 'remove' : 'assign'} admin role ${isUserAdmin ? 'from' : 'to'} 
              ${auth0User?.email}${isSevenstepOrganization ? ` in ${activeOrganization?.tenant} organization` : ''}?
            `}
            buttonText={`Yes, ${isUserAdmin ? 'remove' : 'assign'} it`}
            handleAccept={isUserAdmin ? handleRemoveAdminRole : handleAssignRole}
            data={auth0User}
          />
          <ModalComponent
            open={blockModal}
            setOpen={setBlockModal}
            heading={`${isUserBlocked ? 'Unblock' : 'Block'} user`}
            labelText={`Are you really sure you want to ${isUserBlocked ? 'unblock' : 'block'} ${auth0User?.email}?`}
            buttonText={`Yes, ${isUserBlocked ? 'unblock' : 'block'} it`}
            handleAccept={isUserBlocked ? handleUnblockingUser : handleBlockingUser}
            data={auth0User}
          />
          <ModalComponent
            open={verifyUserModal}
            setOpen={setVerifyUserModal}
            heading={'Verify user'}
            labelText={`Are you really sure you want to verify ${auth0User?.email}? This cannot be undone!`}
            buttonText={'Yes, verify'}
            handleAccept={handleVerifyUser}
            data={auth0User}
          />
          <ModalComponent
            open={removeClientPortalModal}
            setOpen={setRemoveClientPortalModal}
            heading={'Remove access to client portal'}
            labelText={`Are you really sure you want to remove access to ${activeOrganization?.tenant} client portal from ${auth0User?.email}? This cannot be undone!`}
            buttonText={'Yes, remove it'}
            handleAccept={handleRemoveClientPortalAccess}
            data={auth0User}
          />
          <ModalComponent
            open={addClientPortalModal}
            setOpen={setAddClientPortalModal}
            heading={'Add access to client portal'}
            labelText={`Are you really sure you want to add access to ${activeOrganization?.tenant} client portal to ${auth0User?.email}? This cannot be undone!`}
            buttonText={'Yes, add it'}
            handleAccept={handleAddClientPortalAccess}
            data={auth0User}
          />
          <ModalComponent
            open={resetMfaModal}
            setOpen={setResetMfaModal}
            heading={'Reset MFA'}
            labelText={`Are you really sure you want to reset MFA enrollment for ${auth0User?.email}? This cannot be undone!`}
            buttonText={'Yes, reset it'}
            handleAccept={handleResetMfa}
          />
          <ModalComponent
            open={subscribeModal}
            setOpen={setSubscribeModal}
            heading={`${!subscriber ? 'Subscribe to' : 'Unsubscribe from'} notifications`}
            buttonText={`Yes, ${!subscriber ? 'subscribe' : 'unsubscribe'}`}
            labelText={`Are you really sure you want to ${!subscriber ? 'subscribe to' : 'unsubscribe from'} notifications?`}
            handleAccept={!subscriber ? handleSubscribeUser : handleUnsubscribeUser}
          />
          <ModalComponent
            open={addToModal}
            setOpen={setAddToModal}
            heading={`Add user to ${userToAddOrganization?.tenant}`}
            buttonText={'Yes, add user'}
            handleAccept={handleAddUserToOrganization}
          >
            <>
              <Box style={{ textAlign: 'center' }}>
                <OrganizationSelect
                  activeOrganization={userToAddOrganization}
                  setActiveOrganization={setUserToAddOrganization}
                  showLabel={true}
                  adminUserTenants={differenceTenants}
                  userType={userType}
                  setGroups={setUserToAddGroups}
                  changeParam={false}
                />
              </Box>
              <Box style={{ textAlign: 'center' }}>
                <GroupSelect
                  groups={userToAddGroups}
                  setGroups={setUserToAddGroups}
                  availableTenantGroups={userToAddOrganization ? userToAddOrganization.groups : []}
                  showLabel={true}
                  label='Roles'
                  showOtherGroups={false}
                />
              </Box>
            </>
          </ModalComponent>
        </Box>
      </Box>
    ) : <Loading transparentBackground={true} />
  )
};

export default User;
