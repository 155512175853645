import React, { useEffect, useState } from 'react';
import { Modal, makeStyles, Box, Typography, Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { useAuth0 } from '../react-auth0-spa';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { format, parseISO, subDays, isValid, isAfter, isBefore } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { useDebouncedCallback } from 'use-debounce';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend, ResponsiveContainer } from 'recharts';
import { Auth0User, DailyStats } from '../types/Auth0.interfaces';
import { Tenant } from '../types/Tenant.interfaces';
import GraphLoading from '../components/GraphLoading';

const useStyles = makeStyles(() => ({
  modal: {
    position: 'absolute',
    background: '#fff',
    borderRadius: '10px',
    padding: '10px',
    maxWidth: 600,
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  container: {
    padding: '20px 0',
  },
  heading: {
    fontSize: '24px',
    textAlign: 'center',
    paddingBottom: '10px',
    position: 'relative',
  },
  graphHeading: {
    fontSize: '18px',
    textAlign: 'center',
    paddingTop: '10px',
  },
  wrapper: {
    padding: '20px'
  },
  paragraph: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  datePickerWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '-20%',
    width: '30px',
    height: '30px',
  }
}))

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  isSevenstepOrganization: boolean;
  usersNumberInOrg: number;
  activeOrganization: Tenant | null;
  usersType: boolean;
  prefix: string;
}

const UsersStats: React.FC<Props> = ({ open, setOpen, isSevenstepOrganization, usersNumberInOrg, activeOrganization, usersType, prefix }) => {
  const { user } = useAuth0();
  const [activeUsersNumber, setActiveUsersNumber] = useState<number>(0);
  const [dailyStats, setDailyStats] = useState<DailyStats[]>([]);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const [totalUsersInApp, setTotalUsersInApp] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const classes = useStyles();

  useEffect(() => {
    const today = format(Date.now(), 'yyyy-MM-dd');
    const daysBefore = format(subDays(Date.now(), 10), 'yyyy-MM-dd');
    setToDate(today);
    setFromDate(daysBefore);
  }, []);

  useEffect(() => {
    if (user && isSevenstepOrganization) {
      (async function iife() {
        try {
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const response: AxiosResponse<number> = await axios.get(`${prefix}/stats/active-users`, options);
          setActiveUsersNumber(response.data);
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with getting number of active users!');
          }
          setActiveUsersNumber(0);
        }
      })();
    }
  }, [user, prefix, isSevenstepOrganization]);

  useEffect(() => {
    if (user && isSevenstepOrganization && fromDate && toDate) {
      (async function iife() {
        try {
          setLoading(true);
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const response: AxiosResponse<DailyStats[]> = await axios.get(`${prefix}/stats/daily?from=${fromDate.replace(/-/g, '')}&to=${toDate.replace(/-/g, '')}`, options);
          setDailyStats(response.data);
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with getting users daily stats!');
          }
          setDailyStats([]);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [user, prefix, isSevenstepOrganization, fromDate, toDate]);

  useEffect(() => {
    if (user && isSevenstepOrganization) {
      (async function iife() {
        try {
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const response: AxiosResponse<{ limit: number, start: number, total: number, users: Auth0User[], length: number }> = await axios.get(`${prefix}/users?include_totals=true`, options);
          setTotalUsersInApp(response.data.total);
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with getting total number of unique users!');
          }
          setTotalUsersInApp(0);
        }
      })();
    }
  }, [user, isSevenstepOrganization, prefix]);

  const setDateValue = (setDate: (value: any) => void, date: any) => {
    setDate(format(date, 'yyyy-MM-dd'));
  }

  const debounced = useDebouncedCallback(setDateValue, 1000);

  const errorMessage = () => {
    toast.warning('Date is not valid!');
  }

  const debouncedError = useDebouncedCallback(errorMessage, 1000);

  const handleDateChanged = (date: any, setDate: (value: any) => void, after: boolean) => {
    const valid = isValid(date);
    if (valid) {
      if (after && toDate && isBefore(date, new Date(toDate))) {
        debounced(setDate, date);
      } else if (!after && fromDate && isAfter(date, new Date(fromDate))) {
        debounced(setDate, date);
      } else {
        debouncedError();
      }
    } else {
      debouncedError();
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box className={classes.modal}>
        <Box className={classes.wrapper}>
          <Typography className={classes.heading}>
            Users Stats
            <Box className={classes.closeBtn} onClick={() => setOpen(false)}>
              <Tooltip title={'Close'} placement='bottom'>
                <IconButton>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
          </Typography>
          <Box className={classes.container}>
            <Box component='p' className={classes.paragraph}>
              <Box component='span' style={{ fontWeight: 'bold' }}>Active users in portal: {activeUsersNumber}</Box>
              <Tooltip title={'Number of active users that logged in during the last 30 days'} placement='right'>
                <InfoIcon fontSize='small' style={{ marginLeft: '10px', color: '#7C7C7D' }}  />
              </Tooltip>
            </Box>
            <Box component='p' className={classes.paragraph}>
              <Box component='span' style={{ fontWeight: 'bold' }}>Number of {usersType ? 'client' : 'internal'} {activeOrganization?.tenant} users: {usersNumberInOrg}</Box>
              <Tooltip title={`Total number of ${usersType ? 'client' : 'internal'} ${activeOrganization?.tenant} members`} placement='right'>
                <InfoIcon fontSize='small' style={{ marginLeft: '10px', color: '#7C7C7D' }}  />
              </Tooltip>
            </Box>
            <Box component='p' className={classes.paragraph}>
              <Box component='span' style={{ fontWeight: 'bold' }}>Total number of unique users: {totalUsersInApp}</Box>
              <Tooltip title={'Total number of unique users in portal'} placement='right'>
                <InfoIcon fontSize='small' style={{ marginLeft: '10px', color: '#7C7C7D' }}  />
              </Tooltip>
            </Box>
            <Box>
              <Typography className={classes.graphHeading}>Users Activity</Typography>
            </Box>
            <Box style={{ marginBottom: '20px' }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box className={classes.datePickerWrapper}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-picker-start'
                    label='Start date'
                    value={fromDate}
                    onChange={(date: any) => handleDateChanged(date, setFromDate, true)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    style={{ maxWidth: '170px', marginRight: '20px' }}
                    maxDate={toDate}
                    autoOk={true}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='MM/dd/yyyy'
                    margin='normal'
                    id='date-picker-end'
                    label='End date'
                    value={toDate}
                    onChange={(date: any) => handleDateChanged(date, setToDate, false)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    minDate={fromDate}
                    disableFuture={true}
                    style={{ maxWidth: '170px', marginRight: '20px' }}
                    autoOk={true}
                  />
                </Box>
              </MuiPickersUtilsProvider>
            </Box>
            <Box className='chart-container'>
              {dailyStats.length ? (
                <ResponsiveContainer width='100%' height='100%'>
                  <LineChart
                    width={500}
                    height={300}
                    data={dailyStats}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='date' tickFormatter={(date: string) => format(parseISO(date), 'dd MMM')} />
                    <YAxis />
                    <ChartTooltip
                      formatter={(value: string | number | (string | number)[], name: string) => [value, (name.charAt(0).toUpperCase() + name.slice(1)).replace(/_/g, ' ')]}
                      labelFormatter={(label: string | number) => typeof label === 'string' ? format(parseISO(label), 'dd MMM') : label}
                    />
                    <Legend formatter={(value: string) => (value.charAt(0).toUpperCase() + value.slice(1)).replace(/_/g, ' ')} margin={{ top: 40 }} />
                    <Line type='monotone' dataKey='logins' stroke='#74c8b0' activeDot={{ r: 8 }} />
                    <Line type='monotone' dataKey='leaked_passwords' stroke='#EE3B32' />
                    <Line type='monotone' dataKey='signups' stroke='#1e4f61' />
                  </LineChart>
                </ResponsiveContainer>
              ) : !loading ? (
                <Box style={{ padding: '20px 0' }}>
                  No data for this period: {fromDate && format(new Date(fromDate), 'MM/dd/yyyy')} - {toDate && format(new Date(toDate), 'MM/dd/yyyy')}
                </Box>
              ) : null}
              {loading && <GraphLoading />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default UsersStats;
