import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core'

const ErrorFallback = () => {
  return (
    <Box style={{ padding: '20px' }}>
      <p>An error has occured. Go back to <Link to='/'>Home page.</Link> If that doesn't help try refreshing page.</p>
    </Box>
  )
}

export default ErrorFallback;
