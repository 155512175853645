import React, { useEffect } from 'react';
import { useAuth0 } from '../react-auth0-spa';
import { Tenant, TenantGroup } from '../types/Tenant.interfaces';
import { IUser } from '../types/Auth0.interfaces';
import { LookerEmbedDashboard, LookerEmbedSDK } from '../utils/embed_sdk';
import { DashboardEvent } from '../utils/types';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';

interface Props {
  tenant: Tenant;
  lookerHost: string;
  moreInfoDashboardId: number | null;
}

const MoreInfo: React.FC<Props> = ({ tenant, lookerHost, moreInfoDashboardId }) => {
  const { loading, user, userMetadata, appMetadata } = useAuth0();
  LookerEmbedSDK.init(lookerHost, `/.netlify/functions/auth_utils`);

  const destroyExisting = (className: string) => {
    const iframe = document.querySelector(`.${className}`);
    if (iframe && iframe.parentNode !== null) {
      iframe.parentNode.removeChild(iframe);
    }
  };

  useEffect(() => {
    if (moreInfoDashboardId && user && tenant) {
      const getUser = () => {
        return {
          ...user[appMetadata as keyof IUser],
          external_user_id: user.sub,
          ...user[userMetadata as keyof IUser],
          group_ids: tenant.groups.map((group: TenantGroup) => group.group_id),
        };
      };
      const className = 'looker-embed';
      destroyExisting(className);
      const embed = LookerEmbedSDK.createDashboardWithId(moreInfoDashboardId, undefined)
        .withClassName(className)
        .withNext();
      embed
        .on('dashboard:run:complete', (event: DashboardEvent) => { })
        .on('dashboard:filters:changed', (event: DashboardEvent) => { })
        .build()
        .connect(getUser(), '#dash-wrapper')
        .then((res: { connection: Promise<LookerEmbedDashboard>, url: string | null | undefined }) => {
          res.connection.then((dashboard: LookerEmbedDashboard) => { });
        })
        .catch((error: Error) => {
          toast.warning('Connection error!');
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreInfoDashboardId, user, tenant, userMetadata, appMetadata]);

  return (
    <>
      {loading || !user ? <Loading /> : <div id='dash-wrapper'></div>}
    </>
  );
}

export default MoreInfo;
