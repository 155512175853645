import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from './react-auth0-spa';
import history from './utils/history';
import { AppObject } from './types/Auth0.interfaces';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const onRedirectCallback = (appState: AppObject) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const organizations = JSON.parse(process.env.REACT_APP_ORGANIZATIONS as string);
const organizationName = window.location.hostname.split('.')[0];
const organization = organizations.find((org: { [key: string]: string }) => org.hasOwnProperty(organizationName));

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    organization={organization ? organization[organizationName] : undefined}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);
