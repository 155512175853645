import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { InputAdornment, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Dashboard, Explore, Look } from '../types/Looker.interfaces';
import { Tenant } from '../types/Tenant.interfaces';
import useWindowSize from '../utils/useWindowSize';
import history from '../utils/history';
import { useAuth0 } from '../react-auth0-spa';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
  searchBarContent: (Dashboard | Explore | Look)[];
  openFolder: (item: Dashboard | Look,) => void;
  navClick: (width: number) => void;
  searchBarFocus: boolean;
  activeTenant: Tenant | null;
  openExploreFolder: (folderName: string) => void;
}

const SearchBar: React.FC<Props> = ({ searchBarContent, openFolder, navClick, searchBarFocus, activeTenant, openExploreFolder }) => {
  const { isClientUser } = useAuth0();
  const { width } = useWindowSize();

  return (
    <Autocomplete
      id='search-bar'
      options={searchBarContent}
      style={{ width: '100%', padding: '0 20px' }}
      forcePopupIcon={false}
      openOnFocus={false}
      clearOnBlur={false}
      clearOnEscape={true}
      onChange={(event: React.ChangeEvent<{}>, item: any) => {
        if (item) {
          if (item.title) {
            openFolder(item);
            navClick(width);
            history.push(!item.is_look ?
              `/dashboard/${item.id}${!isClientUser ? `?organization=${activeTenant?.organization_id}` : ''}`
              : `/look/${item.id}${!isClientUser ? `?organization=${activeTenant?.organization_id}` : ''}`
            );
          } else {
            openExploreFolder(item.folder.name);
            navClick(width);
            history.push(`/explore/${item.group_name.replace(/_/g, '-')}/${item.name.replace(/_/g, '-')}`);
          }
        }
      }}
      getOptionLabel={(item: any) => item.title || item.label}
      groupBy={(item: any) => item.folder.name}
      renderOption={(item: any) => item.title ? (
        <Link
          to={!item.is_look ?
            `/dashboard/${item.id}${!isClientUser ? `?organization=${activeTenant?.organization_id}` : ''}`
            : `/look/${item.id}${!isClientUser ? `?organization=${activeTenant?.organization_id}` : ''}`}
          style={{ color: '#1e5061', width: '100%', fontSize: '14px' }}
        >
          {item.title}
        </Link>
      ) : (
        <Link
          to={`/explore/${item.group_name.replace(/_/g, '-')}/${item.name.replace(/_/g, '-')}`}
          style={{ color: '#1e5061', width: '100%', fontSize: '14px' }}
        >
          {item.label}
        </Link>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          id='standard-basic'
          placeholder='Search...'
          autoFocus={searchBarFocus}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon className='search-icon' />
              </InputAdornment>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              paddingLeft: '20px'
            }
          }}
        />
      )}
      noOptionsText='No dashboards or explores!'
    />
  )
}

export default SearchBar;
