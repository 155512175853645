import React, { useState, useRef, useEffect } from 'react';
import { useAuth0 } from '../react-auth0-spa';
import axios, { AxiosResponse } from 'axios';
import MaterialTable, { MTableToolbar, Column, MTableBodyRow, Action } from 'material-table';
import history from '../utils/history';
import { TableUser, Auth0User, Role, Member, Organization } from '../types/Auth0.interfaces';
import { Avatar, Box, Chip, TablePagination, Tooltip, makeStyles, Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import ModalComponent from '../components/Modal';
import { GreenSwitch } from '../components/GreenSwitch';
import { format, parseISO } from 'date-fns';
import AssignmentIcon from '@material-ui/icons/Assignment';
import useWindowSize from '../utils/useWindowSize';
import { Tenant, TenantGroup } from '../types/Tenant.interfaces';
import { LookerEmbedUser } from '../types/Looker.interfaces';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GroupIcon from '@material-ui/icons/Group';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import OrganizationSelect from '../components/OrganizationSelect';
import InvitationWidget from '../components/InvitationWidget';
import UsersStats from '../components/UsersStats';
import { Subscriber } from 'src/types/Statuspage.interfaces';

const useStyles = makeStyles((theme: any) => ({
  highlight: {
    backgroundColor: '#74c8b0 !important',
    '& *': {
      color: 'rgba(255, 255, 255, 1)',
    }
  },
  modal: {
    position: 'absolute',
    background: '#fff',
    borderRadius: '10px',
    padding: '24px',
    maxWidth: 500,
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '492px',
    textAlign: 'center',
  },
}));

interface Props {
  pageNumber: number;
  setPageNumber: (value: number) => void;
  BASE_URL: string;
  token: string;
  isSevenstepOrganization: boolean;
  adminUserTenants: Tenant[];
  activeOrganization: Tenant | null;
  setActiveOrganization: (value: Tenant | null) => void;
  setUserManagementType: (value: boolean) => void;
  userManagementType: boolean;
  isClientPortal: boolean;
  clientPortalAccess: (groups: number[], tenant: Tenant) => Tenant | null;
  searchValue: string;
  setSearchValue: (value: string) => void;
  prefix: string;
  activeTenant: Tenant | null;
  setOrgName: (value: string) => void;
  orgName: string;
  statuspageId: string;
  statuspageUrl: string;
}

const UserManagement: React.FC<Props> = ({
  pageNumber, setPageNumber, BASE_URL, token, isSevenstepOrganization, adminUserTenants, activeOrganization, prefix, activeTenant, setOrgName, statuspageId,
  setActiveOrganization, setUserManagementType, userManagementType, isClientPortal, clientPortalAccess, searchValue, setSearchValue, orgName, statuspageUrl,
}) => {
  const { user, getInitials, parseParams, organizationId, isClientUser, adminRoleName } = useAuth0();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<TableUser[]>([]);
  const [numberOfUsers, setNumberOfUsers] = useState<number>(0);
  const [usersPerPage, setUsersPerPage] = useState<number>(50);
  const [rolesParams, setRolesParams] = useState<string>('');
  const [assignModal, setAssignModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [adminModal, setAdminModal] = useState<boolean>(false);
  const [deleteMultipleModal, setDeleteMultipleModal] = useState<boolean>(false);
  const [multipleAdminModal, setMultipleAdminModal] = useState<boolean>(false);
  const [addExistingUserModal, setAddExistingUserModal] = useState<boolean>(false);
  const [usersStatsModal, setUsersStatsModal] = useState<boolean>(false);
  const [userToAssignRole, setUserToAssignRole] = useState<TableUser | null>();
  const [toolbarHeight, setToolbarHeight] = useState<number>(0);
  const [adminRoleData, setAdminRoleData] = useState<Role | undefined>();
  const [groups, setGroups] = useState<TenantGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [memberToRemove, setMemberToRemove] = useState<TableUser | null>();
  const [pageOrganizationId, setPageOrganizationId] = useState<string>('');
  const [usersToDelete, setUsersToDelete] = useState<TableUser[] | null>(null);
  const [invitationOrganization, setInvitationOrganization] = useState<Tenant | null>(null);
  const [userType, setUserType] = useState<boolean>(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [removeOrganizationModal, setRemoveOrganizationModal] = useState<boolean>(false);
  const [userToRemove, setUserToRemove] = useState<TableUser | null>(null);
  const [disabledToogle, setDisableToogle] = useState<boolean>(false);
  const tableRef = useRef<any>();
  const toolbarRef = useRef<any>();
  const { height } = useWindowSize();
  const { search } = useLocation();
  const classes = useStyles();

  useEffect(() => {
    if (activeOrganization) {
      setInvitationOrganization(activeOrganization);
      setDisableToogle(!activeOrganization.otherGroups.length);
    }
  }, [activeOrganization]);

  useEffect(() => {
    setUserType(userManagementType);
  }, [userManagementType]);

  useEffect(() => {
    if (activeOrganization) {
      if (isSevenstepOrganization) {
        setPageOrganizationId(activeOrganization.organization_id);
        setGroups([...activeOrganization.groups.map((group: TenantGroup) => ({ ...group, is_active: false }))]);
      } else {
        setPageOrganizationId(organizationId ? organizationId : '');
        setGroups([...activeOrganization.groups.map((group: TenantGroup) => ({ ...group, is_active: false }))]);
      }
    }
  }, [activeOrganization, isSevenstepOrganization, organizationId]);

  useEffect(() => {
    const additionalHeight = !!isClientUser || isClientPortal ? 38 : 0;
    setToolbarHeight(toolbarRef.current.clientHeight + additionalHeight);
  }, [isClientUser, isClientPortal]);

  useEffect(() => {
    if (pageOrganizationId && user) {
      (async function iife() {
        try {
          setIsLoading(true);
          let membersArray: Member[] = [];
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const response: AxiosResponse<{ limit: number, members: Member[], total: number, start: number }> = await axios.get(`${prefix}/organizations/${pageOrganizationId}/members?page=0&per_page=100&include_totals=true`, options);
          membersArray.push(...response.data.members);
          const pagination = Math.floor((response.data.total - 1) / response.data.limit);
          if (pagination > 0) {
            const restMembers = Array.from({ length: pagination }, (_, i) => i + 1);
            const results = await Promise.all(restMembers.map(async (page: number) => await axios.get(`${prefix}/organizations/${pageOrganizationId}/members?page=${page}&per_page=100&include_totals=true`, options)));
            results.forEach((res: AxiosResponse<{ limit: number, members: Member[], total: number, start: number }>) => {
              membersArray.push(...res.data.members);
            });
          }
          setMembers([...membersArray]);
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with getting members of organization!');
          }
          setMembers([]);
          setIsLoading(false);
        }
      })()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOrganizationId, user]);

  useEffect(() => {
    if (activeTenant && parseParams && adminUserTenants.length && isSevenstepOrganization) {
      const params = parseParams(search);
      if (params.org) {
        let organization = adminUserTenants.find((org: Tenant) => org.name === params.org);
        if (organization) {
          if (params['client-users']) {
            const clientUserParam = JSON.parse(params['client-users']);
            setUserManagementType(clientUserParam);
            const groupType = organization.groups[0].client_group;
            if (groupType !== clientUserParam) {
              organization = {
                ...organization,
                groups: organization.otherGroups,
                otherGroups: organization.groups,
              }
            }
          } else {
            setUserManagementType(!!isClientUser);
          }
          organization && setActiveOrganization(organization);
          organization && setOrgName(organization.name)
        }
      } else {
        setActiveOrganization(activeTenant);
        setOrgName(activeTenant.name);
        setUserManagementType(!!isClientUser);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parseParams, adminUserTenants, activeTenant, search, isSevenstepOrganization, isClientUser]);

  useEffect(() => {
    if (!isSevenstepOrganization && parseParams && activeOrganization) {
      const params = parseParams(search);
      if (params['client-users']) {
        const clientUserParam = !!(params['client-users'] === 'true');
        setUserManagementType(clientUserParam);
        const groupType = activeOrganization.groups[0].client_group;
        if (groupType !== clientUserParam) {
          const organization = {
            ...activeOrganization,
            groups: activeOrganization.otherGroups,
            otherGroups: activeOrganization.groups,
          }
          organization && setActiveOrganization(organization);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isSevenstepOrganization, parseParams, activeOrganization])

  useEffect(() => {
    if (!isSevenstepOrganization && !adminUserTenants.length && activeTenant) {
      setActiveOrganization(activeTenant);
      setUserManagementType(!!isClientUser);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSevenstepOrganization, adminUserTenants, activeTenant, isClientUser]);

  useEffect(() => {
    if (parseParams && user && members.length) {
      const params = parseParams(search);
      if (params.page && params['page-size']) {
        setPageNumber(+params.page - 1);
        setUsersPerPage(+params['page-size']);
        const paramsRoles = params.roles ? params.roles : '';
        setRolesParams(paramsRoles);
        handleGetUsers(+params.page - 1, +params['page-size'], paramsRoles, activeOrganization, searchValue);
      } else {
        setPageNumber(0);
        setUsersPerPage(10);
        setRolesParams('');
        handleGetUsers(0, 10, '', activeOrganization, searchValue);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, parseParams, setPageNumber, user, members]);

  useEffect(() => {
    if (user) {
      (async function iife() {
        try {
          const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
          const response: AxiosResponse<Role[]> = await axios.get(`${prefix}/roles?name_filter=${adminRoleName}`, options);
          setAdminRoleData(response.data[0]);
        } catch (error) {
          if (error.response) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with loading admin role information!');
          }
        }
      })();
    }
  }, [user, prefix, adminRoleName]);

  const buildUrl = (roles: string, client: Tenant | null, page: number, searchText: string): string => {
    let url = `${prefix}/users?page=${page}&per_page=100&include_totals=true&search_engine=v3&q=user_metadata.is_client_user:${isClientPortal ? true : userManagementType}+AND+user_metadata.organizations:${client?.name}`;
    url += searchText ? `+AND+email:*${searchText}*` : '';
    const rolesValue = roles.split(',').filter((groupId: string) => groups.map((group: TenantGroup) => group.group_id).includes(+groupId));
    url += rolesValue.length ? `+AND+${rolesValue.map((id: string) => `app_metadata.group_ids:${id}`).join('+AND+')}` : '';
    return url;
  }

  const handleGroups = (roles: string, client: Tenant | null) => {
    if (roles && client) {
      roles.split(',').forEach((id: string) => {
        const activeGroup = client.groups.find((group: TenantGroup) => group.group_id === +id);
        if (activeGroup) {
          activeGroup.is_active = true;
        }
      });
      setGroups([...client.groups]);
    } else if (client) {
      setGroups(client.groups.map((group: TenantGroup) => ({ ...group, is_active: false })));
    }
  }

  const handleGetUsers = async (page: number, pageSize: number, roles: string, activeTenant: Tenant | null, searchText: string) => {
    const divider = 100 / pageSize;
    const modifiedPageNumber = Math.floor(page / divider);
    setIsLoading(true);
    handleGroups(roles, activeTenant);
    const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
    try {
      const url = buildUrl(roles, activeTenant, modifiedPageNumber, searchText);
      const response: AxiosResponse<{ length: number, limit: number, start: number, total: number, users: Auth0User[] }> = await axios.get(url, options);
      const result = response.data.users.filter((user: TableUser | Auth0User) => members.find((member: Member) => member.user_id === user.user_id));
      try {
        const sliceNum = response.data.total < 100 ? page : (page - modifiedPageNumber * divider);
        const users = await Promise.all(result.slice(sliceNum * pageSize, (sliceNum + 1) * pageSize).map(async (member: TableUser | Auth0User) =>  {
          const memberRoles: AxiosResponse<Role[]> = await axios.get(`${prefix}/organizations/${pageOrganizationId}/members/${member.user_id}/roles`, options);
          member.is_admin = !!memberRoles.data.find((role: Role) => role.name === adminRoleName);
          return member;
        }));
        setUserData(users);
        setNumberOfUsers(response.data.total);
      } catch (error) {
        if (error.response) {
          toast.warning(error.response.data.message);
        } else {
          toast.warning('Something went wrong with loading users roles!');
        }
        setNumberOfUsers(0);
        setUserData([]);
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with loading users!');
      }
      setNumberOfUsers(0);
      setUserData([]);
    } finally {
      setIsLoading(false);
    }
  }

  const deleteUserFromLooker = async (data: TableUser) => {
    try {
      const lookerOptions = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json', Authorization: token } };
      const lookerResponse: AxiosResponse<LookerEmbedUser> = await axios.get(`${BASE_URL}/users/credential/embed/${data.user_id}`, lookerOptions);
      if (lookerResponse.data.id) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const deleteUserResponse: AxiosResponse<null> = await axios.delete(`${BASE_URL}/users/${lookerResponse.data.id}`, lookerOptions);
        } catch (error) {
          toast.warning('Something went wrong with deleting user in Looker!');
        }
      }
    } catch (error) { }
  }

  const handleCloseModal = () => {
    setRemoveModal(false);
    setMemberToRemove(null);
    setIsUserAdmin(null);
  }

  const handleAssignAdminRole = async (data: TableUser) => {
    setAssignModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const body = { roles: [adminRoleData?.id] };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const assignRole: AxiosResponse<null> = await axios.post(`${prefix}/organizations/${pageOrganizationId}/members/${data.user_id}/roles`, body, options);
      toast.success(`Successfully assigned admin role to ${data.email}${isSevenstepOrganization ? ` in ${activeOrganization?.tenant} organization` : ''}!`);
      handleGetUsers(pageNumber, usersPerPage, rolesParams, activeOrganization, searchValue);
    } catch (error) {
      toast.warning(`Couldn't assign admin role to ${data.email}!`);
    } finally {
      setLoading(false);
    }
  }

  const handleRemoveAdminRole = async (data: TableUser) => {
    setAssignModal(false);
    if (user && data.user_id === user.sub) {
      toast.warning('Can\'t remove admin role from yourself!')
    } else {
      try {
        setLoading(true);
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const removeRole: AxiosResponse<null> = await axios.delete(`${prefix}/organizations/${pageOrganizationId}/members/${data.user_id}/roles`, {
          ...options,
          data: { roles: [adminRoleData?.id] },
        });
        if (data.is_admin && !data.user_metadata.is_client_user && activeOrganization) {
          const client = clientPortalAccess(data.app_metadata.group_ids, activeOrganization);
          if (client) {
            await handleRemovePortalAccess(client, data);
          }
        }
        toast.success(`Successfully removed admin role from ${data.email}${isSevenstepOrganization ? ` in ${activeOrganization?.tenant} organization` : ''}!`);
        handleGetUsers(pageNumber, usersPerPage, rolesParams, activeOrganization, searchValue);
      } catch (error) {
        toast.warning(`Couldn't remove admin role from ${data.email}!`);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleRemovePortalAccess = async (client: Tenant, account: TableUser) => {
    try {
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const updateData = {
        app_metadata: {
          group_ids: account.app_metadata.group_ids
            .filter((id: number) => !client.groups
              .filter((group: TenantGroup) => !group.label.toLowerCase().includes('explorer'))
              .map((group: TenantGroup) => group.group_id)
              .includes(id))
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${account.user_id}`, updateData,options);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Something went wrong with removing client portal access from ${account.email}!`);
      }
    }
  }

  const handleRemoveGroups = async (data: TableUser, subscribe: boolean) => {
    try {
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const body = {
        app_metadata: {
          group_ids: data.app_metadata.group_ids.filter((groupId: number) => !activeOrganization?.groups.map((group: TenantGroup) => group.group_id).includes(groupId))
        },
        user_metadata: {
          organizations: data.user_metadata.organizations.filter((orgName: string) => orgName !== activeOrganization?.name),
          subscribed: subscribe ? false : typeof data.user_metadata.subscribed !== 'undefined' ? data.user_metadata.subscribed : false,
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Auth0User> = await axios.patch(`${prefix}/users/${data.user_id}`, body, options);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with removing user roles!');
      }
    }
  }

  const exportTableData = async (columns: Column<TableUser>[]) => {
    const url = (page: number): string => `${prefix}/users?page=${page}&per_page=100&include_totals=true&search_engine=v3&q=user_metadata.is_client_user:${userManagementType}+AND+user_metadata.organizations:${activeOrganization?.name}`;
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const response: AxiosResponse<{ limit: number, total: number, users: Auth0User[], start: number }> = await axios.get(url(0), options);
      const users: Auth0User[] = [...response.data.users];
      const pagination = Math.floor((response.data.total - 1) / response.data.limit);
      if (pagination > 0) {
        const restUsers = Array.from({ length: pagination }, (_, i) => i + 1);
        let usersResponse: Array<AxiosResponse<{ limit: number, total: number, users: Auth0User[], start: number }>> = await Promise.all(restUsers.map(async (page: number) => await axios.get(url(page), options)));
        usersResponse.forEach((res: AxiosResponse<{ limit: number, users: Auth0User[], total: number, start: number }>) => {
          users.push(...res.data.users);
        });
      }
      const results = users.filter((user: TableUser | Auth0User) => members.find((member: Member) => member.user_id === user.user_id));
      const dataRows = results.map((user: TableUser | Auth0User) => isClientUser === false && isSevenstepOrganization ? [
        user.email,
        `${user.user_metadata.first_name} ${user.user_metadata.last_name}`,
        user.last_login ? format(parseISO(user.last_login), 'MM/dd/yyyy') : 'Never',
        activeOrganization && activeOrganization.groups.filter((group: TenantGroup) => user.app_metadata.group_ids.includes(group.group_id)).map((group: TenantGroup) => group.label).join('; '),
        user.user_metadata.organizations.join('; '),
      ] : [
        user.email,
        `${user.user_metadata.first_name} ${user.user_metadata.last_name}`,
        user.last_login ? format(parseISO(user.last_login), 'MM/dd/yyyy') : 'Never',
        activeOrganization && activeOrganization.groups.filter((group: TenantGroup) => user.app_metadata.group_ids.includes(group.group_id)).map((group: TenantGroup) => group.label).join('; '),
      ]);
      const { exportDelimiter } = tableRef.current.props.options;
      const delimiter = exportDelimiter ? exportDelimiter : ',';
      const headerRow = columns.filter((col: Column<TableUser>) => !col.hidden && !col.field?.includes('admin')).map((col: Column<TableUser>) => col.title);
      const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join('\n');
      downloadCsv(csvContent, `${activeOrganization?.tenant}_${!isClientUser ? userManagementType ? 'client_' : 'internal_' : ''}users`);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with downloading user data!');
      }
    } finally {
      setLoading(false);
    }
  }

  const downloadCsv = (data: any, fileName: string) => {
    const finalFileName = fileName.endsWith('.csv') ? fileName : `${fileName}.csv`;
    const downloadElement = document.createElement('a');
    downloadElement.href = URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
    downloadElement.setAttribute('download', finalFileName);
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
  }

  const handleRoleToggle = (index: number) => {
    groups[index].is_active = !groups[index].is_active;
    setGroups([...groups]);
  }

  const handleRemoveMember = async (data: TableUser, reload: boolean | undefined) => {
    setRemoveOrganizationModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      if (!data.user_metadata.is_client_user) {
        const userOrganizationsResponse: AxiosResponse<Organization[]> = await axios.get(`${prefix}/users/${data.user_id}/organizations`, options);
        if (userOrganizationsResponse.data.length === 2 && userOrganizationsResponse.data.find((org: Organization) => org.name === 'sevenstep')) {
          await deleteSevenstepUser(data, reload);
        } else {
          const body = {
            members: [ data.user_id ]
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const response: AxiosResponse<null> = await axios.delete(`${prefix}/organizations/${pageOrganizationId}/members`, {
            ...options,
            data: body,
          });
          toast.success(`Successfully removed ${data.email} from ${!isClientUser && activeOrganization?.tenant} organization!`);
          const unsubscribeUser = await handleUnsubscribeDeletedUser(data);
          await handleRemoveGroups(data, unsubscribeUser);
        }
      }
      if (reload) {
        if (pageNumber === 0) {
          handleGetUsers(0, usersPerPage, rolesParams, activeOrganization, searchValue);
        } else {
          const roles = groups.filter((group: TenantGroup) => group.is_active).map((group: TenantGroup) => group.group_id).join(',');
          history.push(`/users?page=1&page-size=${usersPerPage}${roles.length ? `&roles=${roles}` : ''}${orgName ? `&org=${orgName}` : ''}${isSevenstepOrganization ? `&client-users=${userManagementType}` : ''}`);
        }
      }
    } catch (error) {
      toast.warning(`Couldn't remove ${data.email} from ${!isClientUser && activeOrganization?.tenant} organization!`);
    } finally {
      setLoading(false);
      setMemberToRemove(null);
      setIsUserAdmin(null);
    }
  }

  const deleteSevenstepUser = async (account: TableUser, reload: boolean | undefined) => {
    setRemoveModal(false);
    try {
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<null> = await axios.delete(`${prefix}/users/${account.user_id}`, options);
      await deleteUserFromLooker(account);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const unsubscribe = await handleUnsubscribeDeletedUser(account);
      if (reload) {
        toast.success(`Successfully deleted ${account.email} from all organizations!`);
        if (pageNumber === 0) {
          handleGetUsers(0, usersPerPage, rolesParams, activeOrganization, searchValue);
        } else {
          const roles = groups.filter((group: TenantGroup) => group.is_active).map((group: TenantGroup) => group.group_id).join(',');
          history.push(`/users?page=1&page-size=${usersPerPage}${roles.length ? `&roles=${roles}` : ''}${orgName ? `&org=${orgName}` : ''}${isSevenstepOrganization ? `&client-users=${userManagementType}` : ''}`);
        }
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with deleting user!');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleGetSubscriber = async (auth0User: TableUser): Promise<Subscriber | null> => {
    try {
      const options = { headers: { 'content-type': 'application/json' } };
      const response: AxiosResponse<Subscriber[]> = await axios.get(`${statuspageUrl}/pages/${statuspageId}/subscribers?q=${auth0User.email}&state=active`, options);
      const subscribedUser = response.data.find((account: Subscriber) => account.email === auth0User.email);
      return subscribedUser ? subscribedUser : null;
    } catch (error) {
      return null;
    }
  }

  const handleUnsubscribeDeletedUser = async (account: TableUser): Promise<boolean> => {
    try {
      const options = { headers: { 'content-type': 'application/json' } };
      const subscriber = await handleGetSubscriber(account);
      if (subscriber) {
        const body = {
          subscribers: [ subscriber.id ]
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response: AxiosResponse<Subscriber> = await axios.post(`${statuspageUrl}/pages/${statuspageId}/subscribers/unsubscribe`, body, options);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.error[0]);
      } else {
        toast.warning(`Something went wrong with unsubscribing user from notifications!`);
      }
      return false;
    }
  }

  const deleteMultipleUsers = async (users: TableUser[]) => {
    setDeleteMultipleModal(false);
    for (let i = 0; i < users.length; i++) {
      if (isSevenstepOrganization && activeOrganization?.name === 'sevenstep') {
        await deleteSevenstepUser(users[i], undefined);
      } else {
        await handleRemoveMember(users[i], undefined);
      }
    }
    toast.success(`Successfully deleted users from ${isSevenstepOrganization && activeOrganization?.name === 'sevenstep' ? 'all organizations' : `${activeOrganization?.tenant} organization`}!`);
    if (pageNumber === 0) {
      handleGetUsers(0, usersPerPage, rolesParams, activeOrganization, searchValue);
    } else {
      history.push(`/users?page=1&page-size=${usersPerPage}${rolesParams.length ? `&roles=${rolesParams}` : ''}${orgName ? `&org=${orgName}` : ''}${isSevenstepOrganization ? `&client-users=${userManagementType}` : ''}`);
    }
  }

  const isUserInActiveOrganization = (users: Auth0User[]): boolean => {
    const matchingUsers = [];
    users.forEach((user: Auth0User) => {
      const userInTable = userData.find((account: TableUser) => account.user_id === user.user_id);
      if (userInTable) {
        matchingUsers.push(userInTable);
      }
    });
    return !!matchingUsers.length;
  }

  const reloadTable = (users: Auth0User[]) => {
    if (activeOrganization?.organization_id === invitationOrganization?.organization_id || isUserInActiveOrganization(users)) {
      if (pageNumber === 0) {
        handleGetUsers(0, usersPerPage, rolesParams, activeOrganization, searchValue);
      } else {
        history.push(`/users?page=1&page-size=${usersPerPage}${rolesParams.length ? `&roles=${rolesParams}` : ''}${orgName ? `&org=${orgName}` : ''}${isSevenstepOrganization ? `&client-users=${userManagementType}` : ''}`);
      }
    }
  }

  const handleUsersTypeChanged = () => {
    setUserManagementType(!userManagementType);
    history.push(`/users?page=1&page-size=${usersPerPage}${rolesParams.length ? `&roles=${rolesParams}` : ''}${orgName ? `&org=${orgName}` : ''}${`&client-users=${!userManagementType}`}`);
  }

  const handleSearchUsers = (value: string) => {
    setSearchValue(value);
    if (value.length < 3 && value.length !== 0) {
      toast.info('Search value must be at least 3 characters long!');
    } else {
      handleGetUsers(pageNumber, usersPerPage, rolesParams, activeOrganization, value);
    }
  }

  const debounced = useDebouncedCallback(handleSearchUsers, 1000)

  const columns: Column<TableUser>[] = [
    {
      title: 'Email',
      field: 'email',
      render: (rowData: TableUser) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Avatar
            component='span'
            style={{
              width: '40px',
              height: '40px',
              fontSize: '16px',
              display: 'inline-flex',
              background: rowData.user_metadata.color ? rowData.user_metadata.color : ''
            }}
          >
            {getInitials && getInitials(rowData.email, rowData.user_metadata.first_name, rowData.user_metadata.last_name)}
          </Avatar>
          <span> {rowData.email} </span>
        </div>
      ),
      searchable: true,
      customFilterAndSearch: (filter: any, rowData: TableUser, column: Column<TableUser>) => true,
    },
    {
      title: 'Name',
      field: 'user_metadata.first_name',
      render: (rowData: TableUser) => (
        <span>
          {rowData.user_metadata.first_name} {rowData.user_metadata.last_name}
          {!!rowData.user_metadata.subscribed ? (
            <Tooltip title='Subscribed to notifications' placement='right'>
              <NotificationsActiveIcon fontSize='small' style={{ marginLeft: '3px' }} />
            </Tooltip>
          ) : null}
        </span>
      ),
      searchable: false,
    },
    {
      title: 'Latest login',
      field: 'user_metadata.latest_login',
      render: (rowData: TableUser) => (
        <span>
          {rowData.user_metadata.latest_login ? (
            format(parseISO(rowData.user_metadata.latest_login), 'MM/dd/yyyy')
          ) : rowData.last_login ? format(parseISO(rowData.last_login), 'MM/dd/yyyy') : 'Never'}
        </span>),
      sorting: false,
    },
    {
      title: 'Roles',
      field: 'app_metadata.group_ids',
      render: (rowData: TableUser) => (
        <span>
          {activeOrganization && activeOrganization.groups
            .filter((group: TenantGroup) => rowData.app_metadata.group_ids.includes(group.group_id))
            .map((group: TenantGroup) => group.label).join(', ')}
        </span>
      ),
      sorting: false,
      cellStyle: {
        minWidth: '200px'
      }
    },
    {
      title: 'Organizations',
      field: 'user_metadata.organizations',
      render: (rowData: TableUser) => <span>{rowData.user_metadata.organizations.sort().join(', ')}</span>,
      hidden: !isClientUser && isSevenstepOrganization ? false : true,
      cellStyle: {
        minWidth: '200px'
      }
    },
    {
      title: 'User Admin',
      field: 'is_admin',
      render: (rowData: TableUser) => (
        <span>{rowData.is_admin ? <CheckCircleIcon style={{ color: '#74c8b0' }} /> : null}</span>
      ),
      sorting: false,
    },
  ];

  return (
    <Box style={{ height: '100%' }}>
      <MaterialTable
        tableRef={tableRef}
        title={
          <div className='table-header-container'>
            <h6 className='table-header'>Manage Users{isSevenstepOrganization ? ' In' : ''}</h6>
            {isSevenstepOrganization && (
              <div>
                <OrganizationSelect
                  activeOrganization={activeOrganization}
                  setActiveOrganization={setActiveOrganization}
                  showLabel={false}
                  adminUserTenants={adminUserTenants}
                  userType={userManagementType}
                  setType={setUserManagementType}
                  setPageOrganizationId={setPageOrganizationId}
                  setMembers={setMembers}
                  changeParam={true}
                  pageSize={usersPerPage}
                />
              </div>
            )}
          </div>
        }
        columns={columns}
        data={userData}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit user',
            onClick: (event: React.MouseEvent, rowData: any) => {
              history.push(`/users/${rowData.user_id}`);
            },
            position: 'row',
          },
          {
            icon: () => <AssignmentIcon />,
            tooltip: 'Assign admin role',
            onClick: (event: React.MouseEvent, rowData: any) => {
              setIsUserAdmin(rowData.is_admin);
              setUserToAssignRole(rowData);
              setAssignModal(true);
            },
            position: 'row',
            hidden: true,
          },
          {
            icon: () => <AssignmentIcon />,
            tooltip: 'Remove admin role',
            onClick: (event: React.MouseEvent, rowData: any) => {
              setIsUserAdmin(rowData.is_admin);
              setUserToAssignRole(rowData);
              setAssignModal(true);
            },
            position: 'row',
            hidden: true,
          },
          {
            icon: () => <RemoveCircleOutlineIcon />,
            tooltip: 'Remove user from organization',
            onClick: (event: React.MouseEvent, rowData: any) => {
              setIsUserAdmin(rowData.is_admin);
              setUserToRemove(rowData);
              setRemoveOrganizationModal(true);
            },
            position: 'row',
            hidden: !!isClientUser,
          },
          {
            icon: 'delete',
            tooltip: 'Delete user',
            onClick: (event: React.MouseEvent, rowData: any) => {
              setIsUserAdmin(rowData.is_admin);
              setMemberToRemove(rowData.is_admin ? null : rowData);
              rowData.is_admin ? setAdminModal(true) : setRemoveModal(true);
            },
            position: 'row',
          },
          {
            icon: 'delete',
            tooltip: 'Delete selected users',
            onClick: (event: React.MouseEvent, rowData: any) => {
              const adminsSelected = rowData.filter((user: TableUser) => user.is_admin);
              if (adminsSelected.length > 0) {
                setUsersToDelete(null);
                setMultipleAdminModal(true);
              } else {
                setUsersToDelete(rowData);
                setDeleteMultipleModal(true);
              }
            },
            position: 'toolbarOnSelect',
          },
          {
            icon: () => <PersonAddIcon />,
            tooltip: 'Add existing user',
            onClick: (event: React.MouseEvent, rowData: any) => setAddExistingUserModal(true),
            hidden: !isSevenstepOrganization,
            position: 'toolbar',
          },
          {
            icon: () => <EqualizerIcon />,
            tooltip: 'Users Stats',
            onClick: (event: React.MouseEvent, rowData: any) => setUsersStatsModal(true),
            hidden: !isSevenstepOrganization,
            position: 'toolbar',
          },
        ]}
        isLoading={isLoading}
        options={{
          debounceInterval: 1000,
          pageSize: usersPerPage,
          headerStyle: {
            position: 'sticky',
            top: 0,
            height: 60,
          },
          selection: true,
          maxBodyHeight: height - (107 + toolbarHeight),
          minBodyHeight: height - (107 + toolbarHeight),
          emptyRowsWhenPaging: false,
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportAllData: true,
          exportCsv: (columns: Column<TableUser>[], data: TableUser[]) => exportTableData(columns),
        }}
        components={{
          Toolbar: (props: any) => (
            <div ref={toolbarRef} style={{ width: '100%' }}>
              <MTableToolbar
                {...props}
                classes={{ highlight: classes.highlight }}
                searchText={searchValue}
                onSearchChanged={(value: string) => debounced(value)}
                localization={{
                  searchPlaceholder: 'Search by Email'
                }}
              />
              <Box className='toolbar-wrapper'>
                {!isClientUser && !isClientPortal ? (
                  <Box>
                    <Tooltip
                      title={disabledToogle ? (
                        `${activeOrganization && activeOrganization.tenant} organization doesn't have ${!userManagementType ? 'client' : 'internal'} users!`
                      ) : `Change to ${userManagementType ? 'internal' : 'client'} users`}
                      placement='bottom'
                    >
                      <Box>
                        <GreenSwitch
                          checked={userManagementType}
                          onClick={() => handleUsersTypeChanged()}
                          disabled={disabledToogle}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                ) : null}
                <Box style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                  {groups.map((group: TenantGroup, index: number) => (
                    <Tooltip title={group.description} placement='bottom-start' key={index}>
                      <Chip
                        variant='outlined'
                        className={group.is_active ? 'active-chip' : ''}
                        icon={<GroupIcon />}
                        label={group.label}
                        style={{ cursor: 'pointer', margin: '5px' }}
                        color={group.is_active ? 'primary' : 'default'}
                        clickable={true}
                        onClick={() => {
                          handleRoleToggle(index);
                          const roles = groups.filter((group: TenantGroup) => group.is_active).map((group: TenantGroup) => group.group_id).join(',');
                          history.push(`/users?page=1&page-size=${usersPerPage}${roles.length ? `&roles=${roles}` : ''}${orgName ? `&org=${orgName}` : ''}${!isClientUser && !isClientPortal ? `&client-users=${userManagementType}` : ''}`);
                        }}
                      />
                    </Tooltip>
                  ))}
                </Box>
              </Box>
            </div>
          ),
          Pagination: (props: any) => (
            <TablePagination
              {...props}
              page={pageNumber}
              rowsPerPage={usersPerPage}
              rowsPerPageOptions={[10,20]}
              count={numberOfUsers}
              onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
                history.push(`/users?page=${page + 1}&page-size=${usersPerPage}${rolesParams.length ? `&roles=${rolesParams}` : ''}${orgName ? `&org=${orgName}` : ''}${!isClientUser && !isClientPortal ? `&client-users=${userManagementType}` : ''}`)
              }}
              onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                history.push(`/users?page=1&page-size=${event.target.value}${rolesParams.length ? `&roles=${rolesParams}` : ''}${orgName ? `&org=${orgName}` : ''}${!isClientUser && !isClientPortal ? `&client-users=${userManagementType}` : ''}`)
              }}
            />
          ),
          Row: (props: any) => {
            const propsCopy = { ...props };
            propsCopy.actions.find((a: Action<TableUser>) => a.tooltip?.toLowerCase().includes('remove admin')).hidden = !propsCopy.data.is_admin;
            propsCopy.actions.find((a: Action<TableUser>) => a.tooltip?.toLowerCase().includes('assign admin')).hidden = propsCopy.data.is_admin;
            return <MTableBodyRow {...propsCopy} />
          },
          OverlayLoading: (props: any) => <Loading {...props} transparentBackground={true} />,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No users to display!'
          },
          toolbar: {
            nRowsSelected: `{0} user(s) selected`
          }
        }}
      />
      {loading ? <Loading transparentBackground={true} /> : null}
      <ModalComponent 
        open={removeModal}
        setOpen={setRemoveModal}
        heading={'Delete user'}
        labelText={
          `Are you really sure you want to delete ${memberToRemove?.email}${isSevenstepOrganization ? ` from ${activeOrganization?.name === 'sevenstep' ? 'all organizations' : `${activeOrganization?.tenant} organization`}` : ''}? 
          This cannot be undone!`}
        buttonText={`Yes, delete it`}
        data={memberToRemove}
        handleAccept={deleteSevenstepUser}
        handleCancel={handleCloseModal}
        reload={true}
      />
      {!isUserAdmin ? (
        <ModalComponent
          open={removeOrganizationModal}
          setOpen={setRemoveOrganizationModal}
          heading='Remove user'
          buttonText='Yes, remove it'
          labelText={`Are you really sure you want to remove ${userToRemove?.email} from ${activeOrganization?.tenant}? This cannot be undone!`}
          handleAccept={handleRemoveMember}
          data={userToRemove}
          reload={true}
        />
      ) : (
        <ModalComponent
          open={removeOrganizationModal}
          setOpen={setRemoveOrganizationModal}
          heading='Remove organization'
          labelText={'You can\'t remove admin user from organization!'}
        />
      )}
      <ModalComponent 
        open={adminModal}
        setOpen={setAdminModal}
        heading={'Delete user'}
        labelText={'You can\'t delete another admin!'}
      />
      <ModalComponent
        open={assignModal}
        setOpen={setAssignModal}
        heading={isUserAdmin ? 'Remove admin role' : 'Assign admin role'}
        labelText={`
          Are you really sure you want to ${isUserAdmin ? 'remove ' : 'assign '}
          admin role ${isUserAdmin ? 'from' : 'to'} ${userToAssignRole?.email}${isSevenstepOrganization ? ` in ${activeOrganization?.tenant} organization` : ''}?
        `}
        buttonText={`Yes, ${isUserAdmin ? 'remove' : 'assign'} it`}
        data={userToAssignRole}
        handleAccept={isUserAdmin ? handleRemoveAdminRole : handleAssignAdminRole}
      />
      <ModalComponent
        open={deleteMultipleModal}
        setOpen={setDeleteMultipleModal}
        heading={'Delete users'}
        labelText={`
          Are you really sure you want to delete 
          ${usersToDelete && usersToDelete.length <= 3 ? usersToDelete.map((user: TableUser) => user.email).join(', ') : 'these users'}?
          This cannot be undone!
        `}
        buttonText={`Yes, delete users`}
        handleAccept={deleteMultipleUsers}
        data={usersToDelete}
      />
      <ModalComponent 
        open={multipleAdminModal}
        setOpen={setMultipleAdminModal}
        heading={'Delete users'}
        labelText={`You can't delete users because there is an admin selected. Please remove them and then proceed!`}
      />
      <Modal
        open={addExistingUserModal}
        onClose={() => setAddExistingUserModal(false)}
      >
        <Box className={classes.modal}>
          <InvitationWidget
            isSevenstepOrganization={isSevenstepOrganization}
            invitationOrganization={invitationOrganization}
            setInvitationOrganization={setInvitationOrganization}
            adminUserTenants={adminUserTenants}
            setLoading={setLoading}
            setCloseModal={setAddExistingUserModal}
            reloadTable={reloadTable}
            cancelButton={true}
            userType={userType}
            setUserType={setUserType}
            prefix={prefix}
          />
        </Box>
      </Modal>
      {usersStatsModal ? (
        <UsersStats
          open={usersStatsModal}
          setOpen={setUsersStatsModal}
          isSevenstepOrganization={isSevenstepOrganization}
          usersNumberInOrg={numberOfUsers}
          activeOrganization={activeOrganization}
          usersType={userManagementType}
          prefix={prefix}
        />
      ) : null}
    </Box>
  )
};

export default UserManagement;
