import React from 'react';
import { ListItem, ListItemText, Collapse, Box } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Incident, Component, IncidentUpdates } from '../types/Statuspage.interfaces';
import { format, parseISO } from 'date-fns';

interface Props {
  incident: Incident;
  toggle: (incident: Incident) => void;
  getIcon: (status: string) => React.ReactNode;
}

const NotificationItem: React.FC<Props> = ({ incident, toggle, getIcon }) => {
  const returnDate = (incident: Incident) => {
    let value;
    switch (incident.status) {
      case 'scheduled':
        value = incident.scheduled_for ? format(parseISO(incident.scheduled_for), 'MM/dd/yyyy hh:mm a') : '';
        break;
      case 'completed':
      case 'resolved':
        value = incident.resolved_at ? format(parseISO(incident.resolved_at), 'MM/dd/yyyy hh:mm a') : '';
        break;
      case 'investigating':
      case 'identified':
      case 'monitoring':
        value = incident.updated_at ? format(parseISO(incident.updated_at), 'MM/dd/yyyy hh:mm a') : '';
        break;
      default:
        value = '';
    }
    return value;
  }

  return (
    <>
      <ListItem className='notification-item' disableGutters onClick={() => toggle(incident)}>
        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box>
            <ListItemText primary={incident.name} />
            <Box>
              <Box component='span' className='notifications-tag'>{incident.status.toUpperCase().replace(/_/g, ' ')}</Box>
              <Box component='span' style={{ padding: '0 5px' }}>{returnDate(incident)}</Box>
            </Box>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {incident.open ? (
              <ExpandLess style={{ fontSize: 24, marginRight: '3px', cursor: 'pointer' }} />
            ) : (
              <ChevronRightIcon style={{ fontSize: 24, marginRight: '3px', cursor: 'pointer' }} />
            )}
          </Box>
        </Box>
      </ListItem>
      <Collapse in={incident.open} timeout='auto' unmountOnExit>
        <Box style={{ padding: '20px 0' }}>
          <Box component='p' style={{ marginBottom: '0' }}>Affected portal components:</Box>
          <Box className='notification-component-wrapper'>
            {incident.components.length ? incident.components.map((component: Component) => (
              <Box className='notification-component'>
                {getIcon(component.status)}
                <Box component='span'>{component.name}</Box>
              </Box>
            )) : (
              <Box>None!</Box>
            )}
          </Box>
          <Box component='p' style={{ marginBottom: '0', marginTop: '10px' }}>Notification updates:</Box>
          <Box className='notification-component-wrapper'>
            {incident.incident_updates.length ? incident.incident_updates.map((incidentUpdate: IncidentUpdates) => (
              <Box className='notification-incident-update'>
                <Box>
                  <Box component='span' className='notifications-tag'>{incidentUpdate.status.toUpperCase().replace(/_/g, ' ')}</Box>
                </Box>
                <Box>
                  <Box component='span' style={{ padding: '5px 0', fontSize: '12px' }}>{format(parseISO(incidentUpdate.display_at), 'MM/dd/yyyy hh:mm a')}</Box>
                </Box>
                <Box>{incidentUpdate.body}</Box>
              </Box>
            )) : (
              <Box>No maintenance updates!</Box>
            )}
          </Box>
        </Box>
      </Collapse>
    </>
  )
}

export default NotificationItem;
