import { EmbedBuilder } from './embed_builder'
import { Chatty, ChattyHost, ChattyHostBuilder } from '@looker/chatty';
import { LookerEmbedUser } from './types';



const IS_URL = /^https?:\/\//

/**
 * Wrapper for Looker embedded content. Provides a mechanism for creating the embedded content element,
 * and for establishing two-way communication between the parent window and the embedded content.
 */

export class EmbedClient<T> {
  _hostBuilder: ChattyHostBuilder | null = null
  _host: ChattyHost | null = null
  _connection: Promise<T> | null = null
  _client: T | null = null

  /**
   * @hidden
   */

  constructor(private _builder: EmbedBuilder<T>) { }

  /**
   * Returns a promise that resolves to a client that can be used to send messages to the
   * embedded content.
   */

  get connection() {
    return this._connection
  }

  /**
   * Indicates whether two way communication has successfully been established with the embedded content.
   */

  get isConnected() {
    return !!this._connection
  }

  get targetOrigin() {
    if (this._builder.sandboxedHost) {
      return '*'
    }
    const apiHost = this._builder.apiHost
    return IS_URL.test(apiHost) ? apiHost : `https://${apiHost}`
  }

  private async createIframe(url: string, parent?: string) {
    this._hostBuilder = Chatty.createHost(url)
    for (const eventType in this._builder.handlers) {
      for (const handler of this._builder.handlers[eventType]) {
        this._hostBuilder.on(eventType, (...args) => handler.apply(this._client, args))
      }
    }
    for (const attr of this._builder.sandboxAttrs) {
      this._hostBuilder.withSandboxAttribute(attr)
    }
    let anchor;
    if (parent) {
      anchor = document.querySelector(parent);
    }
    this._host = this._hostBuilder
      // tslint:disable-next-line:deprecation
      .appendTo(anchor ? anchor as any : document.body)
      .frameBorder(this._builder.frameBorder)
      .withTargetOrigin(this.targetOrigin)
      .build()
    // IE doesn't like calling classList.add() with no arguments, so check
    if (this._builder.classNames.length) {
      this._host.iframe.classList.add(...this._builder.classNames)
    }
    return this._host.connect()
      .then((host) => {
        this._client = new this._builder.clientConstructor(host)
        return this._client
      })
  }
  private async createUrl(user: LookerEmbedUser) {
    const src = this._builder.embedUrl;
    if (!this._builder.authUrl) return `${this._builder.apiHost}${src}`;
    const url = `${this._builder.authUrl}?src=${encodeURIComponent(src)}`;
    return new Promise<string>(async (resolve, reject) => {
      // compute signature
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url)
      xhr.setRequestHeader('Cache-Control', 'no-cache');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('user', JSON.stringify(user));
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.responseText);
        } else {
          reject(xhr.statusText);
        }
      }
      xhr.onerror = () => reject(xhr.statusText);
      xhr.send();
    })
  }

  /**
   * Establish two way communication with embedded content. Returns a promise that resolves to a
   * client that can be used to send messages to the embedded content.
   */
  async connect(user: LookerEmbedUser, parent?: string) {
    if (this._connection) {
      return { connection: this._connection, url: this._builder.url }
    }
    if (this._builder.url) {
      this._connection = this.createIframe(this._builder.url, parent);
      return { connection: this._connection, url: this._builder.url };
    } else {
      const url = await this.createUrl(user);
      this._connection = this.createIframe(url, parent);
      return { connection: this._connection, url: url };
    }
  }
}