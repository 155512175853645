import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const GreenSwitch = withStyles({
  switchBase: {
    color: '#74c8b0',
    '&$checked': {
      color: '#23949d',
    },
    '&$checked + $track': {
      backgroundColor: '#23949d',
    },
  },
  checked: {},
  track: {},
})(Switch);