import React, { useState, useEffect } from 'react';
import useWindowSize from '../utils/useWindowSize';
import SearchBar from './SearchBar';
import DashboardMenuItem from './DashboardMenuItem';
import ExploreMenuItem from './ExploreMenuItem';
import { Tooltip, List, Box, ListItem, ListItemText, makeStyles, Backdrop } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@yisheng90/react-loading';
import SearchIcon from '@material-ui/icons/Search';
import { useAuth0 } from '../react-auth0-spa';
import { Transition } from 'react-transition-group';
import { FolderStructure, Dashboard, Explore, Look } from '../types/Looker.interfaces';
import { Tenant } from '../types/Tenant.interfaces';
import { TransitionStatus } from 'react-transition-group/Transition';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    maxHeight: 'calc(100vh - 90px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media (max-width: 767px)': {
      maxHeight: 'calc(100vh - 100px)',
    }
  },
}));

interface Props {
  menuWidth: number;
  menuLoading: boolean;
  menuStructure: FolderStructure<Dashboard | Look>[];
  explores: FolderStructure<Explore>[];
  toggleMenu: () => void;
  toggleItem: (item: FolderStructure<Dashboard | Look>) => void;
  toggleExplore: (item: FolderStructure<Explore>) => void;
  showMenuItems: boolean;
  openFolder: (item: Dashboard | Look) => void;
  addDashboardToFavorites: (dashboard: Dashboard | Look, inSubfolder: boolean) => void;
  deleteDashboardFromFavorites: (dashboard: Dashboard | Look) => void;
  setSearchBarFocus: (value: boolean) => void;
  searchBarFocus: boolean;
  activeTenant: Tenant | null;
  toggleSubfolder: (folderId: string, parentFolderId: string) => void;
  openExploreFolder: (folderName: string) => void;
  handleCloseMenu: (event: React.MouseEvent<any>) => void;
}

const MobileMenu: React.FC<Props> = ({
  menuWidth,
  menuLoading,
  menuStructure,
  toggleMenu,
  toggleItem,
  toggleExplore,
  showMenuItems,
  explores,
  openFolder,
  addDashboardToFavorites,
  deleteDashboardFromFavorites,
  setSearchBarFocus,
  searchBarFocus,
  activeTenant,
  toggleSubfolder,
  openExploreFolder,
  handleCloseMenu,
}) => {
  const { width, height } = useWindowSize();
  const { user } = useAuth0();
  const openMenuSize = 240;
  const classes = useStyles();
  const [searchBarContent, setSearchBarContent]  = useState<(Dashboard | Explore | Look)[]>([]);

  useEffect(() => {
    if (menuStructure.length !== 0) {
      const dash: (Dashboard | Explore | Look)[] = [];
      menuStructure
        .filter((menu: FolderStructure<Dashboard | Look>) => menu.name.toLowerCase() !== 'favorites')
        .forEach((menu: FolderStructure<Dashboard | Look>) => {
          menu.dashboards.forEach((dashboard: Dashboard | Look) => {
            dash.push(dashboard);
          })
          if (menu.subfolders && menu.subfolders.length) {
            menu.subfolders.forEach((folder: FolderStructure<Dashboard | Look>) => {
              folder.dashboards.forEach((dashboard: Dashboard | Look) => {
                dash.push(dashboard);
              })
            })
          }
        });
      if (explores.length !== 0) {
        explores.forEach((menu: FolderStructure<Explore>) => {
          menu.dashboards.forEach((explore: Explore) => {
            dash.push(explore);
          });
        });
      }
      setSearchBarContent(dash);
    } else {
      setSearchBarContent([]);
    }
  }, [menuStructure, explores]);

  const duration = 500;

  const sidebarStyle = {
    transition: `width ${duration}ms`
  }

  const sidebarTransitionStyles: { [id: string]: React.CSSProperties } = {
    entering: { width: menuWidth },
    entered: { width: menuWidth },
    exiting: { width: menuWidth },
    exited: { width: menuWidth }
  }

  const navClick = (viewWidth: number) => {
    if (viewWidth < 768) {
      toggleMenu();
    }
  };

  const isMenuOpen = menuWidth === openMenuSize;

  const getFolderMenu = (menuStructure: FolderStructure<Dashboard | Look>[], explores?: FolderStructure<Explore>[]) => {
    return (
      <List
        component='nav'
        aria-labelledby='nested-list'
        className={classes.root}
        dense
        disablePadding
      >
        <Box>
          {isMenuOpen || showMenuItems ? (
            <>
              <ListItem className='side-link' style={{ padding: '4px 0', display: 'flex', justifyContent: 'center' }} disableGutters>
                <SearchBar
                  searchBarContent={searchBarContent}
                  openFolder={openFolder}
                  navClick={navClick}
                  searchBarFocus={searchBarFocus}
                  activeTenant={activeTenant}
                  openExploreFolder={openExploreFolder}
                />
              </ListItem>
            </>
          ) : (
            <>
              <ListItem
                button
                className='side-link'
                onClick={() => {
                  toggleMenu();
                  setSearchBarFocus(true);
                }}
                disableGutters
              >
                <Tooltip placement='right-start' enterDelay={0} title={'Search bar'}>
                  <ListItemText className='sidebar-item-text small'>
                    <IconButton size='small'>
                      <SearchIcon />
                    </IconButton>
                  </ListItemText>
                </Tooltip>
              </ListItem>
            </>
          )}
          {menuStructure.map((item: FolderStructure<Dashboard | Look>) => (
            <DashboardMenuItem
              key={item.id}
              isMenuOpen={isMenuOpen} 
              showMenuItems={showMenuItems}
              toggleItem={toggleItem}
              menuItem={item}
              navClick={navClick}
              addDashboardToFavorites={addDashboardToFavorites}
              deleteDashboardFromFavorites={deleteDashboardFromFavorites}
              width={width}
              toggleSubfolder={toggleSubfolder}
              activeTenant={activeTenant}
            />
          ))}
          {explores && explores.length && explores[0].dashboards.length > 0 ? explores.map((item: FolderStructure<Explore>) => (
            <ExploreMenuItem
              key={item.id}
              isMenuOpen={isMenuOpen} 
              showMenuItems={showMenuItems}
              toggleExplore={toggleExplore}
              item={item}
              navClick={navClick}
              width={width}
            />
          )) : null}
        </Box>
      </List>
    )
  }

  return (
    <>
      {width < 768 ? (
        <Backdrop className='backdrop' open={isMenuOpen} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleCloseMenu(event)}>
          <Transition timeout={duration}>
            {(state: TransitionStatus) => (
              <div
                className='menu-style'
                style={{ ...sidebarStyle, ...sidebarTransitionStyles[state] }}
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
              >
                {menuLoading ? (
                  <div style={{ padding: '5px' }}>
                    <Skeleton rows={Math.floor((height - 150) / 40)} height={40} />
                  </div>
                ) : getFolderMenu(menuStructure, explores)}
                {width >= 768 ? (
                  <List className='collapse-side' disablePadding dense>
                    <Tooltip placement='right-start' enterDelay={0} title={menuWidth !== openMenuSize ? 'Expand Menu' : 'Collapse Menu'}>
                      <ListItem
                        button
                        onClick={() => {
                          toggleMenu();
                          setSearchBarFocus(false);
                        }}
                        disableGutters={menuWidth !== openMenuSize}
                      >
                        <ListItemText className='sidebar-item-text text-center'>
                          {(menuWidth !== openMenuSize) ? (
                            <KeyboardArrowRightIcon />
                          ) : (
                            <KeyboardArrowLeftIcon />
                          )}
                        </ListItemText>
                      </ListItem>
                    </Tooltip>
                  </List>
                ) : (
                  <span className='user-info-mobile' style={!isMenuOpen || showMenuItems ? { whiteSpace: 'nowrap' } : { overflowWrap: 'anywhere' }}>
                    <h6 className='d-inline-block'>{user ? user.email : null}</h6>
                  </span>
                )}
              </div>
            )}
          </Transition>
        </Backdrop>
      ) : (
        <Transition timeout={duration}>
          {(state: TransitionStatus) => (
            <div className='menu-style' style={{ ...sidebarStyle, ...sidebarTransitionStyles[state] }}>
              {menuLoading ? (
                <div style={{ padding: '5px' }}>
                  <Skeleton rows={Math.floor((height - 150) / 40)} height={40} />
                </div>
              ) : getFolderMenu(menuStructure, explores)}
              {width >= 768 ? (
                <List className='collapse-side' disablePadding dense>
                  <Tooltip placement='right-start' enterDelay={0} title={menuWidth !== openMenuSize ? 'Expand Menu' : 'Collapse Menu'}>
                    <ListItem
                      button
                      onClick={() => {
                        toggleMenu();
                        setSearchBarFocus(false);
                      }}
                      disableGutters={menuWidth !== openMenuSize}
                    >
                      <ListItemText className='sidebar-item-text text-center'>
                        {(menuWidth !== openMenuSize) ? (
                          <KeyboardArrowRightIcon />
                        ) : (
                          <KeyboardArrowLeftIcon />
                        )}
                      </ListItemText>
                    </ListItem>
                  </Tooltip>
                </List>
              ) : (
                <span className='user-info-mobile' style={!isMenuOpen || showMenuItems ? { whiteSpace: 'nowrap' } : { overflowWrap: 'anywhere' }}>
                  <h6 className='d-inline-block'>{user ? user.email : null}</h6>
                </span>
              )}
            </div>
          )}
        </Transition>
      )}
    </>
  );
}

export default MobileMenu;
