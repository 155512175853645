import React, { useEffect } from 'react';
import { Route, withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { useAuth0 } from '../react-auth0-spa';
import { toast } from 'react-toastify';
import history from '../utils/history';

interface Props extends RouteComponentProps {
  component: React.ComponentType<any>;
  path: string | string[];
  exact: boolean;
  render?: (props: any) => JSX.Element;
}

const AdminRoute: React.FC<Props> = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, isAdmin } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      if (isAdmin) {
        return;
      }
      toast.error('Not Authorized!');
      history.push('/');
      return;
    }
    const fn = async () => {
      if (loginWithRedirect) {
        await loginWithRedirect({
          appState: { targetUrl: window.location.pathname }
        });
      }
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, isAdmin, path]);

  const render = (props: any) =>
    (isAuthenticated === true && isAdmin === true) ? <Component {...props} /> : <Redirect to='/' />;

  return <Route path={path} render={render} {...rest} />;
};

export default withRouter(AdminRoute);
