import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

const IERedirect: React.FC = () => {
  useEffect(() => {
    toast.info(
      <p>
        Sevayo™ Insights is designed to be viewed in a modern browser for the best experience. Please retry using
        {' '}
        <a href='https://www.microsoft.com/en-us/edge' target='_blank' rel='noopener noreferrer' className='redirect-link'>Microsoft Edge</a> or
        {' '}
        <a href='https://www.google.com/chrome/' target='_blank' rel='noopener noreferrer' className='redirect-link'>Google Chrome.</a>
      </p>, {
        autoClose: false,
        closeOnClick: false,
        position: 'top-center',
        closeButton: false,
        draggable: false,
        }
      );
  }, []);
  return (
    <div></div>
  )
}

export default IERedirect;
