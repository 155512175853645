import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { toast } from 'react-toastify';
import { IUser, Auth0Client, ParamsObject, ReturnUrl, AppObject, AppState, Organization, Role } from './types/Auth0.interfaces';
import axios, { AxiosResponse } from 'axios';
import { Tenant, TenantGroup } from './types/Tenant.interfaces';
import 'url-search-params-polyfill';

type ContextProps = {
  user: IUser;
  isAuthenticated: boolean;
  isAdmin: boolean;
  loading: boolean;
  popupOpen: boolean;
  userMetadata: string;
  appMetadata: string;
  parseParams: (value: string) => any;
  getTokenSilently: () => string;
  loginWithPopup: (params?: {}) => any;
  handleRedirectCallback: () => any;
  getIdTokenClaims: () => any;
  loginWithRedirect: (appState: AppState) => any;
  getTokenWithPopup: () => any;
  logout: (returnTo: ReturnUrl) => any;
  getInitials: (firstName: string, lastName: string, email: string) => string;
  clientId: string;
  organizationId: string;
  setOrganizationId: (value: string) => void;
  isClientUser: boolean | null;
  handleOrganizationChanged: (organizationId: string) => void;
  userOrganizations: Organization[];
  availableUserTenants: Tenant[];
  allAvailableTenants: Tenant[];
  handleUserUpdate: () => void;
  adminRoleName: string;
  isFirstLogin: string;
}

interface Props {
  onRedirectCallback: (appState: AppObject) => void;
  domain: string;
  client_id: string;
  redirect_uri: string;
  organization: string | undefined;
}

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<Partial<ContextProps>>({});
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider: React.FC<Props> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const [auth0Client, setAuth0] = useState<Auth0Client | any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [userMetadata] = useState<string>('http://mynamespace.com/user_metadata');
  const [appMetadata] = useState<string>('http://mynamespace.com/app_metadata');
  const [rolesMetadata] = useState<string>('http://mynamespace.com/roles');
  const [isFirstLogin] = useState<string>('http://mynamespace.com/is_first_login');
  const [organizationId, setOrganizationId] = useState<string>('');
  const [isClientUser, setIsClientUser] = useState<boolean | null>(null);
  const [userOrganizations, setUserOrganizations] = useState<Organization[]>([]);
  const [availableUserTenants, setAvailableUserTenants] = useState<Tenant[]>([]);
  const [allAvailableTenants, setAllAvailableTenants] = useState<Tenant[]>([]);
  const [adminRoleName] = useState<string>('si-admins');
  const prefix = '/.netlify/functions/auth0Proxy/https://' + process.env.REACT_APP_AUTH0_API_IDENTIFIER + '/api/v2';
  const parseParams = (querystring: string): ParamsObject => {
    // parse query string
    const params = new URLSearchParams(querystring);
    const obj: ParamsObject = {};
    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }
    return obj;
  };

  const isAdminRole = (user: IUser): boolean => !!user[rolesMetadata].find((role: string) => role === adminRoleName);

  const handleUserTenants = async (organizations: Organization[], user: IUser, updateAllTenants: boolean) => {
    if (user && organizations && organizations.length) {
      const userGroups = user[appMetadata as keyof IUser].group_ids;
      const myTenants: Tenant[] = [];
      const allTenants: Tenant[] = [];
      if (userGroups.length) {
        userGroups.forEach((groupID: number) => {
          for (let index = 0; index < organizations.length; index++) {
            const element = organizations[index];
            const groupIndex = element.groups.findIndex((item: TenantGroup) => item.group_id === groupID);
            if (groupIndex > -1) {
              const group = {
                label: element.groups[groupIndex].label,
                group_id: groupID,
                description: element.groups[groupIndex].description,
                weight: element.groups[groupIndex].weight || 0,
                default_dashboard: element.groups[groupIndex].default_dashboard
              };
              const i = myTenants.findIndex((tenantObj: Tenant) => tenantObj.organization_id === element.id);
              if (i > -1) {
                myTenants[i].groups.push(group);
              } else {
                myTenants.push({
                  tenant: element.display_name,
                  groups: [group],
                  organization_id: element.id,
                  name: element.name,
                  logo_url: element.branding.logo_url,
                  otherGroups: element.otherGroups,
                  allowedEmailDomains: element.allowed_email_domains,
                });
              }
              const tenantIndex = allTenants.findIndex((tenantObj: Tenant) => tenantObj.organization_id === element.id);
              if (tenantIndex === -1) {
                allTenants.push({
                  tenant: element.display_name,
                  groups: element.groups,
                  organization_id: element.id,
                  name: element.name,
                  logo_url: element.branding.logo_url,
                  otherGroups: element.otherGroups,
                  allowedEmailDomains: element.allowed_email_domains,
                });
              }
            } else {
              const i = myTenants.findIndex((tenantObj: Tenant) => tenantObj.organization_id === element.id);
              if (i === -1) {
                myTenants.push({
                  tenant: element.display_name,
                  groups: [],
                  organization_id: element.id,
                  name: element.name,
                  logo_url: element.branding.logo_url,
                  otherGroups: element.otherGroups,
                  allowedEmailDomains: element.allowed_email_domains,
                })
              }
              const tenantIndex = allTenants.findIndex((tenantObj: Tenant) => tenantObj.organization_id === element.id);
              if (tenantIndex === -1) {
                allTenants.push({
                  tenant: element.display_name,
                  groups: element.groups,
                  organization_id: element.id,
                  name: element.name,
                  logo_url: element.branding.logo_url,
                  otherGroups: element.otherGroups,
                  allowedEmailDomains: element.allowed_email_domains,
                });
              }
            }
          }
        });
        myTenants.forEach((client: Tenant) => {
          client.groups.sort((groupA: TenantGroup, groupB: TenantGroup) => groupA.weight && groupB.weight ? groupB.weight - groupA.weight : -1);
          client.otherGroups.sort((groupA: TenantGroup, groupB: TenantGroup) => groupA.weight && groupB.weight ? groupB.weight - groupA.weight : -1);
        });
        allTenants.forEach((client: Tenant) => {
          client.groups.sort((groupA: TenantGroup, groupB: TenantGroup) => groupA.weight && groupB.weight ? groupB.weight - groupA.weight : -1);
          client.otherGroups.sort((groupA: TenantGroup, groupB: TenantGroup) => groupA.weight && groupB.weight ? groupB.weight - groupA.weight : -1);
          if (user[userMetadata as keyof IUser].is_client_user === true) {
            client.groups.filter((group: TenantGroup) => group.label.toLowerCase().includes('explorer')).forEach((group: TenantGroup) => {
              group.label = group.label.split(' ')[group.label.split(' ').length - 1];
            });
          }
        });
        if (!user[userMetadata as keyof IUser].is_client_user) {
          const sevenstepOrg = myTenants.find((tenant: Tenant) => tenant.name === 'sevenstep');
          if (sevenstepOrg && user.org_id !== sevenstepOrg.organization_id) {
            try {
              const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
              const response: AxiosResponse<Role[]> = await axios.get(`${prefix}/organizations/${sevenstepOrg.organization_id}/members/${user.sub}/roles`, options);
              const sevenstepAdmin = response.data.find((role: Role) => role.name === adminRoleName);
              if (sevenstepAdmin) {
                setAvailableUserTenants(sortTenants(myTenants));
              } else {
                setAvailableUserTenants(sortTenants(myTenants.filter((tenant: Tenant) => tenant.name !== 'sevenstep')));
              }
            } catch (error) {
              setAvailableUserTenants(sortTenants(myTenants));
            }
          } else {
            setAvailableUserTenants(sortTenants(myTenants));
          }
        } else {
          setAvailableUserTenants(sortTenants(myTenants));
        }
        if (updateAllTenants) {
          setAllAvailableTenants(sortTenants(allTenants));
        }
      }
    }
  }

  const sortTenants = (tenantArray: Tenant[]): Tenant[] =>  tenantArray.sort((a: Tenant, b: Tenant) => a.tenant > b.tenant ? 1 : - 1);

  const handleUserUpdate = async () => {
    const options = { ...initOptions, organization: organizationId };
    const auth0FromHook = await createAuth0Client(options);
    const user = await auth0FromHook.getUser();
    setUser(user);
    await handleUserTenants(userOrganizations, user, false);
  }

  const handleOrganizationChanged = async (organizationId: string) => {
    const options = { ...initOptions, organization: organizationId };
    const auth0FromHook = await createAuth0Client(options);
    const user = await auth0FromHook.getUser();
    setIsAdmin(isAdminRole(user));
  }

  const isObjectJSON = (data: any): boolean => {
    let ret = false;
    try {
      JSON.parse(data);
      ret = true;
    } catch (e) {
      ret = false;
    }
    return ret;
  }

  const loadUserOrganizations = async (user: IUser, clientUser: boolean) => {
    try {
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const response: AxiosResponse<Organization[]> = await axios.get(`${prefix}/users/${user.sub}/organizations`, options);
      const results = response.data.map((organization: Organization) => {
        const organizationRoles: TenantGroup[] = []
        Object.entries(organization.metadata).forEach((group: [string, any]) => {
          if (isObjectJSON(group[1])) {
            const groupData = JSON.parse(group[1]);
            if (groupData.hasOwnProperty('label')) {
              organizationRoles.push(groupData);
            } else {
              if (group[0] === 'allowed_email_domains') {
                organization.allowed_email_domains = JSON.parse(group[1]);
              } else {
                organization.allowed_email_domains = [];
              }
            }
          }
        });
        organization.groups = organizationRoles.filter((group: TenantGroup) => group.client_group === clientUser || group.client_group === null);
        organization.otherGroups = organizationRoles.filter((group: TenantGroup) => group.client_group === !clientUser || group.client_group === null)
        return organization;
      });
      setUserOrganizations(results);
      await handleUserTenants(results, user, true);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with loading user organizations!');
      }
    }
  }

  useEffect(() => {
    const initAuth0 = async () => {
      const params = parseParams(window.location.search);
      const options = initOptions.organization ? { ...initOptions } : params.organization ? { ...initOptions, organization: params.organization } : localStorage.organizationId ? { ...initOptions, organization: localStorage.organizationId } : initOptions;
      const auth0FromHook = await createAuth0Client(options);
      setAuth0(auth0FromHook);
      if (params.code && params.state) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      } else if (params.error) {
        localStorage.removeItem('organizationId');
        toast.error(
          <>
            <h3>{params.error.charAt(0).toUpperCase() + params.error.slice(1)}</h3>
            {params.error_description ? <p>{params.error_description.charAt(0).toUpperCase() + params.error_description.slice(1)}</p> : <></>}
          </>
        );
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setOrganizationId(user.org_id);
        localStorage.organizationId = user.org_id;
        setIsClientUser(user[userMetadata as keyof IUser].is_client_user);
        setUser(user);
        setIsAdmin(isAdminRole(user));
        await loadUserOrganizations(user, user[userMetadata as keyof IUser].is_client_user);
      }
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      toast.warning(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
    setIsAdmin(isAdminRole(user));
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setUser(user);
    setIsAuthenticated(true);
    setIsAdmin(isAdminRole(user));
  };

  const getInitials = (email: string, firstName?: string | undefined, lastName?: string | undefined): string => {
    if (firstName && lastName) {
      return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    } else {
      return `${email.slice(0,2).toUpperCase()}`;
    }
  }

  return (
    <Auth0Context.Provider
      value={{
        clientId: initOptions.client_id,
        isAuthenticated,
        isAdmin,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        userMetadata,
        appMetadata,
        getInitials,
        parseParams,
        organizationId,
        setOrganizationId,
        isClientUser,
        handleOrganizationChanged,
        userOrganizations,
        availableUserTenants,
        allAvailableTenants,
        handleUserUpdate,
        adminRoleName,
        isFirstLogin,
        getIdTokenClaims: (...p: any) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p: any) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p: any) => auth0Client.getTokenWithPopup(...p),
        logout: (...p: any) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
