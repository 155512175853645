import React, { useState, useEffect } from 'react';
import { FormControl, Input, InputLabel, Select, MenuItem, Checkbox, ListItemText, FormHelperText, Tooltip, Box } from '@material-ui/core';
import { InvitedUser, Tenant, TenantGroup } from '../types/Tenant.interfaces';
import { EditComponentProps } from 'material-table';
import { useAuth0 } from '../react-auth0-spa';


interface Props {
  availableTenantGroups: TenantGroup[];
  setGroups?: (value: number[]) => void;
  groups: number[];
  showLabel: boolean;
  showOtherGroups: boolean;
  tableData?: EditComponentProps<InvitedUser>;
  label?: string;
}

const GroupSelect: React.FC<Props> = ({ availableTenantGroups, setGroups, groups, showLabel, tableData, label, showOtherGroups }) => {
  const { allAvailableTenants, isClientUser } = useAuth0();
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [otherGroups, setOtherGroups] = useState<number[]>([]);
  const [userAdminGroups, setUserAdminGroups] = useState<TenantGroup[]>([]);

  useEffect(() => {
    if (allAvailableTenants && allAvailableTenants.length) {
      const adminGroups: TenantGroup[] = [];
      allAvailableTenants.forEach((tenant: Tenant) => {
        adminGroups.push(...tenant.groups);
      });
      setUserAdminGroups(adminGroups);
    }
  }, [allAvailableTenants]);

  useEffect(() => {
    const items = groups.filter((groupId: number) => !availableTenantGroups.map((group: TenantGroup) => group.group_id).includes(groupId));
    if (items.length > 0) {
      setOtherGroups(items);
    }
  }, [groups, availableTenantGroups]);

  useEffect(() => {
    if (groups.length && availableTenantGroups.map((group: TenantGroup) => group.group_id).every((groupId: number) => groups.includes(groupId))) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [groups, availableTenantGroups]);

  const groupChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let value: Array<number | string> = [...event.target.value as Array<number | string>];
    const includeAll = value.includes('all');
    if (includeAll && checkedAll === false) {
      value = [...availableTenantGroups.map((group) => group.group_id), ...otherGroups];
      setCheckedAll(true);
    } else if (!includeAll && value.length === availableTenantGroups.length) {
      setCheckedAll(true);
    } else if (!includeAll && value.length !== availableTenantGroups.length) {
      setCheckedAll(false);
    } else if (includeAll && checkedAll === true && value.length > availableTenantGroups.length) {
      setCheckedAll(false);
      value = [...otherGroups];
    }
    const groupsArray = [...value.filter((groupId: number | string) => groupId !== undefined).map((groupId: number | string) => +groupId)];
    if (setGroups) {
      setGroups(groupsArray);
    } else if (tableData) {
      tableData.onChange(groupsArray);
    }
  }

  return (
    <FormControl className='full-width'>
      {showLabel && <InputLabel id='mutiple-group-label'>{label}</InputLabel>}
      <Select
        labelId='mutiple-group-label'
        id='mutiple-group'
        multiple
        value={groups || []}
        renderValue={() => availableTenantGroups.filter((group: TenantGroup) => groups.includes(group.group_id)).map((group: TenantGroup) => group.label).join(', ')}
        onChange={(e: React.ChangeEvent<{ value: unknown }>) => groupChange(e)}
        input={<Input />}
        style={{ textAlign: 'left' }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          classes: {
            paper: 'paper'
          }
        }}
      >
        <MenuItem value={'all'}>
          <Checkbox checked={checkedAll} />
          <ListItemText primary='Select all' />
        </MenuItem>
        {availableTenantGroups.map((group: TenantGroup) => (
          <MenuItem key={group.group_id} value={group.group_id}>
            <Checkbox checked={groups && groups.indexOf(group.group_id) > -1} />
            <Tooltip placement='right' title={group.description}>
              <ListItemText primary={group.label} />
            </Tooltip>
          </MenuItem>
        ))}
        {!isClientUser && showOtherGroups && otherGroups.length && otherGroups
          .filter((groupId: number) => userAdminGroups.map((tenantGroup: TenantGroup) => tenantGroup.group_id).includes(groupId))
          .map((groupId: number) => (
            <Tooltip placement='right' title={'You can\'t remove this role!'}>
              <Box key={groupId}>
                <MenuItem value={groupId} disabled>
                  <Checkbox checked />
                  <ListItemText primary={userAdminGroups.find((group: TenantGroup) => group.group_id === groupId)?.label} />
                </MenuItem>
              </Box>
            </Tooltip>
          )
        )}
      </Select>
      {showLabel && <FormHelperText>This value can be blank!</FormHelperText>}
    </FormControl>
  )
}

export default GroupSelect;
