import { EmbedBuilder } from './embed_builder';
import { LookerEmbedDashboard } from './dashboard_client';
import { LookerEmbedExplore } from './explore_client';
import { LookerEmbedLook } from './look_client';

export { LookerEmbedDashboard } from './dashboard_client';
export { LookerEmbedExplore } from './explore_client';
export { LookerEmbedLook } from './look_client';

export class LookerEmbedSDK {

  /**
   * Initialize the Embed SDK.
   *
   * @param apiHost The address or base URL of the Looker host (example.looker.com:9999, https://example.looker.com:9999)
   * @param authUrl A server endpoint that will sign SSO embed URLs
   */

  static init(apiHost: string, authUrl?: string) {
    this.apiHost = apiHost
    this.authUrl = authUrl
  }

  /**
   * Create an EmbedBuilder for an embedded Looker dashboard.
   *
   * @param url A signed SSO embed URL or embed URL for an already authenticated Looker user
   */

  static createDashboardWithUrl(url: string) {
    return new EmbedBuilder<LookerEmbedDashboard>(this, 'dashboard', '/embed/dashboards', LookerEmbedDashboard).withUrl(url)
  }

  /**
   * Create an EmbedBuilder for an embedded Looker dashboard.
   *
   * @param id The numeric ID of a Looker User Defined Dashboard, or LookML Dashboard ID
   */

  static createDashboardWithId(id: string | number, queryParams?: any) {
    return new EmbedBuilder<LookerEmbedDashboard>(this, 'dashboard', '/embed/dashboards', LookerEmbedDashboard).withId(id, queryParams);
  }

  /**
   * Create an EmbedBuilder for an embedded Looker Explore.
   *
   * @param url A signed SSO embed URL or embed URL for an already authenticated Looker user
   */

  static createExploreWithUrl(url: string) {
    return new EmbedBuilder<LookerEmbedExplore>(this, 'explore', '/embed/explore', LookerEmbedExplore).withUrl(url);
  }

  /**
   * Create an EmbedBuilder for an embedded Looker Explore.
   *
   * @param id The ID of a Looker explore
   */

  static createExploreWithId(id: string) {
    id = id.replace('::', '/') // Handle old format explore ids.
    return new EmbedBuilder<LookerEmbedExplore>(this, 'explore', '/embed/explore', LookerEmbedExplore).withId(id);
  }

  /**
   * Create an EmbedBuilder for an embedded Looker Look.
   *
   * @param url A signed SSO embed URL or embed URL for an already authenticated Looker user
   */

  static createLookWithUrl(url: string) {
    return new EmbedBuilder<LookerEmbedLook>(this, 'look', '/embed/looks', LookerEmbedLook).withUrl(url);
  }

  /**
   * Create an EmbedBuilder for an embedded Looker dashboard.
   *
   * @param id The ID of a Looker Look
   */

  static createLookWithId(id: number) {
    return new EmbedBuilder<LookerEmbedLook>(this, 'look', '/embed/looks', LookerEmbedLook).withId(id);
  }

  /**
   * @hidden
   */

  static apiHost: string

  /**
   * @hidden
   */

  static authUrl?: string
}
