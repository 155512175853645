import React, { useEffect } from 'react';
import { Tenant } from '../types/Tenant.interfaces';
import { Card, Box, Tooltip } from '@material-ui/core';
import { useAuth0 } from '../react-auth0-spa';

interface Props {
  tenant: Tenant | null;
  redirectToDefaultDashboard: (tenant: Tenant) => void;
  isSevenstepOrganization: boolean;
  tenantChange: (tenant: Tenant) => void;
}

const Home: React.FC<Props> = ({ tenant, redirectToDefaultDashboard, isSevenstepOrganization, tenantChange }) => {
  const { availableUserTenants } = useAuth0();

  useEffect(() => {
    if (tenant) {
      redirectToDefaultDashboard(tenant);
    }
  }, [tenant, redirectToDefaultDashboard]);

  return (
    <div>
      {isSevenstepOrganization ? (
        <Box className='card-wrapper'>
          {availableUserTenants && availableUserTenants.length > 1 && availableUserTenants.map((client: Tenant) => (
            <Tooltip title={client.tenant} placement='bottom'>
              <Card key={client.organization_id} className='card' onClick={() => tenantChange(client)}>
                <Box className='card-image'>
                  <img src={client.logo_url} alt={client.tenant} style={{ width: '200px' }} />
                </Box>
              </Card>
            </Tooltip>
          ))}
        </Box>
      ) : null}
    </div>
  );
};

export default Home;
