import React, { useEffect, useState } from 'react';
import { Box, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Tenant } from '../types/Tenant.interfaces';

interface Props {
  userType: boolean;
  setUserType: (value: boolean) => void;
  label: string;
  handleTypeChange: (client: Tenant, setNewTenant: (value: Tenant) => void) => void;
  tenant: Tenant | null;
  setTenant: (value: Tenant) => void;
  setGroups: (value: number[]) => void;
}

const UserType: React.FC<Props> = ({ userType, setUserType, label, handleTypeChange, tenant, setTenant, setGroups }) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (tenant && tenant.otherGroups) {
      setDisabled(!tenant.otherGroups.length);
    }
  }, [tenant]);

  const handleUserTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value === 'true' ? true : false;
    setUserType(type);
    setGroups([]);
    tenant && handleTypeChange(tenant, setTenant);
  }

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
      <RadioGroup
        value={userType}
        name='client-user'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUserTypeChange(e)}
        style={{ flexDirection: 'row' }}
      >
        <FormControlLabel value={true} control={<Radio />} label={`Client ${label}`} checked={userType === true} style={{ marginBottom: '0' }} disabled={disabled} />
        <FormControlLabel value={false} control={<Radio />} label={`Internal ${label}`} checked={userType === false} style={{ marginBottom: '0' }} disabled={disabled} />
      </RadioGroup>
    </Box>
  )
}

export default UserType;
