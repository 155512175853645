import React from 'react';
import useWindowSize from '../utils/useWindowSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, ListSubheader, List, Box, ListItem, makeStyles, Backdrop } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Skeleton from '@yisheng90/react-loading';
import { NavLink } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import EmailIcon from '@material-ui/icons/Email';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { useAuth0 } from '../react-auth0-spa';
import { Transition } from 'react-transition-group';
import { Tenant } from '../types/Tenant.interfaces';
import { Incident } from '../types/Statuspage.interfaces';
import { TransitionStatus } from 'react-transition-group/Transition';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    maxHeight: 'calc(100vh - 70px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media (max-width: 767px)': {
      maxHeight: 'calc(100vh - 70px)',
    }
  },
}));

interface Props {
  menuWidth: number;
  toggleMenu: () => void;
  tenantChange: (tenant: Tenant) => void;
  activeTenant: Tenant | null;
  clientTenant: Tenant | null;
  changeToClientPortal: (tenant: Tenant) => void;
  isClientPortal: boolean;
  moreInfoDashboardId: number | null;
  notifications: { scheduled: Incident[], active: Incident[], unresolved: Incident[] } | null;
  orgName: string;
  whatsNewDashboardId: number | null;
  handleCloseMenu: (event: React.MouseEvent<any>) => void;
  openMenuSize: number;
  dashboardIndexId: number | null;
}

const MobileRightSideMenu: React.FC<Props> = ({
  menuWidth,
  toggleMenu,
  tenantChange,
  activeTenant,
  clientTenant,
  changeToClientPortal,
  isClientPortal,
  moreInfoDashboardId,
  notifications,
  orgName,
  whatsNewDashboardId,
  handleCloseMenu,
  openMenuSize,
  dashboardIndexId
}) => {
  const { width, height } = useWindowSize();
  const { logout, isAdmin, availableUserTenants, isClientUser, loading } = useAuth0();
  const classes = useStyles();

  const duration = 500;
  const isMenuOpen = menuWidth === openMenuSize;

  const sidebarStyle = {
    transition: `width ${duration}ms`
  }

  const sidebarTransitionStyles: { [id: string]: React.CSSProperties } = {
    entering: { width: menuWidth },
    entered: { width: menuWidth },
    exiting: { width: menuWidth },
    exited: { width: menuWidth }
  }

  const logoutWithRedirect = () => {
    logout && logout({ returnTo: window.location.origin });
  };

  const navClick = (viewWidth: number) => {
    if (viewWidth < 768) {
      toggleMenu();
    }
  };

  const getFolderMenu = () => {
    return (
      <List
        component='nav'
        aria-labelledby='nested-list'
        className={classes.root}
        dense
        disablePadding
      >
        {width < 768 && (
          <>
            {availableUserTenants && availableUserTenants.length > 1 && (
              <>
                <Box>
                  <ListSubheader disableSticky={true}>
                    Clients
                  </ListSubheader>
                  {availableUserTenants.map((tenant: Tenant) => (
                    <ListItem
                      key={tenant.organization_id}
                      style={{
                        color: '#74c8b0',
                        fontWeight: activeTenant && activeTenant.tenant === tenant.tenant ? 600 : 400
                      }}
                      onClick={() => {
                        navClick(width);
                        tenantChange(tenant);
                      }}
                    >
                      {tenant.tenant}
                    </ListItem>
                  ))}
                </Box>
                <Divider />
              </>
            )}
            {isAdmin && (
              <>
                <ListSubheader disableSticky={true}>
                  Admin
                </ListSubheader>
                <ListItem className='mobile-list-item'>
                  <NavLink
                    to={`/users?page=1&page-size=10${orgName ? `&org=${orgName}` : ''}`}
                    onClick={() => navClick(width)}
                    activeClassName='router-link-exact-active'
                  >
                    <FontAwesomeIcon icon='users' className='mr-3' style={{ width: '24px' }} />
                    User Management
                  </NavLink>
                </ListItem>
                <ListItem className='mobile-list-item'>
                  <NavLink
                    to='/invite-users'
                    onClick={() => navClick(width)}
                    activeClassName='router-link-exact-active'
                  >
                    <FontAwesomeIcon icon='user-plus' className='mr-3' style={{ width: '24px' }} />
                    Users Invitation
                  </NavLink>
                </ListItem>
                <ListItem className='mobile-list-item'>
                  <NavLink
                    to='/pending-invitations'
                    onClick={() => navClick(width)}
                    activeClassName='router-link-exact-active'
                  >
                    <FontAwesomeIcon icon='paper-plane' className='mr-3' style={{ width: '24px' }} />
                    Pending Invitations
                  </NavLink>
                </ListItem>
                {!isClientUser && !isClientPortal && (
                  <ListItem className='mobile-list-item'>
                    <NavLink
                      to='/existing-invited-users'
                      onClick={() => navClick(width)}
                      activeClassName='router-link-exact-active'
                    >
                      <FontAwesomeIcon icon='user-friends' className='mr-3' style={{ width: '24px' }} />
                      Recently Added Users
                    </NavLink>
                  </ListItem>
                )}
              </>
            )}
            <Box>
              <ListSubheader disableSticky={true}>
                Profile
              </ListSubheader>
              {whatsNewDashboardId && (
                <ListItem className='mobile-list-item'>
                  <NavLink
                    to='/whats-new'
                    onClick={() => navClick(width)}
                    activeClassName='router-link-exact-active'
                  >
                    <NewReleasesIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                    What's New
                  </NavLink>
                </ListItem>
              )}
              {moreInfoDashboardId && (
                <ListItem className='mobile-list-item'>
                  <NavLink
                    to='/more-information'
                    onClick={() => navClick(width)}
                    activeClassName='router-link-exact-active'
                  >
                    <InfoIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                    More Information
                  </NavLink>
                </ListItem>
              )}
              {dashboardIndexId && (
                <ListItem className='mobile-list-item'>
                  <NavLink
                    to='/dashboard-index'
                    onClick={() => navClick(width)}
                    activeClassName='router-link-exact-active'
                  >
                    <DashboardIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                    Dashboard Index
                  </NavLink>
                </ListItem>
              )}
              <ListItem className='mobile-list-item'>
                <NavLink
                  to='#'
                  onClick={() => {
                    window.location.href = 'mailto:support@sevayoinsights.com';
                    navClick(width);
                  }}
                >
                  <EmailIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                  Email Support
                </NavLink>
              </ListItem>
              <ListItem className='mobile-list-item'>
                <NavLink
                  to='/notifications'
                  onClick={() => navClick(width)}
                  activeClassName='router-link-exact-active'
                >
                  {notifications ? (
                    <NotificationsActiveIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                  ) : (
                    <NotificationsIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                  )}
                  Notifications
                </NavLink>
              </ListItem>
              {!isClientUser && clientTenant ? (
                <ListItem className='mobile-list-item'>
                  <NavLink
                    to='#'
                    onClick={() => {
                      changeToClientPortal(clientTenant);
                      navClick(width);
                    }}
                  >
                    <DashboardIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                    Switch to {`${isClientPortal ? 'internal' : 'client'}`} portal
                  </NavLink>
                </ListItem>
              ) : null}
              <ListItem className='mobile-list-item'>
                <NavLink
                  to='/profile'
                  onClick={() => navClick(width)}
                  activeClassName='router-link-exact-active'
                >
                  <FontAwesomeIcon icon='user' className='mr-3' style={{ width: '24px' }} />
                  Profile
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to='#'
                  onClick={() => logoutWithRedirect()}
                  className='mobile-list-item'
                >
                  <FontAwesomeIcon icon='power-off' className='mr-3' style={{ width: '24px' }} />
                  Log out
                </NavLink>
              </ListItem>
            </Box>
          </>
        )}
      </List>
    )
  }

  return (
    <>
      <Backdrop className='backdrop' open={isMenuOpen} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleCloseMenu(event)}>
        <Transition timeout={duration}>
          {(state: TransitionStatus) => (
            <div className='right-menu-style' style={{ ...sidebarStyle, ...sidebarTransitionStyles[state] }}>
              {loading ? (
                <div style={{ padding: '5px' }}>
                  <Skeleton rows={Math.floor((height - 150) / 40)} height={40} />
                </div>
              ) : getFolderMenu()}
            </div>
          )}
        </Transition>
      </Backdrop>
    </>
  );
}

export default MobileRightSideMenu;
