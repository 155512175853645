import { LookerDashboardOptions, LookerEmbedFilterParams } from './types'
import { LookerEmbedBase } from './embed_base'

/**
 * Client that communicates with an embedded Looker dashboard. Messages are documented
 * [here](https://docs.looker.com/r/sdk/events)
 */

export class LookerEmbedDashboard extends LookerEmbedBase {
  /**
   * Convenience method for sending a run message to the embedded dashboard.
   */

  run() {
    this.send('dashboard:run');
  }

  /**
   * Convenience method for updating the filters of the embedded dashboard.
   *
   * @param filters A set of filter parameters to update
   */

  updateFilters(params: LookerEmbedFilterParams) {
    this.send('dashboard:filters:update', { filters: params });
  }

  /**
   * Convenience method for setting options on the embedded dashboard.
   *
   * @param options An options object to be applied
   */

  setOptions(options: LookerDashboardOptions) {
    this.send('dashboard:options:set', options);
  }
}
