import React, { useEffect } from 'react';
import { LookerEmbedDashboard, LookerEmbedSDK } from '../utils/embed_sdk';
import Loading from '../components/Loading';
import { useAuth0 } from '../react-auth0-spa';
import { useParams } from 'react-router-dom';
import { DashboardFilters, LookerDashboardFilter } from '../types/Looker.interfaces';
import { Tenant, ParamTypes, TenantGroup } from '../types/Tenant.interfaces';
import { IUser } from '../types/Auth0.interfaces';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { DashboardEvent } from '../utils/types';

interface Props {
  tenant: Tenant;
  embedToken: string;
  BASE_URL: string;
  expirationTime: Date | null;
  lookerHost: string;
  checkTokenValidity: (message: string) => boolean;
}

const Dashboard: React.FC<Props> = ({ tenant, embedToken, BASE_URL, expirationTime, lookerHost, checkTokenValidity }) => {
  const { loading, user, userMetadata, appMetadata } = useAuth0();
  const { id } = useParams<ParamTypes>();
  LookerEmbedSDK.init(lookerHost, `/.netlify/functions/auth_utils`);

  const destroyExisting = (className: string) => {
    const iframe = document.querySelector(`.${className}`);
    if (iframe && iframe.parentNode !== null) {
      iframe.parentNode.removeChild(iframe);
    }
  };

  useEffect(() => {
    const dashboardFilter = getFilters();
    if (id && BASE_URL && embedToken && dashboardFilter && expirationTime) {
      (async function iife() {
        const isValid = checkTokenValidity('');
        if (!isValid) {
          return;
        }
        const options = { headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json', Authorization: embedToken } };
        try {
          const response: AxiosResponse<LookerDashboardFilter[]> = await axios.get(`${BASE_URL}/dashboards/${id}/dashboard_filters`, options);
          const filters: DashboardFilters = {};
          response.data.forEach((filter: LookerDashboardFilter) => {
            const name = filter.name;
            filters[name] = '';
          });
          const value = haveSameProperites(dashboardFilter, filters);
          if (!value) {
            Object.keys(filters).forEach((filter: string) => {
              filters[filter] = dashboardFilter[filter];
            });
            saveToLocalStorage(filters);
          }
        } catch (error) {
          toast.warning(`Something went wrong with loading dashboard filters!`);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, embedToken, BASE_URL, expirationTime]);

  const lookerFilters = JSON.parse(localStorage.lookerFilters || null) || {};

  const haveSameProperites = (obj1: DashboardFilters, obj2: DashboardFilters) => Object.keys(obj1).every((prop: string) => obj2.hasOwnProperty(prop));

  const saveToLocalStorage = (obj: object) => {
    lookerFilters[id] = obj;
    localStorage.lookerFilters = JSON.stringify(lookerFilters);
  };

  const getFilters = () => lookerFilters[id] || undefined;

  useEffect(() => {
    if (id && user && tenant) {
      const dashboardFilter = getFilters();
      const getUser = () => {
        return {
          ...user[appMetadata as keyof IUser],
          external_user_id: user.sub,
          ...user[userMetadata as keyof IUser],
          group_ids: tenant.groups.map((x: TenantGroup) => x.group_id),
        };
      };
      const className = 'looker-embed';
      destroyExisting(className);
      const embed = LookerEmbedSDK.createDashboardWithId(id, dashboardFilter)
        .withClassName(className)
        .withNext();
      embed
        .on('dashboard:run:complete', (event: DashboardEvent) => { })
        .on('dashboard:filters:changed', (event: DashboardEvent) => {
          if (JSON.stringify(event.dashboard.dashboard_filters) !== JSON.stringify(getFilters())) {
            saveToLocalStorage(event.dashboard.dashboard_filters);
          }
        })
        .build()
        .connect(getUser(), '#dash-wrapper')
        .then((res: { connection: Promise<LookerEmbedDashboard>, url: string | null | undefined }) => {
          res.connection.then((dashboard: LookerEmbedDashboard) => {});
        })
        .catch((error: Error) => {
          toast.warning('Connection error!');
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user, tenant, userMetadata, appMetadata]);

  return (
    <>
      {loading || !user ? <Loading /> : <div id='dash-wrapper'></div>}
    </>
  );
};

export default Dashboard;
