import React from 'react';
import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
import { Tenant } from '../types/Tenant.interfaces';
import { Member } from '../types/Auth0.interfaces';
import history from '../utils/history';

interface Props {
  activeOrganization: Tenant | null;
  setActiveOrganization: (value: Tenant | null) => void;
  showLabel: boolean;
  adminUserTenants: Tenant[];
  setGroups?: (value: number[]) => void;
  userType: boolean;
  setType?: (value: boolean) => void;
  setPageOrganizationId?: (value: string) => void;
  setMembers?: (value: Member[]) => void;
  changeParam: boolean;
  pageSize?: number;
}

const OrganizationSelect: React.FC<Props> = ({
  activeOrganization, setActiveOrganization, showLabel, setGroups, adminUserTenants,
  userType, setType, setPageOrganizationId, setMembers, changeParam, pageSize
}) => {
  const handleOrganizationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGroups && setGroups([]);
    setMembers && setMembers([]);
    if (!changeParam) {
      setPageOrganizationId && setPageOrganizationId('');
      let organization = adminUserTenants?.find((tenant: Tenant) => tenant.organization_id === event.target.value);
      if (organization) {
        const groupType = organization.groups[0].client_group;
        if (groupType !== userType && organization.otherGroups.length) {
          organization = {
            ...organization,
            groups: organization.otherGroups,
            otherGroups: organization.groups,
          }
        } else {
          setType && setType(false);
        }
        setActiveOrganization(organization);
      }
    } else {
      const organization = adminUserTenants?.find((tenant: Tenant) => tenant.organization_id === event.target.value);
      if (organization) {
        history.push(`/users?page=1&page-size=${pageSize}&org=${organization?.name}`);
        const groupType = organization.groups[0].client_group;
        if (groupType !== userType) {
          setType && setType(false);
        }
      }
    }
  }

  return (
    <FormControl className='full-width' style={{ minWidth: '200px' }}>
      {showLabel && <InputLabel id='organization-label'>Organization</InputLabel>}
      <Select
        labelId='organization-label'
        id='organization'
        value={activeOrganization?.organization_id}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleOrganizationChange(event)}
        style={{ textAlign: 'left' }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          classes: {
            paper: 'paper'
          }
        }}
      >
        {adminUserTenants?.map((tenant: Tenant) => (
          <MenuItem key={tenant.organization_id} value={tenant.organization_id}>
            {tenant.tenant}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default OrganizationSelect;
