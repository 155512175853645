import React, { useEffect } from 'react';
import { LookerEmbedLook, LookerEmbedSDK } from '../utils/embed_sdk';
import Loading from '../components/Loading';
import { useAuth0 } from '../react-auth0-spa';
import { ParamTypes, Tenant, TenantGroup } from '../types/Tenant.interfaces';
import { IUser } from '../types/Auth0.interfaces';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props {
  tenant: Tenant | null;
  lookerHost: string;
}

const Look: React.FC<Props> = ({ tenant, lookerHost }) => {
  const { loading, user, userMetadata, appMetadata } = useAuth0();
  const { id } = useParams<ParamTypes>();
  LookerEmbedSDK.init(lookerHost, `/.netlify/functions/auth_utils`);

  const destroyExisting = (className: string) => {
    const iframe = document.querySelector(`.${className}`);
    if (iframe && iframe.parentNode !== null) {
      iframe.parentNode.removeChild(iframe);
    }
  };

  useEffect(() => {
    if (id && user && tenant) {
      const getUser = () => {
        return {
          ...user[appMetadata as keyof IUser],
          external_user_id: user.sub,
          ...user[userMetadata as keyof IUser],
          group_ids: tenant.groups.map((x: TenantGroup) => x.group_id),
        };
      };
      const className = 'looker-embed';
      destroyExisting(className);
      LookerEmbedSDK.createLookWithId(+id)
        .withClassName(className)
        .build()
        .connect(getUser(), '#dash-wrapper')
        .then((res: { connection: Promise<LookerEmbedLook>, url: string | null | undefined }) => {
          res.connection.then((look: LookerEmbedLook) => { });
        })
        .catch((error: Error) => {
          toast.warning('Connection error!');
        })
    }
  }, [id, user, tenant, userMetadata, appMetadata]);

  return (
    <>
      {loading || !user ? <Loading /> : <div id='dash-wrapper'></div>}
    </>
  );
}

export default Look;
