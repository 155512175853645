import React, { ReactElement, useEffect, useState } from 'react';
import Loading from '../components/Loading';
import NotificationItem from '../components/NotificationItem';
import ModalComponent from '../components/Modal';
import { Box, Typography, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { Incident, Component, ComponentUptime, Status, StatusValue, Subscriber } from '../types/Statuspage.interfaces';
import { differenceInDays } from 'date-fns';
import { useAuth0 } from '../react-auth0-spa';
import { Auth0User } from '../types/Auth0.interfaces';

interface Props {
  statuspageUrl: string;
  statuspageId: string;
  prefix: string;
}

const Notifications: React.FC<Props> = ({ statuspageUrl, statuspageId, prefix }) => {
  const { user } = useAuth0();
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(true);
  const [components, setComponents] = useState<Component[]>([]);
  const [loadingComponents, setLoadingComponents] = useState<boolean>(true);
  const [status, setStatus] = useState<StatusValue | null>(null);
  const [subscribeModal, setSubscribeModal] = useState<boolean>(false);
  const [subscriber, setSubscriber] = useState<Subscriber | null>(null);
  const [loadingSubscriber, setLoadingSubscriber] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [unsubscribeModal, setUnsubscribeModal] = useState<boolean>(false);

  useEffect(() => {
    if (statuspageUrl && statuspageId) {
      (async function iife() {
        try {
          setLoadingNotifications(true);
          const options = { headers: { 'content-type': 'application/json' } };
          const response: AxiosResponse<Incident[]> = await axios.get(`${statuspageUrl}/pages/${statuspageId}/incidents`, options);
          response.data.forEach((incident: Incident) => {
            incident.open = false;
          })
          setIncidents(response.data);
        } catch (error) {
          toast.warning('Something went wrong with loading notifications!');
          setIncidents([]);
        } finally {
          setLoadingNotifications(false);
        }
      })();
    }
  }, [statuspageUrl, statuspageId]);

  useEffect(() => {
    if (user) {
      (async function iife() {
        try {
          setLoadingSubscriber(true);
          const options = { headers: { 'content-type': 'application/json' } };
          const response: AxiosResponse<Subscriber[]> = await axios.get(`${statuspageUrl}/pages/${statuspageId}/subscribers?q=${user.email}&state=active`, options);
          const subscribedUser = response.data.find((account: Subscriber) => account.email === user.email);
          setSubscriber(subscribedUser ? subscribedUser : null);
        } catch (error) {
          if (error.response.data.message) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with checking subscription to notifications!');
          }
          setSubscriber(null);
        } finally {
          setLoadingSubscriber(false);
        }
      })();
    }
  }, [statuspageUrl, statuspageId, user]);

  useEffect(() => {
    if (statuspageUrl && statuspageId) {
      (async function iife() {
        try {
          setLoadingComponents(true);
          const options = { headers: { 'content-type': 'application/json' } };
          const response: AxiosResponse<Component[]> = await axios.get(`${statuspageUrl}/pages/${statuspageId}/components`, options);
          const statusWeight: Status = {
            'operational': { weight: 60, label: 'operational', color: '#74c8b0' },
            'under_maintenance': { weight: 70, label: 'under maintenance', color: '#F1C40E' },
            'degraded_performance': { weight: 80, label: 'degraded performance', color: '#F1C40E' },
            'partial_outage': { weight: 90, label: 'minor outage', color: '#F1C40E' },
            'major_outage': { weight: 100, label: 'partial system outage',color: '#EE3B32' },
          }
          const status: Status = {};
          const componentUptimeData = await Promise.all(
            response.data.map(async (component: Component) => {
              if (component.showcase) {
                const compUptime: AxiosResponse<ComponentUptime> = await axios.get(`${statuspageUrl}/pages/${statuspageId}/components/${component.id}/uptime`, options);
                component.uptime_data = compUptime.data;
              }
              if (!status[component.status]) {
                status[component.status] = statusWeight[component.status];
              }
              return component;
            })
          );
          handleComponentStatus(status);
          setComponents(componentUptimeData);
        } catch (error) {
          if (error.response.data.message) {
            toast.warning(error.response.data.message);
          } else {
            toast.warning('Something went wrong with getting components status!');
          }
          setComponents([]);
          setStatus(null);
        } finally {
          setLoadingComponents(false);
        }
      })();
    }
  }, [statuspageUrl, statuspageId]);

  const handleSubscribeUser = async (data: Auth0User) => {
    try {
      setSubscribeModal(false);
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json' } };
      const body = {
        subscriber: {
          email: data.email,
        }
      }
      const response: AxiosResponse<Subscriber> = await axios.post(`${statuspageUrl}/pages/${statuspageId}/subscribers`, body, options);
      await handleUpdateUser(true);
      setSubscriber(response.data)
      toast.success('Successfully subscribed to notifications!');
    } catch (error) {
      if (error.response.data.error) {
        toast.warning(error.response.data.error);
      } else {
        toast.warning('Something went wrong with subscribing to notifications!');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleUnsubscribeUser = async () => {
    if (subscriber) {
      try {
        setUnsubscribeModal(false);
        setLoading(true);
        const options = { headers: { 'content-type': 'application/json' } };
        const body = {
          subscribers: [ subscriber.id ]
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response: AxiosResponse<Subscriber> = await axios.post(`${statuspageUrl}/pages/${statuspageId}/subscribers/unsubscribe`, body, options);
        await handleUpdateUser(false);
        setSubscriber(null);
        toast.success('Successfully unsubscribed from notifications!');
      } catch (error) {
        if (error.response.data.error) {
          toast.warning(error.response.data.error);
        } else {
          toast.warning('Something went wrong with unsubscribing from notifications!');
        }
      } finally {
        setLoading(false);
      }
    }
  }

  const handleUpdateUser = async (value: boolean) => {
    if (user) {
      try {
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        const body = {
          user_metadata: {
            subscribed: value,
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.patch(`${prefix}/users/${user.sub}`, body, options);
      } catch (error) {
        if (error.response) {
          toast.warning(error.response.data.error);
        } else {
          toast.warning('Something went wrong with updating profile!');
        }
      }
    }
  }

  const handleComponentStatus = (status: Status) => {
    const maxWeight = Math.max(...Object.values(status).map((value: StatusValue) => value.weight));
    const maxStatus = Object.values(status).find((value: StatusValue) => value.weight === maxWeight);
    maxStatus && setStatus(maxStatus);
  }

  const toggle = (incident: Incident) => {
    incident.open = !incident.open;
    setIncidents([...incidents]);
  }

  const getIcon = (status: string): ReactElement => {
    switch (status) {
      case 'operational':
        return <CheckCircleIcon fontSize='small' style={{ color: '#74c8b0', marginRight: '5px' }} />;
      case 'under_maintenance': case 'degraded_performance': case 'partial_outage':
        return <WarningIcon fontSize='small' style={{ color: '#F1C40E', marginRight: '5px' }} />;
      case 'major_outage':
        return <ErrorIcon fontSize='small' style={{ color: '#EE3B32', marginRight: '5px' }} />
      default:
        return <CheckCircleIcon fontSize='small' style={{ color: '#74c8b0', marginRight: '5px' }} />;
    }
  }

  const capitalize = (value: string) => (value.charAt(0).toUpperCase() + value.slice(1)).replace(/_/g, ' ');

  return (
    !loadingComponents && !loadingNotifications && !loadingSubscriber ? (
      <Box className='notification-wrapper'>
        <Box className='wrapper' style={{ padding: '24px' }}>
          <Box className='notification-header-wrapper'>
            <Typography variant='h5' className='header-style' style={{ paddingBottom: '10px' }}>
              Status {status ? (
                <Box component='span' style={{ color: `${status.color}`, fontSize: '16px' }}>({status.label})</Box>
              ) : null}
            </Typography>
            {components.length ? components.map((component: Component) => (
              <Box className='component-wrapper'>
                <Box>
                  <Typography variant='h5' className='header-style' style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={capitalize(component.status)} placement='bottom'>
                      {getIcon(component.status)}
                    </Tooltip>
                    {component.name}
                    {component.description && (
                      <Tooltip title={component.description} placement='right'>
                        <InfoIcon fontSize='small' style={{ marginLeft: '10px', color: '#a5a5a5' }} />
                      </Tooltip>
                    )}
                  </Typography>
                  {component.uptime_data && (
                    <Box component='span' style={{ fontSize: '14px', color: '#a5a5a5' }}>
                      {component.uptime_data.uptime_percentage} % uptime in past {differenceInDays(new Date(component.uptime_data.range_end), new Date(component.uptime_data.range_start))} days
                    </Box>
                  )}
                </Box>
              </Box>
            )) : (
              <Typography>No portal components to display!</Typography>
            )}
          </Box>
        </Box>
        <Box className='wrapper' style={{ padding: '24px' }}>
          <Box className='notifications-header-wrapper'>
            <Typography variant='h5' className='header-style'>Notifications</Typography>
            <Box>
              <Button
                color='primary'
                className='green-button'
                onClick={() => !subscriber ? setSubscribeModal(true) : setUnsubscribeModal(true)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {subscriber ? (
                <>
                  <NotificationsOffOutlinedIcon fontSize='small' />
                  Unsubscribe
                </>
              ) : (
                <>
                  <NotificationsNoneOutlinedIcon fontSize='small' />
                  Subscribe
                </>
              )}
              </Button>
            </Box>
          </Box>
          {incidents.length ? incidents.map((incident: Incident) => (
            <NotificationItem
              incident={incident}
              toggle={toggle}
              getIcon={getIcon}
            />
          )) : (
            <Typography>No notifications to display!</Typography>
          )}
        </Box>
        <ModalComponent
          open={subscribeModal}
          setOpen={setSubscribeModal}
          heading={'Subscribe to notifications'}
          buttonText={'Yes, subscribe'}
          labelText={'Are you really sure you want to subscribe to notifications?'}
          handleAccept={handleSubscribeUser}
          data={user}
        />
        <ModalComponent
          open={unsubscribeModal}
          setOpen={setUnsubscribeModal}
          heading={'Unsubscribe to notifications'}
          buttonText={'Yes, unsubscribe'}
          labelText={'Are you really sure you want to unsubscribe from notifications?'}
          handleAccept={handleUnsubscribeUser}
        />
        {loading && <Loading transparentBackground={true} />}
      </Box>
    ) : <Loading transparentBackground={true} />
  )
}

export default Notifications;
