import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dashboard, Look } from '../types/Looker.interfaces';
import { Tenant } from '../types/Tenant.interfaces';
import { Box, IconButton, ListItem, ListItemText, Tooltip, makeStyles } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { useAuth0 } from '../react-auth0-spa';

const useStyles = (inSubfolder: boolean) => makeStyles((theme: any) => ({
  nested: {
    paddingLeft: theme.spacing(inSubfolder ? 5 : 4),
    paddingRight: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    color: '#333c4d',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

interface Props {
  dashboard: Dashboard | Look;
  navClick: (width: number) => void;
  addDashboardToFavorites: (dashboard: Dashboard | Look, inSubfolder: boolean) => void;
  deleteDashboardFromFavorites: (dashboard: Dashboard | Look) => void;
  width: number;
  isMenuOpen: boolean;
  showMenuItems: boolean;
  inSubfolder: boolean;
  activeTenant: Tenant | null;
}

const SideMenuLink: React.FC<Props> = ({ dashboard, navClick, width, isMenuOpen, showMenuItems, addDashboardToFavorites, deleteDashboardFromFavorites, inSubfolder, activeTenant }) => {
  const { isClientUser } = useAuth0();
  const classes = useStyles(inSubfolder)();

  return (
    <NavLink
      key={dashboard.id}
      onClick={() => navClick(width)}
      to={!dashboard.is_look ?
        `/dashboard/${dashboard.id}${!isClientUser ? `?organization=${activeTenant?.organization_id}` : ''}`
      : `/look/${dashboard.id}${!isClientUser ? `?organization=${activeTenant?.organization_id}` : ''}`}
      className='side-link'
    >
      {isMenuOpen || showMenuItems ? (
        <ListItem button className={classes.nested} disableGutters>
          <Tooltip title={dashboard.title} enterDelay={0} placement='right-start'>
            <ListItemText className='sidebar-item-text' primary={dashboard.title} />
          </Tooltip>
          <Tooltip 
            placement='right-start'
            enterDelay={0}
            title={dashboard.content_favorite_id ? `Remove ${dashboard.title} from Favorites` : `Add ${dashboard.title} to Favorites`}
          >
            <Box className={classes.iconWrapper}>
              {dashboard.content_favorite_id ? (
                <FavoriteIcon
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    deleteDashboardFromFavorites(dashboard);
                  }}
                />
              ) : (
                <FavoriteBorderIcon
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    addDashboardToFavorites(dashboard, inSubfolder);
                  }}
                />
              )}
            </Box>
          </Tooltip>
        </ListItem>
      ) : (
        <Tooltip placement='right-start' enterDelay={0} key={`${dashboard.id}`} title={dashboard.title}>
          <ListItem button disableGutters>
            <ListItemText className='sidebar-item-text small'>
              <IconButton size='small' aria-label='dashboard'>
                <EqualizerIcon className='icon-color' />
              </IconButton>
            </ListItemText>
          </ListItem>
        </Tooltip>
      )}
    </NavLink>
  )
}

export default SideMenuLink;
