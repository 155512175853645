import React from 'react';
import loading from '../assets/loading.svg';

interface Props {
  transparentBackground?: boolean;
}

const Loading: React.FC<Props> = ({ transparentBackground }) => (
  <div className={!transparentBackground ? 'spinner' : 'loader'}>
    <img src={loading} alt='Loading' />
  </div>
);

export default Loading;
