import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExploreIcon from '@material-ui/icons/Explore';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import InfoIcon from '@material-ui/icons/Info';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Explore, FolderStructure } from '../types/Looker.interfaces';

const useStyles = makeStyles((theme: any) => ({
  nested: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface Props {
  isMenuOpen: boolean;
  showMenuItems: boolean;
  item: FolderStructure<Explore>;
  toggleExplore: (item: FolderStructure<Explore>) => void;
  navClick: (width: number) => void;
  width: number;
}

const ExploreMenuItem: React.FC<Props> = ({
  isMenuOpen,
  showMenuItems,
  item,
  navClick,
  toggleExplore,
  width,
}) => {
  const classes = useStyles();

	return (
		<>
      <ListItem button onClick={() => toggleExplore(item)} className='side-link' disableGutters>
        {isMenuOpen || showMenuItems ? (
          <>
            <ListItemIcon className='icon'>
              {item.open ? (
                <ExploreOutlinedIcon style={{ marginLeft: '8px', minWidth: '45px' }} />
              ) : (
                <ExploreIcon style={{ marginLeft: '8px', minWidth: '45px' }} />
              )}
            </ListItemIcon>
            <ListItemText primary={isMenuOpen || showMenuItems ? item.name : ''} />
            {item.open ? (
              <ExpandLess style={{ fontSize: 16, marginRight: '3px' }} />
            ) : (
              <ExpandMore style={{ fontSize: 16, marginRight: '3px' }} />
            )}
          </>
        ) : (
          <>
            <Tooltip placement='right-start' enterDelay={0} key={`folder${item.id}`} title={item.name}>
              <ListItemText className='sidebar-item-text small'>
                <IconButton size='small'>
                  {item.open ? <ExploreOutlinedIcon /> : <ExploreIcon />}
                </IconButton>
              </ListItemText>
            </Tooltip>
          </>
        )}
      </ListItem>
      <Collapse in={item.open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding dense>
          {item.dashboards.map((explore: Explore, index: number) => (
            <NavLink
              key={explore.name}
              onClick={() => navClick(width)}
              to={`/explore/${explore.group_name.replace(/_/g, '-')}/${explore.name.replace(/_/g, '-')}`}
              className='side-link'
            >
              {isMenuOpen || showMenuItems ? (
                <ListItem button className={classes.nested} disableGutters>
                  <Tooltip title={explore.label} enterDelay={0} placement='right-start'>
                    <ListItemText className='sidebar-item-text' primary={explore.label} />
                  </Tooltip>
                  {explore.description ? (
                    <Tooltip placement='right-start' enterDelay={0} key={`${index}`} title={explore.description}>
                      <InfoIcon style={{ color: '#a5a5a5', fontSize: '16px' }} />
                    </Tooltip>
                    ) : null
                  }
                </ListItem>
              ) : (
                <Tooltip placement='right-start' enterDelay={0} key={`${index}`} title={explore.label}>
                  <ListItem button disableGutters>
                    <ListItemText className='sidebar-item-text small'>
                      <IconButton size='small' aria-label='dashboard'>
                        <EqualizerIcon className='icon-color' />
                      </IconButton>
                    </ListItemText>
                  </ListItem>
                </Tooltip>
              )}
            </NavLink>
          ))}
        </List>
      </Collapse>
    </>
	)
}

export default ExploreMenuItem;
