import React from 'react';
import { List, Collapse } from '@material-ui/core';
import { FolderStructure, Dashboard, Look } from '../types/Looker.interfaces';
import FolderTitle from './FolderTitle';
import SideMenuLink from './SideMenuLink';
import { Tenant } from '../types/Tenant.interfaces';

interface Props {
  isMenuOpen: boolean;
  showMenuItems: boolean;
  menuItem: FolderStructure<Dashboard | Look>;
  toggleItem: (item: FolderStructure<Dashboard | Look>) => void;
  navClick: (width: number) => void;
  addDashboardToFavorites: (dashboard: Dashboard | Look, inSubfolder: boolean) => void;
  deleteDashboardFromFavorites: (dashboard: Dashboard | Look) => void;
  width: number;
  toggleSubfolder: (folderId: string, parentFolderId: string) => void;
  activeTenant: Tenant | null;
}

const DashboardMenuItem: React.FC<Props> = ({
  isMenuOpen,
  showMenuItems,
  toggleItem,
  menuItem,
  navClick,
  addDashboardToFavorites,
  deleteDashboardFromFavorites,
  width,
  toggleSubfolder,
  activeTenant,
}) => {
  return (
    <>
      <FolderTitle
        isMenuOpen={isMenuOpen}
        showMenuItems={showMenuItems}
        folder={menuItem}
        isSubfolder={false}
        toggleFolder={toggleItem}
      />
      <Collapse in={menuItem.open} timeout='auto' unmountOnExit>
        {menuItem.subfolders && menuItem.subfolders.length ? (
          <List component='div' disablePadding dense>
            {menuItem.subfolders.map((folder: FolderStructure<Dashboard | Look>) => (
              <>
                <FolderTitle
                  isMenuOpen={isMenuOpen}
                  showMenuItems={showMenuItems}
                  folder={folder}
                  isSubfolder={true}
                  toggleSubfolder={toggleSubfolder}
                  parentFolderId={menuItem.id}
                />
                <Collapse in={folder.open} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding dense>
                    {folder.dashboards.map((dashboard: Dashboard | Look) => (
                      <SideMenuLink
                        dashboard={dashboard}
                        isMenuOpen={isMenuOpen}
                        showMenuItems={showMenuItems}
                        addDashboardToFavorites={addDashboardToFavorites}
                        deleteDashboardFromFavorites={deleteDashboardFromFavorites}
                        navClick={navClick}
                        width={width}
                        inSubfolder={true}
                        activeTenant={activeTenant}
                      />
                    ))}
                  </List>
                </Collapse>
              </>
            ))}
          </List>
        ) : null}
        <List component='div' disablePadding dense>
          {menuItem.dashboards.map((dashboard: Dashboard | Look) => (
            <SideMenuLink
              dashboard={dashboard}
              isMenuOpen={isMenuOpen}
              showMenuItems={showMenuItems}
              addDashboardToFavorites={addDashboardToFavorites}
              deleteDashboardFromFavorites={deleteDashboardFromFavorites}
              navClick={navClick}
              width={width}
              inSubfolder={false}
              activeTenant={activeTenant}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default DashboardMenuItem;
