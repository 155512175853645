import React, { useRef, useState, useEffect } from 'react';
import { useAuth0 } from '../react-auth0-spa';
import axios, { AxiosResponse } from 'axios';
import MaterialTable, { MTableToolbar, Column, MTableAction } from 'material-table';
import { toast } from 'react-toastify';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { format, isAfter, parseISO } from 'date-fns';
import Loading from '../components/Loading';
import ModalComponent from '../components/Modal';
import OrganizationSelect from '../components/OrganizationSelect';
import { GreenSwitch } from '../components/GreenSwitch';
import useWindowSize from '../utils/useWindowSize';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Invitation } from '../types/Auth0.interfaces';
import { TenantGroup, Tenant } from '../types/Tenant.interfaces';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme: any) => ({
  highlight: {
    backgroundColor: '#74c8b0 !important',
    '& *': {
      color: 'rgba(255, 255, 255, 1)',
    }
  },
}));

interface Props {
  activeTenant: Tenant | null;
  isSevenstepOrganization: boolean;
  adminUserTenants: Tenant[];
  isClientPortal: boolean;
  prefix: string;
}

const PendingInvitations: React.FC<Props> = ({ activeTenant, isSevenstepOrganization, adminUserTenants, isClientPortal, prefix }) => {
  const { user, clientId, organizationId, isClientUser } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const [toolbarHeight, setToolbarHeight] = useState<number>(0);
  const [resendModal, setResendModal] = useState<boolean>(false);
  const [revokeModal, setRevokeModal] = useState<boolean>(false);
  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const [activeOrganization, setActiveOrganization] = useState<Tenant | null>(null);
  const [pageOrganizationId, setPageOrganizationId] = useState<string>('');
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [userType, setUserType] = useState<boolean>(false);
  const [multipleInvitationsModal, setMultipleInvitationsModal] = useState<boolean>(false);
  const [invitationsToRevoke, setInvitationsToRevoke] = useState<Invitation[]>([]);
  const { height } = useWindowSize();
  const tableRef = useRef<any>();
  const toolbarRef = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    setUserType(!!isClientPortal);
  }, [isClientPortal]);

  useEffect(() => {
    setToolbarHeight(toolbarRef.current.clientHeight)
  }, []);

  useEffect(() => {
    if (activeTenant) {
      setActiveOrganization(!isClientPortal && userType ? {
        ...activeTenant,
        groups: activeTenant.otherGroups,
        otherGroups: activeTenant.groups,
      } : activeTenant);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTenant, isClientPortal]);

  useEffect(() => {
    if (activeOrganization) {
      if (isSevenstepOrganization) {
        setPageOrganizationId(activeOrganization.organization_id);
      } else {
        setPageOrganizationId(organizationId ? organizationId : '');
      }
    }
  }, [activeOrganization, organizationId, isSevenstepOrganization]);

  useEffect(() => {
    if (pageOrganizationId) {
      handleGetInvitations();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOrganizationId, userType]);

  const handleGetInvitations = async () => {
    try {
      setIsLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const response: AxiosResponse<Invitation[]> = await axios.get(`${prefix}/organizations/${pageOrganizationId}/invitations?page=0&per_page=100`, options);
      const results = response.data.filter((invitation: Invitation) => invitation.user_metadata.is_client_user === userType && invitation.user_metadata.organizations.length === 1);
      setInvitations(results);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning('Something went wrong with loading invitations!');
      }
      setInvitations([]);
    } finally {
      setIsLoading(false);
    }
  }

  const sendInvitation = async () => {
    if (invitation) {
      setResendModal(false);
      try {
        setLoading(true);
        const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
        const body = {
          inviter: invitation.inviter,
          invitee: invitation.invitee,
          client_id: clientId,
          ttl_sec: 2592000,
          user_metadata: invitation.user_metadata,
          app_metadata: invitation.app_metadata,
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const emailResponse: AxiosResponse<Invitation> = await axios.post(`${prefix}/organizations/${pageOrganizationId}/invitations`, body, options);
        toast.success(`Successfully sent invitation to ${invitation.invitee.email}!`);
        handleGetInvitations();
      } catch (error) {
        toast.warning(`Invitation wasn't sent to ${invitation.invitee.email}!`);
      } finally {
        setLoading(false);
        setInvitation(null);
      }
    }
  }

  const revokeInvitation = async (invitation: Invitation, reload: boolean | undefined) => {
    try {
      setRevokeModal(false);
      setLoading(true);
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<null> = await axios.delete(`${prefix}/organizations/${pageOrganizationId}/invitations/${invitation.id}`, options);
      if (reload) {
        toast.success(`Successfully deleted invitation for ${invitation.invitee.email} from ${isSevenstepOrganization ? activeOrganization?.tenant : ''} organization!`);
        handleGetInvitations();
        setInvitation(null);
      }
    } catch (error) {
      toast.warning(`Something went wrong with deleting ${invitation.invitee.email} invitation from ${isSevenstepOrganization ? activeOrganization?.tenant : ''} organization!`);
    } finally {
      setLoading(false);
    }
  }

  const handleDeleteMultipleInvitations = async (data: Invitation[]) => {
    setMultipleInvitationsModal(false);
    for (let i = 0; i < data.length; i++) {
      await revokeInvitation(data[i], undefined);
    }
    toast.success(`Successfully deleted selected invitations!`);
    handleGetInvitations();
  }

  const handleUsersTypeChanged = () => {
    setUserType(!userType);
    if (activeOrganization && activeOrganization.groups.length && activeOrganization.otherGroups.length) {
      setUserType(!userType);
      const groupType = activeOrganization.groups[0].client_group;
      if (groupType !== !userType && activeOrganization.otherGroups) {
        const org = {
          ...activeOrganization,
          groups: activeOrganization.otherGroups,
          otherGroups: activeOrganization.groups,
        }
        setActiveOrganization(org);
      }
    }
  }

  const exportTableData = (columns: Column<Invitation>[], data: Invitation[]) => {
    const { exportDelimiter } = tableRef.current.props.options;
    const delimiter = exportDelimiter ? exportDelimiter : ',';
    const headerRow = columns.filter((col: Column<Invitation>) => !col.hidden && !col.field?.includes('admin')).map((col: Column<Invitation>) => col.title);
    const dataRows = data.map((invitation: Invitation) => [
      invitation.invitee.email,
      isAfter(new Date(Date.now()), new Date(invitation.expires_at)) ? 'Expired' : 'Pending',
      format(parseISO(invitation.created_at), 'MM/dd/yyyy'),
      format(parseISO(invitation.expires_at), 'MM/dd/yyyy'),
      activeOrganization && activeOrganization.groups.filter((group: TenantGroup) => invitation.app_metadata.group_ids.includes(group.group_id)).map((group: TenantGroup) => group.label).join('; '),
      invitation.inviter.name,
    ]);
    const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join('\n');
    downloadCsv(csvContent, `${activeOrganization?.tenant}_${!isClientUser ? 'client_' : 'internal_'}pending_invitations`);
  }

  const downloadCsv = (data: any, fileName: string) => {
    const finalFileName = fileName.endsWith('.csv') ? fileName : `${fileName}.csv`;
    const downloadElement = document.createElement('a');
    downloadElement.href = URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
    downloadElement.setAttribute('download', finalFileName);
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
  }

  const columns: Column<Invitation>[] = [
    {
      title: 'Email',
      field: 'invitee.email',
      render: (rowData: Invitation) => <span>{rowData.invitee.email} </span>
    },
    {
      title: 'Status',
      field: 'expires_at',
      render: (rowData: Invitation) => {
        const isExpired = isAfter(new Date(Date.now()), new Date(rowData.expires_at));
        return (
          <span>
            <FiberManualRecordIcon style={{ fontSize: '12px', color: isExpired ? '#EE3B32' : '#74c8b0' }} />
            {' '}
            {isExpired ? 'Expired' : 'Pending'}
          </span>
        )
      }
    },
    {
      title: 'Sent',
      field: 'created_at',
      render: (rowData: Invitation) => <span>{format(parseISO(rowData.created_at), 'MM/dd/yyyy hh:mm a')}</span>,
      cellStyle: {
        minWidth: '200px'
      }
    },
    {
      title: 'Expires At',
      field: 'expires_at',
      render: (rowData: Invitation) => <span>{format(parseISO(rowData.expires_at), 'MM/dd/yyyy hh:mm a')}</span>,
      cellStyle: {
        minWidth: '200px'
      },
    },
    {
      title: 'Roles',
      field: 'app_metadata.group_ids',
      render: (rowData: Invitation) => (
        <span>
          {activeOrganization && activeOrganization.groups
            .filter((group: TenantGroup) => rowData.app_metadata.group_ids && rowData.app_metadata.group_ids.includes(group.group_id))
            .map((group: TenantGroup) => group.label).join(', ')}
        </span>
      ),
      cellStyle: {
        minWidth: '200px'
      }
    },
    {
      title: 'Sender',
      field: 'inviter.name',
      render: (rowData: Invitation) => <span>{rowData.inviter.name}</span>
    }
  ];

  return (
    <Box>
      <MaterialTable
        tableRef={tableRef}
        title={
          <div className='table-header-container'>
            <Tooltip title={`Not accepted invitations to ${activeOrganization?.tenant}`} placement='bottom'>
              <InfoOutlinedIcon fontSize='small' style={{ marginRight: '10px' }} />
            </Tooltip>
            <h6 className='table-header'>Pending Invitations{isSevenstepOrganization ? ' In' : ''}</h6>
            {isSevenstepOrganization && (
              <div>
                <OrganizationSelect
                  activeOrganization={activeOrganization}
                  setActiveOrganization={setActiveOrganization}
                  showLabel={false}
                  adminUserTenants={adminUserTenants}
                  userType={userType}
                  setType={setUserType}
                  changeParam={false}
                />
              </div>
            )}
          </div>
        }
        columns={columns}
        data={invitations}
        isLoading={isLoading}
        actions={[
          {
            icon: 'send',
            tooltip: 'Resend invitation',
            onClick: (event: React.MouseEvent, rowData: any) => {
              setResendModal(true);
              setInvitation(rowData);
            },
            position: 'row',
          },
          {
            icon: 'delete',
            tooltip: 'Revoke invitation',
            onClick: (event: React.MouseEvent, rowData: any) => {
              setRevokeModal(true);
              setInvitation(rowData);
            },
            position: 'row',
          },
          {
            icon: () => <GreenSwitch />,
            tooltip: `Show ${userType ? 'internal' : 'client'} users invitations`,
            onClick: (event: React.MouseEvent, rowData: any) => handleUsersTypeChanged(),
            position: 'toolbar',
            hidden: !!isClientUser || isClientPortal,
          },
          {
            icon: 'delete',
            tooltip: 'Revoke selected invitations',
            onClick: (event: React.MouseEvent, rowData: any) => {
              setMultipleInvitationsModal(true);
              setInvitationsToRevoke(rowData);
            },
            position: 'toolbarOnSelect',
          }
        ]}
        options={{
          search: false,
          sorting: false,
          headerStyle: {
            position: 'sticky',
            top: 0,
            height: 60,
          },
          maxBodyHeight: height - (103 + toolbarHeight),
          minBodyHeight: height - (103 + toolbarHeight),
          emptyRowsWhenPaging: false,
          pageSize: pageSize,
          selection: true,
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportAllData: true,
          exportCsv: (columns: Column<Invitation>[], data: Invitation[]) => exportTableData(columns, data),
        }}
        onChangeRowsPerPage={(size: number) => setPageSize(size)}
        components={{
          Toolbar: (props: any) => <div ref={toolbarRef} style={{ width: '100%' }}><MTableToolbar {...props} classes={{ highlight: classes.highlight }} /></div>,
          OverlayLoading: (props: any) => <Loading transparentBackground={true} />,
          Action: (props: any) => {
            if (props.action.tooltip.toLowerCase().includes('show') && !props.action.hidden) {
              const disabled = !activeOrganization?.otherGroups.length;
              return (
                <Tooltip
                  title={disabled ? `${activeOrganization && activeOrganization.tenant} organization doesn't have client users!` : props.action.tooltip}
                  placement='bottom'
                >
                  <Box component='span'>
                    <GreenSwitch
                      {...props}
                      checked={userType}
                      onClick={props.action.onClick}
                      disabled={disabled}
                    />
                  </Box>
                </Tooltip>
              )
            } else {
              return <MTableAction {...props} />;
            }
          }
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No invitations to display!'
          },
          toolbar: {
            nRowsSelected: `{0} invitation(s) selected`
          }
        }}
      />
      {loading && <Loading transparentBackground={true} />}
      <ModalComponent
        open={resendModal}
        setOpen={setResendModal}
        heading={'Resend invitation'}
        labelText={`Are you really sure you want to resend invitation to ${invitation && invitation.invitee.email}? This cannot be undone!`}
        buttonText={'Yes, send it'}
        handleAccept={sendInvitation}
      />
      <ModalComponent
        open={revokeModal}
        setOpen={setRevokeModal}
        heading={'Revoke invitation'}
        labelText={`Are you really sure you want to revoke invitation for ${invitation && invitation.invitee.email}? This cannot be undone!`}
        buttonText={'Yes, revoke it'}
        handleAccept={revokeInvitation}
        data={invitation}
        reload={true}
      />
      <ModalComponent
        open={multipleInvitationsModal}
        setOpen={setMultipleInvitationsModal}
        heading={'Revoke selected invitations'}
        labelText={`Are you really sure you want to revoke selected invitations? This cannot be undone!`}
        buttonText={'Yes, revoke all'}
        handleAccept={handleDeleteMultipleInvitations}
        data={invitationsToRevoke}
      />
    </Box>
  )
}

export default PendingInvitations;
