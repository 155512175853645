import React, { useEffect } from 'react';
import Loading from '../components/Loading';
import { useAuth0 } from '../react-auth0-spa';
import { ParamTypes, Tenant, TenantGroup } from '../types/Tenant.interfaces';
import { IUser } from '../types/Auth0.interfaces';
import { LookerEmbedExplore, LookerEmbedSDK } from '../utils/embed_sdk';
import { useParams } from 'react-router-dom';
import history from '../utils/history';
import { toast } from 'react-toastify';

interface Props {
  tenant: Tenant | null;
  isExploreAvailable: boolean | null;
  lookerHost: string;
}

const Explore: React.FC<Props> = ({ tenant, isExploreAvailable, lookerHost }) => {
  const { loading, user, userMetadata, appMetadata } = useAuth0();
  const { id, name } = useParams<ParamTypes>();
  LookerEmbedSDK.init(lookerHost, `/.netlify/functions/auth_utils`);

  const destroyExisting = (className: string) => {
    const iframe = document.querySelector(`.${className}`);
    if (iframe && iframe.parentNode !== null) {
      iframe.parentNode.removeChild(iframe);
    }
  };

  useEffect(() => {
    if (isExploreAvailable === false) {
      history.push('/');
      toast.info('You don\'t have access to Explore option!');
    }
  }, [isExploreAvailable]);

  useEffect(() => {
    if (id && user && tenant) {
      const getUser = () => {
        return {
          ...user[appMetadata as keyof IUser],
          external_user_id: user.sub,
          ...user[userMetadata as keyof IUser],
          group_ids: tenant.groups.map((x: TenantGroup) => x.group_id),
        };
      };
      const className = 'looker-embed';
      destroyExisting(className);
      LookerEmbedSDK.createExploreWithId(`${name.replace(/-/g, '_')}::${id.replace(/-/g, '_')}`)
        .withClassName(className)
        .build()
        .connect(getUser(), '#dash-wrapper')
        .then((res: { connection: Promise<LookerEmbedExplore>, url: string | null | undefined }) => {
          res.connection.then((explore: LookerEmbedExplore) => { });
        })
        .catch((error: Error) => {
          toast.warning('Connection error!');
        })
    }
  }, [id, user, tenant, userMetadata, appMetadata, name]);

  return (
    <>
      {loading || !user ? <Loading /> : <div id='dash-wrapper'></div>}
    </>
  );
}

export default Explore;
