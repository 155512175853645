import React, { useState, useEffect, useRef } from 'react';
import Loading from '../components/Loading';
import FileReader from '../components/FileReader';
import GroupSelect from '../components/GroupSelect';
import InvitationWidget from '../components/InvitationWidget';
import { Typography, Box, TextField, Tooltip, InputAdornment, makeStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import MaterialTable, { Column, EditComponentProps, MTableToolbar } from 'material-table';
import { Button } from 'reactstrap';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useAuth0 } from '../react-auth0-spa';
import { InvitedUser, Tenant, TenantGroup } from '../types/Tenant.interfaces';
import { Prompt } from 'react-router-dom';
import useWindowSize from '../utils/useWindowSize';
import { IUser, Invitation, Auth0User } from '../types/Auth0.interfaces';
import OrganizationSelect from '../components/OrganizationSelect';
import UserType from '../components/UserType';

const useStyles = makeStyles((theme: any) => ({
  highlight: {
    backgroundColor: '#74c8b0 !important',
    borderRadius: '20px 20px 0 0',
    '& *': {
      color: 'rgba(255, 255, 255, 1)',
    }
  }
}));

interface Props {
  colors: string[];
  activeTenant: Tenant | null;
  isSevenstepOrganization: boolean;
  adminUserTenants: Tenant[];
  isClientPortal: boolean;
  prefix: string;
}

const UserInvitations: React.FC<Props> = ({ colors, activeTenant, isSevenstepOrganization, adminUserTenants, isClientPortal, prefix }) => {
  const { user, clientId, userMetadata, isClientUser } = useAuth0();
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<InvitedUser[] | null>();
  const [isCsvValid, setIsCsvValid] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [areEmailsUnique, setAreEmailsUnique] = useState<boolean>(true);
  const [reset, setReset] = useState<boolean>(false);
  const [invitationData, setInvitationData] = useState<InvitedUser[]>([]);
  const [availableGroups, setAvailableGroups] = useState<number[]>([]);
  const [globalGroups, setGlobalGroups] = useState<number[]>([]);
  const [activeOrganization, setActiveOrganization] = useState<Tenant | null>(null);
  const [globalOrganization, setGlobalOrganization] = useState<Tenant | null>(null);
  const [singleInvitationOrganization, setSingleInvitationOrganization] = useState<Tenant | null>(null);
  const [userType, setUserType] = useState<boolean>(false);
  const [multipleUserType, setMultipleUserType] = useState<boolean>(false);
  const [widgetUserType, setWidgetUserType] = useState<boolean>(false);
  const [multiInvitations, setMultiInvitations] = useState<boolean>(false);
  const tableRef = useRef<any>();
  const classes = useStyles();
  const { width } = useWindowSize();

  useEffect(() => {
    if (isClientUser !== undefined && isClientUser !== null && !isClientPortal) {
      setUserType(isClientUser);
      setMultipleUserType(isClientUser);
    } else {
      setUserType(isClientPortal);
      setMultipleUserType(isClientPortal);
    }
  }, [isClientUser, isClientPortal]);

  useEffect(() => {
    if (activeTenant) {
      setActiveOrganization(activeTenant);
      setGlobalOrganization(activeTenant);
      setSingleInvitationOrganization(activeTenant);
    }
  }, [activeTenant]);

  useEffect(() => {
    if (previewData) {
      previewData.forEach((row: InvitedUser) => {
        row.groups = globalGroups;
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalGroups]);

  useEffect(() => {
    if (loading) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [loading]);

  useEffect(() => {
    if (previewData) {
      setInvitationData([]);
      setShowPreview(true);
      setReset(false);
      const isEmailUnique = isUnique(previewData);
      if (!isEmailUnique) {
        setAreEmailsUnique(false);
        return;
      } else {
        setAreEmailsUnique(true);
      }
      for (let i = 0; i < previewData.length; i += 1) {
        const isEmailValid = validateEmail(previewData[i].email);
        if (!isEmailValid || !previewData[i].first_name || !previewData[i].last_name) {
          setIsCsvValid(false);
          toast.warning('CSV data is not valid!');
          return;
        }
      }
      setIsCsvValid(true);
    } else {
      setIsCsvValid(false);
      setShowPreview(false);
      setAreEmailsUnique(true);
      setReset(false);
    }
  }, [previewData]);

  const isUnique = (array: InvitedUser[]): boolean => {
    let tmpArray = [];
    const filteredArray = array.filter((item: InvitedUser) => item.email);
    for (let obj in filteredArray) {
      if (tmpArray.indexOf(filteredArray[obj].email) < 0) { 
        tmpArray.push(filteredArray[obj].email);
      } else {
        return false;
      }
    }
    return true;
  }

  const validateEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleCsvInvitations = async (tenant: Tenant) => {
    if (!tableRef.current.state.lastEditingRow && !tableRef.current.state.showAddRow) {
      setInvitationData([]);
      if (previewData) {
        const length = previewData.length;
        for (let i = 0; i < length; i += 1) {
          await sendInvitation(previewData[i].email, previewData[i].first_name, previewData[i].last_name, previewData[i].groups || [], tenant, multipleUserType, false);
        }
        setReset(true);
        setGlobalGroups([]);
      }
    } else {
      toast.info('Please save changes to the CSV file!');
    }
  }

  const addUsersToOrganization = async (orgId: string, userIdsToAdd: string): Promise<boolean> => {
    try {
      const data = {
        members: [ userIdsToAdd ],
      }
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await axios.post(`${prefix}/organizations/${orgId}/members`, data, options);
      return true;
    } catch (error) {
      return false;
    }
  }

  const updateUser = async (account: Auth0User, tenant: Tenant, groupIds: number[]): Promise<boolean> => {
    try {
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const data = {
        user_metadata: {
          organizations: [...account.user_metadata.organizations.filter((org: string) => org !== tenant.name), tenant.name ],
        },
        app_metadata: {
          group_ids: [...account.app_metadata.group_ids.filter((id: number) => !groupIds.includes(id)), ...groupIds],
        },
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await axios.patch(`${prefix}/users/${account.user_id}`, data, options);
      return true;
    } catch (error) {
      return false;
    }
  }

  const sendInvitation = async (emailValue: string, firstName: string, lastName: string, groupIds: number[], tenant: Tenant, typeOfUser: boolean, singleInv: boolean) => {
    const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
    try {
      setLoading(true);
      const emailDomain = emailValue.split('@')[1];
      const domainCheck = tenant.allowedEmailDomains.find((domain: string) => domain === emailDomain.toLowerCase());
      if (!domainCheck) {
        toast.error('User is not allowed to register!');
        return;
      }
      let account = null;
      if (!isClientUser) {
        account = await searchUser(emailValue);
      }
      if (account) {
        const addedUsers = await addUsersToOrganization(tenant.organization_id, account.user_id);
        if (!addedUsers) {
          toast.error('Something went wrong with adding users to organization!');
          return;
        }
        const updatedUser = await updateUser(account, tenant, groupIds);
        if (!updatedUser) {
          toast.error('Something went wrong with updating user roles!');
          return;
        }
      }
      const randomColor = colors[Math.floor(Math.random() * 5)];
      const body = {
        inviter: {
          name: user && `${user[userMetadata as keyof IUser].first_name} ${user[userMetadata as keyof IUser].last_name}`
        },
        invitee: { email: emailValue.toLowerCase() },
        client_id: clientId,
        user_metadata: {
          first_name: firstName,
          last_name: lastName,
          is_client_user: typeOfUser,
          organizations: !account ? [ tenant.name ] : [...account.user_metadata.organizations.filter((org: string) => org !== tenant.name), tenant.name ],
          color: randomColor,
        },
        app_metadata: {
          group_ids: !account ? groupIds : [...account.app_metadata.group_ids.filter((id: number) => !groupIds.includes(id)), ...groupIds],
        },
        ttl_sec: 2592000,
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response: AxiosResponse<Invitation> = await axios.post(`${prefix}/organizations/${tenant.organization_id}/invitations`, body, options);
      setInvitationData((prevState) => [...prevState, {
        email: emailValue,
        first_name: firstName,
        last_name: lastName,
        email_sent: 'Success',
        groups: groupIds,
      }]);
      if (singleInv) {
        setEmail('');
        setFirstName('');
        setLastName('');
        setAvailableGroups([]);
      }
      toast.success(`Successfully sent invitation to ${emailValue}!`);
    } catch (error) {
      if (error.response) {
        toast.warning(error.response.data.message);
      } else {
        toast.warning(`Something went wrong with sending invitation to ${emailValue}!`);
      }
      setInvitationData((prevState) => [...prevState, {
        email: emailValue,
        first_name: firstName,
        last_name: lastName,
        email_sent: error.response ? error.response.data.message : 'Error',
        groups: groupIds,
      }]);
    } finally {
      setLoading(false);
    }
  }

  const searchUser = async (email: string): Promise<Auth0User | null> => {
    try {
      const options = { headers: { 'content-type': 'application/json', user: JSON.stringify(user) } };
      const response: AxiosResponse<Auth0User[]> = await axios.get(`${prefix}/users?search_engine=v3&q=email:${email.toLowerCase()}`, options);
      if (response.data.length) {
        return response.data[0];
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  const handleRemoveUsersFromInvitations = (users: InvitedUser[]) => {
    const newData = previewData?.filter((account: InvitedUser) => !users.find((user: InvitedUser) => user.email === account.email));
    setPreviewData(newData);
  }

  const handleTypeChange = (client: Tenant, setNewTenant: (value: Tenant) => void) => {
    if (client.otherGroups) {
      const tenant = {
        ...client,
        groups: client.otherGroups,
        otherGroups: client.groups,
      }
      setNewTenant(tenant);
    }
  }

  const columns: Column<InvitedUser>[] = [
    {
      title: 'Email',
      field: 'email',
      render: (rowData: InvitedUser) => !rowData.email ? (
        <span style={{ color: '#f44336' }}>Required!</span>
      ) : (
        <span style={!validateEmail(rowData.email) ? { color: '#f44336' } : {}}>{rowData.email}</span>
      ),
      editComponent: (tableData: EditComponentProps<InvitedUser>) => (
        <TextField fullWidth value={tableData.rowData.email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => tableData.onChange(e.target.value)} />
      )
    },
    {
      title: 'First name',
      field: 'first_name',
      render: (rowData: InvitedUser) => !rowData.first_name ? <span style={{ color: '#f44336' }}>Required!</span> : <span>{rowData.first_name}</span>,
      editComponent: (tableData: EditComponentProps<InvitedUser>) => (
        <TextField fullWidth value={tableData.rowData.first_name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => tableData.onChange(e.target.value)} />
      )
    },
    {
      title: 'Last name',
      field: 'last_name',
      render: (rowData: InvitedUser) => !rowData.last_name ? <span style={{ color: '#f44336' }}>Required!</span> : <span>{rowData.last_name}</span>,
      editComponent: (tableData: EditComponentProps<InvitedUser>) => (
        <TextField fullWidth value={tableData.rowData.last_name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => tableData.onChange(e.target.value)} />
      )
    }, 
    {
      title: 'Roles',
      field: 'groups',
      render: (rowData: InvitedUser) => (
        <>
          {(multiInvitations ? globalOrganization : singleInvitationOrganization)?.groups
            .filter((group: TenantGroup) => rowData.groups && rowData.groups.includes(group.group_id))
            .map((group: TenantGroup) => group.label).join(', ')}
        </>
      ),
      editComponent: (tableData: EditComponentProps<InvitedUser>) => (
        <GroupSelect
          showLabel={false}
          groups={tableData.rowData.groups || []}
          availableTenantGroups={globalOrganization ? globalOrganization.groups : []}
          tableData={tableData}
          showOtherGroups={false}
        />
      ),
      sorting: false,
      cellStyle: {
        minWidth: '200px'
      }
    }
  ];

  return (
    <Box className='invitation-wrapper'>
      <Prompt 
        when={loading}
        message={location =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />
      <Box className='profile'>
        {!isClientUser && isSevenstepOrganization && (
          <Box className='invitation-container' style={{ minHeight: isSevenstepOrganization ? '579px' : '531px' }}>
            <InvitationWidget
              isSevenstepOrganization={isSevenstepOrganization}
              invitationOrganization={activeOrganization}
              setInvitationOrganization={setActiveOrganization}
              adminUserTenants={adminUserTenants}
              setLoading={setLoading}
              cancelButton={false}
              userType={widgetUserType}
              setUserType={setWidgetUserType}
              prefix={prefix}
            />
          </Box>
        )}
        <Box className='invitation-container' style={{ minHeight: isSevenstepOrganization ? '579px' : '531px' }}>
          <Box>
            <Typography variant='h4'>Single Invitation</Typography>
          </Box>
          <Box>
            <Box style={{ paddingBottom: '20px' }}>
              {!isClientUser ? (
                <UserType
                  userType={userType}
                  setUserType={setUserType}
                  label={'user'}
                  tenant={singleInvitationOrganization}
                  handleTypeChange={handleTypeChange}
                  setTenant={setSingleInvitationOrganization}
                  setGroups={setAvailableGroups}
                />
              ) : null}
              {isSevenstepOrganization ? (
                <Box>
                  <OrganizationSelect
                    activeOrganization={singleInvitationOrganization}
                    setActiveOrganization={setSingleInvitationOrganization}
                    showLabel={true}
                    setGroups={setAvailableGroups}
                    adminUserTenants={adminUserTenants}
                    userType={userType}
                    setType={setUserType}
                    changeParam={false}
                  />
                </Box>
              ) : null}
              <Box>
                <TextField
                  label='Email'
                  className='full-width'
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                  InputProps={{
                    endAdornment: width >= 768 && (
                      <InputAdornment position='end'>
                        <Tooltip placement='right' title='Email must be valid email address.'>
                          <InfoIcon style={{ color: '#a5a5a5', fontSize: '20px' }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <TextField
                  label='First name'
                  className='full-width'
                  value={firstName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
                  InputProps={{
                    endAdornment: width >= 768 && (
                      <InputAdornment position='end'>
                        <Tooltip placement='right' title={'First name can\'t be blank.'}>
                          <InfoIcon style={{ color: '#a5a5a5', fontSize: '20px' }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <TextField
                  label='Last name'
                  className='full-width'
                  value={lastName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value)}
                  InputProps={{
                    endAdornment: width >= 768 && (
                      <InputAdornment position='end'>
                        <Tooltip placement='right' title={'Last name can\'t be blank.'}>
                          <InfoIcon style={{ color: '#a5a5a5', fontSize: '20px' }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <GroupSelect
                  availableTenantGroups={singleInvitationOrganization ? singleInvitationOrganization.groups : []}
                  setGroups={setAvailableGroups}
                  groups={availableGroups}
                  showLabel={true}
                  label={'Roles'}
                  showOtherGroups={false}
                />
              </Box>
            </Box>
            <Box style={{ padding: '20px 0 10px'}}>
              <Button
                color='primary'
                className='green-button'
                disabled={validateEmail(email) && firstName && lastName ? false : true}
                onClick={() => {
                  setMultiInvitations(false);
                  singleInvitationOrganization && sendInvitation(email, firstName, lastName, availableGroups, singleInvitationOrganization, userType, true);
                }}
              >
                Send Invitation
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className='invitation-container' style={{ minHeight: isSevenstepOrganization ? '579px' : '531px' }}>
          <Box>
            <Box>
              <Typography variant='h4'>
                Multi Invitations
              </Typography>
            </Box>
            <Box style={{ padding: '10px 0', textAlign: 'left' }}>
              <Typography style={{ fontSize: '16px' }}>Rules to upload CSV file:</Typography>
              <Typography style={{ fontSize: '12px' }}>1. Write in A column email, first_name and last_name, order is not important.</Typography>
              <Typography style={{ fontSize: '12px' }}>2. First name and last name can't be blank, email must be valid email address.</Typography>
              <Typography style={{ fontSize: '12px' }}>3. Select groups that are going to be applied to all invitations.</Typography>
            </Box>
            {!isClientUser ? (
              <UserType
                userType={multipleUserType}
                setUserType={setMultipleUserType}
                label={'users'}
                tenant={globalOrganization}
                handleTypeChange={handleTypeChange}
                setTenant={setGlobalOrganization}
                setGroups={setGlobalGroups}
              />
            ) : null}
            {isSevenstepOrganization ? (
              <Box>
                <OrganizationSelect
                  activeOrganization={globalOrganization}
                  setActiveOrganization={setGlobalOrganization}
                  showLabel={true}
                  setGroups={setGlobalGroups}
                  adminUserTenants={adminUserTenants}
                  userType={multipleUserType}
                  setType={setMultipleUserType}
                  changeParam={false}
                />
              </Box>
            ) : null}
            <Box>
              <GroupSelect
                availableTenantGroups={globalOrganization ? globalOrganization.groups : []}
                showLabel={true}
                groups={globalGroups}
                setGroups={setGlobalGroups}
                label={'Roles'}
                showOtherGroups={false}
              />
            </Box>
            <Box style={{ padding: '20px 0' }}>
              <FileReader setData={setPreviewData} reset={reset} groups={globalGroups} />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => setShowPreview(!showPreview)}
                color='primary'
                className='green-button'
                disabled={!previewData}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {!showPreview ? <><VisibilityIcon /> Preview file</> : <><VisibilityOffIcon /> Hide preview</>}
              </Button>
            </Box>
            {!areEmailsUnique && isCsvValid && (
              <Box component='span' style={{ color: '#f44336', fontSize: '12px' }}>Emails are not unique!</Box>
            )}
            {previewData && !isCsvValid && (
              <Box component='span' style={{ color: '#f44336', fontSize: '12px' }}>CSV data is not valid!</Box>
            )}
          </Box>
        </Box> 
        {loading ? <Loading transparentBackground={true} /> : null}
      </Box>
      {!loading && previewData && showPreview ? (
        <Box style={{ maxWidth: '1048px', width: '100%' }}>
          <MaterialTable
            title={`Invitations preview${isSevenstepOrganization ? ` for ${globalOrganization?.tenant} organization` : ''}`}
            columns={columns}
            actions={[
              {
                icon: 'delete',
                tooltip: 'Delete row(s)',
                onClick: (event: React.MouseEvent, rowData: any) => handleRemoveUsersFromInvitations(rowData),
                position: 'toolbarOnSelect',
              }
            ]}
            options={{
              selection: true,
            }}
            tableRef={tableRef}
            data={previewData}
            editable={{
              onRowAdd: (newData: InvitedUser) => (
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setPreviewData([...previewData, newData]);
                    resolve('');
                  }, 1000);
                })
              ),
              onRowUpdate: (newData: InvitedUser, oldData: InvitedUser | undefined) => (
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...previewData];
                    if (oldData && oldData.tableData) {
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setPreviewData([...dataUpdate]);
                    }
                    resolve('');
                  }, 1000);
                })
              ),
              onRowDelete: (oldData: InvitedUser) => (
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...previewData];
                    if (oldData && oldData.tableData) {
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                    }
                    setPreviewData([...dataDelete]);
                    resolve('')
                  }, 1000);
                })
              )
            }}
            style={{
              borderRadius: '20px'
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No invitations to display!'
              },
              toolbar: {
                nRowsSelected: '{0} invitation(s) selected'
              }
            }}
            components={{
              Toolbar: (props: any) => <MTableToolbar {...props} classes={{ highlight: classes.highlight }} />
            }}
          />
          <Box style={{ padding: '24px 0 48px', textAlign: 'right' }}>
            <Button
              color='primary'
              className='green-button'
              disabled={!isCsvValid || !areEmailsUnique || !previewData.length}
              onClick={() => {
                setMultiInvitations(true);
                globalOrganization && handleCsvInvitations(globalOrganization);
              }}
            >
              Send Invitations
            </Button>
          </Box>
        </Box>
      ) : null}
      {invitationData.length > 0 ? (
        <Box style={{ padding: '24px 0 48px', maxWidth: '1048px', width: '100%' }}>
          <MaterialTable
            title='Invitations info'
            actions={[
              {
                icon: 'clear',
                tooltip: 'Remove invitations info table',
                onClick: (event: React.MouseEvent, rowData: any) => {
                  setInvitationData([]);
                },
                position: 'toolbar',
              }
            ]}
            columns={[...columns,
              {
                title: 'Status',
                field: 'email_sent',
                render: (rowData: InvitedUser) => rowData.email_sent === 'Success' ? <CheckIcon style={{ color: '#74c8b0' }} /> : <span style={{ color: '#f44336' }}>{rowData.email_sent}</span>
              }]
            }
            data={invitationData}
            options={{
              sorting: true,
            }}
            style={{
              borderRadius: '20px'
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No invitations to display!'
              }
            }}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default UserInvitations;
