import React, { useState } from 'react';
import { NavLink as RouterNavLink, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoIcon from '@material-ui/icons/Info';
import EmailIcon from '@material-ui/icons/Email';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FolderIcon from '@material-ui/icons/Folder';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import useWindowSize from '../utils/useWindowSize';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'reactstrap';
import { useAuth0 } from '../react-auth0-spa';
import { Avatar, Box, Typography, IconButton, Tooltip, ClickAwayListener } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { IUser } from '../types/Auth0.interfaces';
import { Tenant } from '../types/Tenant.interfaces';
import { Incident } from '../types/Statuspage.interfaces';
import { format, parseISO } from 'date-fns';
import history from '../utils/history';
import logo from '../assets/Sevayo_Insights_Color.png';

interface Props {
  toggleMenu: () => void;
  toggleRightSideMenu: () => void;
  randomColor: string;
  tenantChange: (tenant: Tenant) => void;
  redirectToDefaultDashboard: (tenant: Tenant) => void;
  selectedTenant: Tenant | null;
  clientTenant: Tenant | null;
  changeToClientPortal: (tenant: Tenant) => void;
  isClientPortal: boolean;
  moreInfoDashboardId: number | null;
  whatsNewDashboardId: number | null;
  notifications: { scheduled: Incident[], active: Incident[], unresolved: Incident[] } | null;
  orgName: string;
  rightToggleRef: any;
  leftToggleRef: any;
  dashboardIndexId: number | null;
}

const NavBar: React.FC<Props> = ({
  toggleMenu, randomColor, tenantChange, redirectToDefaultDashboard, notifications, orgName, toggleRightSideMenu, leftToggleRef,
  selectedTenant, clientTenant, changeToClientPortal, isClientPortal, moreInfoDashboardId, whatsNewDashboardId, rightToggleRef, dashboardIndexId
}) => {
  const { user, isAuthenticated, logout, isAdmin, getInitials, userMetadata, isClientUser, availableUserTenants } = useAuth0();
  const [open, setOpen] = useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [tenantOpen, setTenantOpen] = useState<boolean>(false);
  const [profileInfoOpen, setProfileInfoOpen] = useState<boolean>(false);

  const toggle = (setToggleDropdown: (value: boolean) => void, toggleDropdown: boolean) => setToggleDropdown(!toggleDropdown);

  const logoutWithRedirect = () => {
    logout && logout({ returnTo: window.location.origin });
  };

  const { width } = useWindowSize();

  return (
    <div className='nav-container'>
      <Navbar color='light' light expand='md'>
        <Container fluid>
          <Link className='logo-top' to='#' onClick={() => selectedTenant && redirectToDefaultDashboard(selectedTenant)}>
            <img src={logo} alt='Sevayo Insights' />
          </Link>
          {isAuthenticated && width < 768 && (
            <>
              <NavbarToggler
                tag={() => (
                  <Box className='folder-toggler' {...{ ref: leftToggleRef } as any} onClick={() => toggleMenu()}>
                    <FolderIcon className='navbar-icon' />
                  </Box>
                )}
              />
              <NavbarToggler
                tag={() => (
                  <Box className='folder-toggler' {...{ ref: rightToggleRef } as any} onClick={() => toggleRightSideMenu()} >
                    <MenuRoundedIcon className='navbar-icon' />
                  </Box>
                )}
              />
            </>
          )}
          <Collapse navbar>
            <Nav className='mr-auto' navbar>
            </Nav>
            {isAuthenticated && width >= 768 && (
              <Nav className='d-none d-md-block' navbar>
                <ClickAwayListener onClickAway={(event: React.MouseEvent<Document>) => setOpen(false)}>
                  <Dropdown isOpen={open} toggle={() => toggle(setOpen, open)} nav inNavbar>
                    <DropdownToggle nav>
                      {!open ? (
                        <Tooltip title='Notifications' placement='bottom' enterDelay={0}>
                          <IconButton>
                            {notifications ? <NotificationsActiveOutlinedIcon /> : <NotificationsNoneOutlinedIcon />}
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton>
                          {notifications ? <NotificationsActiveOutlinedIcon /> : <NotificationsNoneOutlinedIcon />}
                        </IconButton>
                      )}
                      {notifications ? <FiberManualRecordIcon className='notifications-dot' /> : null}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <Box style={{ padding: '5px 10px', minWidth: '300px' }}>
                        <DropdownItem header style={{ padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
                          <Box component='span'>Notifications:</Box>
                          <Box
                            component='span'
                            className='notification-link'
                            onClick={() => {
                              history.push('/notifications');
                              setOpen(false);
                            }}
                          >
                            View all
                          </Box>
                        </DropdownItem>
                        {!notifications && <DropdownItem divider />}
                        {notifications ? (
                          <>
                            {Object.entries(notifications).filter((value: [string, Array<Incident>]) => value[1].length).map((value: [string, Array<Incident>]) => (
                              <Box style={{ padding: '10px 0', borderTop: '1px solid #e9ecef' }}>
                                {value[1].map((incident: Incident) => (
                                  <Box>
                                    <Typography>{incident.name}</Typography>
                                    <Box style={{ display: 'flex', justifyContent: 'flex-start', fontSize: '12px' }}>
                                      <Box component='span' className='notifications-tag'>{value[0].toUpperCase()}</Box>
                                      {incident.scheduled_for ? (
                                        <Box component='span' style={{ padding: '0 5px' }}>{format(parseISO(incident.scheduled_for), 'MM/dd/yyyy hh:mm a')}</Box>
                                      ) : incident.updated_at ? (
                                        <Box component='span' style={{ padding: '0 5px' }}>{format(parseISO(incident.updated_at), 'MM/dd/yyyy hh:mm a')}</Box>
                                      ) : null}
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            ))}
                          </>
                        ) : (
                          <Box>No scheduled or active maintenances.</Box>
                        )}
                      </Box>
                    </DropdownMenu>
                  </Dropdown>
                </ClickAwayListener>
              </Nav>
            )}
            {isAuthenticated && width >= 768 && (
              <Nav className='d-none d-md-block' navbar>
                <ClickAwayListener onClickAway={() => setHelpOpen(false)}>
                  <Dropdown isOpen={helpOpen} toggle={() => toggle(setHelpOpen, helpOpen)} nav inNavbar>
                    <DropdownToggle nav caret>
                      <HelpOutlineIcon />
                    </DropdownToggle>
                    <DropdownMenu right>
                      {whatsNewDashboardId && (
                        <DropdownItem
                          tag={RouterNavLink}
                          to='/whats-new'
                          className='info-dropdown'
                          activeClassName='router-link-exact-active'
                          exact
                        >
                          <NewReleasesIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                          What's New
                        </DropdownItem>
                      )}
                      {moreInfoDashboardId && (
                        <DropdownItem
                          tag={RouterNavLink}
                          to='/more-information'
                          className='info-dropdown'
                          activeClassName='router-link-exact-active'
                          exact
                        >
                          <InfoIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                          More Information
                        </DropdownItem>
                      )}
                      {dashboardIndexId && (
                        <DropdownItem
                          tag={RouterNavLink}
                          to='/dashboard-index'
                          className='info-dropdown'
                          activeClassName='router-link-exact-active'
                          exact
                        >
                          <DashboardIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                          Dashboard Index
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={() => window.location.href = 'mailto:support@sevayoinsights.com'} className='info-dropdown'>
                        <EmailIcon className='mr-3' style={{ width: '24px' }} fontSize='small' />
                        Email Support
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </ClickAwayListener>
              </Nav>
            )}
            {!isClientPortal && availableUserTenants && availableUserTenants.length === 1 && !isClientUser && (
              <Nav className='d-none d-md-block' navbar>
                <Box style={{ color: 'rgba(0,0,0,.5)', margin: '0 10px' }}>Client: {selectedTenant && selectedTenant.tenant}</Box>
              </Nav>
            )}
            {availableUserTenants && availableUserTenants.length > 1 && (
              <Nav className='d-none d-md-block' navbar>
                <ClickAwayListener onClickAway={() => setTenantOpen(false)}>
                  <Dropdown isOpen={tenantOpen} toggle={() => toggle(setTenantOpen, tenantOpen)} nav inNavbar>
                    <DropdownToggle nav caret>
                      Client: {selectedTenant && selectedTenant.tenant}
                    </DropdownToggle>
                    <DropdownMenu right className='org-navbar'>
                      <DropdownItem header> Clients: </DropdownItem>
                      <DropdownItem divider />
                      {availableUserTenants.map((client: Tenant) => (
                        <DropdownItem
                          key={client.organization_id}
                          className={selectedTenant && selectedTenant.tenant === client.tenant ? 'active-tenant' : ''}
                          onClick={() => tenantChange(client)}
                        >
                          {client.tenant}
                        </DropdownItem>
                        )
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </ClickAwayListener>
              </Nav>
            )}
            <Nav className='d-none d-md-block' navbar>
              {isAuthenticated && (
                <ClickAwayListener onClickAway={() => setProfileInfoOpen(false)}>
                  <Dropdown isOpen={profileInfoOpen} toggle={() => toggle(setProfileInfoOpen, profileInfoOpen)} nav inNavbar>
                    <DropdownToggle nav caret id='profileDropDown'>
                      <Avatar
                        component='span'
                        style={{
                          width: '40px',
                          fontSize: '16px',
                          display: 'inline-flex',
                          background: user && user[userMetadata as keyof IUser] && user[userMetadata as keyof IUser].color ? user[userMetadata as keyof IUser].color : randomColor,
                        }}
                      >
                        {user && getInitials && getInitials(user.email, user[userMetadata as keyof IUser].first_name, user[userMetadata as keyof IUser].last_name)}
                      </Avatar>
                    </DropdownToggle>
                    <DropdownMenu right>
                      {user ? <DropdownItem header>{user.email}</DropdownItem> : null}
                      {!isClientUser && clientTenant ? (
                        <>
                          <DropdownItem divider />
                          <DropdownItem onClick={() => changeToClientPortal(clientTenant)}>
                            <DashboardIcon className='mr-3' style={{ width: '24px'}} fontSize='small' />
                            Switch to {`${isClientPortal ? 'internal' : 'client'}`} portal
                          </DropdownItem>
                          <DropdownItem divider />
                        </>
                      ) : null}
                      <DropdownItem
                        tag={RouterNavLink}
                        to='/profile'
                        className='dropdown-profile'
                        activeClassName='router-link-exact-active'
                        exact
                      >
                        <FontAwesomeIcon icon='user' className='mr-3' style={{ width: '24px'}} />
                        Profile
                      </DropdownItem>
                      {isAdmin && (
                        <>
                          <DropdownItem
                            tag={RouterNavLink}
                            to={`/users?page=1&page-size=10${orgName ? `&org=${orgName}` : ''}`}
                            className='dropdown-profile'
                            activeClassName='router-link-exact-active'
                            exact
                          >
                            <FontAwesomeIcon icon='users' className='mr-3' style={{ width: '24px'}} />
                            User Management
                          </DropdownItem>
                          <DropdownItem
                            tag={RouterNavLink}
                            to='/invite-users'
                            className='dropdown-profile'
                            activeClassName='router-link-exact-active'
                            exact
                          >
                            <FontAwesomeIcon icon='user-plus' className='mr-3' style={{ width: '24px'}} />
                            Users Invitation
                          </DropdownItem>
                          <DropdownItem
                            tag={RouterNavLink}
                            to='/pending-invitations'
                            className='dropdown-profile'
                            activeClassName='router-link-exact-active'
                            exact
                          >
                            <FontAwesomeIcon icon='paper-plane' className='mr-3' style={{ width: '24px'}} />
                            Pending Invitations
                          </DropdownItem>
                          {!isClientUser && !isClientPortal ? (
                            <DropdownItem
                              tag={RouterNavLink}
                              to='/existing-invited-users'
                              className='dropdown-profile'
                              activeClassName='router-link-exact-active'
                              exact
                            >
                              <FontAwesomeIcon icon='user-friends' className='mr-3' style={{ width: '24px'}} />
                              Recently Added Users
                            </DropdownItem>
                          ) : null}
                        </>
                      )}
                      <DropdownItem
                        onClick={() => logoutWithRedirect()}
                      >
                        <FontAwesomeIcon icon='power-off' className='mr-3' style={{ width: '24px'}} />
                        Log out
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </ClickAwayListener>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <ToastContainer />
    </div >
  );
};

export default NavBar;
